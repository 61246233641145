import React, {useEffect, useState, useRef} from "react";
import User from "data/User";
import { useParams } from "react-router-dom";

// MX ConnectWidget SDK
import * as widgetSdk from "@mxenabled/web-widget-sdk";

//MX Longpolling
import { mountBankPoll, unmountBankPoll } from "services/ULink";

//Will return correct dev/qa/prod function based on env var
import CloudFunctionsURL from "views/pages/pay-in-store/helpers/CloudFunctionsURL";
import { useNavigate } from "react-router-dom";

// Environment
import isProd from "views/pages/pay-in-store/helpers/isProd";

export default function MXConnect(props: {
    user: User, 
    setUser: React.Dispatch<React.SetStateAction<User>>, 
    isTransparent?: boolean,
    primaryAction: () => void,
    memberConnected?: (userGuid: string, memberGuid: string) => void,
    onURLCallback?: () => void,
    stepChange?: () => void,
}) {


    //MX State
    const [ mxURL, setMxURL ]        = useState('');
    const mxWidgetRef                = useRef(null);
    const [ loading, setLoading ]    = useState(false);

    // Navigation
    //
    const navigate = useNavigate();

    // URL Params
    //
    let { location_id } = useParams();


    // Effects
    //
    useEffect(() => { 
        //hitMX and mountBankPoll depend on props.user existing
        if(props.user) { 
            hitMX(); //gets MX widget URL
            setLoading(true);

            // Starts long polling
            mountBankPoll(props.user, (methods) => {
                props.setUser(props.user.copy().withAddBankPaymentMethods(methods))
                navigate(`/locations/${location_id}/code-checkout`); //TODO: THIS MIGHT BE WHY THE PAGE WAS SENDING PEOPLE BACK TO WEIRD SPOTS IN THE LAUNCH RECEPTION
            })
            return unmountBankPoll;
        };
    }, []);

    // onURLCallback is envoked when the MXWidget has received it's iframe URL -> [mxURL]  (this is an optional prop)
    useEffect(() => {
        if(!props.onURLCallback) return;
        props.onURLCallback();
    }, [mxURL]);


    // This basically just mounts the MX Widget when mxURL exists and mxWidgetRef div has been mounted
    useEffect(() => {
        if(mxWidgetRef.current) {
            new widgetSdk.ConnectWidget({ container: "#mxWidget", url: mxURL });
            window.addEventListener('message', (e) => { 
                const data = e.data;
                if(data.type === 'mx/connect/stepChange' && props.stepChange) {
                    props.stepChange();
                }
                if(data.type === "mx/connect/memberConnected" && props.memberConnected) {
                    //Triggered when a member has successfully connected the account.
                    props.memberConnected(data.metadata.user_guid, data.metadata.member_guid);
                }
                //primaryAction callback is a required prop
                if(data.type === "mx/connect/connected/primaryAction") {
                    //Triggered when the user selects the primary button on the connected step.
                    props.primaryAction();
                } 

                //todo: this is where events would go to G-Analytics (( could not get working before the launch event ))

                // if(data.type === "mx/connect/memberStatusUpdate"
                // && data.metadata.connection_status === 14) {
                //     logEvent(analytics, 'mx_status_update_14', {
                //         data: data,
                //     });
                // } 
            });
        }
    }, [mxWidgetRef, mxURL]);


    // POSTs data to server-side function
    // Returns the MX widget URL
    async function hitMX() {
        const data = { 
            ulink_user_id: props.user.id, 
            bypass: false,
            mx_env_prod: isProd(),
        };

        const response = await fetch(CloudFunctionsURL('mx_widget_url'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          })
          try {
            setMxURL(await response.text());
            setLoading(false);
          } catch(e) { 
            console.error("Error getting MX Widget URL:", e)
            alert(`An unexpected error has occured while communicating with MX. Please try again or contact support at ${<a href="mailto:support@ulink.mobi">support@ulink.mobi</a>}`);
            
        };
    };


    // Hides the widget without unmounting component so the long polling can continue
    const mxConnectStateStyles = () => {
        if(props.isTransparent) {
            return {
                opacity:    "0",
                height:     "0",
                width:      "0",
            }
        } else return null;
    }



    return (
        <>
            <div id="MXConnect" style={mxConnectStateStyles()}>
                { mxURL && <div id="mxWidget" ref={mxWidgetRef}></div> }
                { loading && <div id="Loader"><span>Loading...</span></div> }
            </div>

        </>
    );
};