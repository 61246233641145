import React, { useRef } from 'react'; 

function FormatInput(props) { 
    let ref = useRef(); 
    let fmt = props.fmt; 

    let onKeyDown = (e) => { 
        if(e.key == 'Enter' && props.onEnter) { 
            props.onEnter()
            return
        }

        const input = e.target; 
        const start = input.selectionStart, end = input.selectionEnd; 

        var deldir;
        var newkey = ''; 
        if(e.key == 'Backspace')    deldir = -1; 
        else if(e.key == 'Delete')  deldir = 0; 

        let isDeleting      = deldir !== undefined; 
        let hasSelection    = start != end; 

        // These conditions are from https://stackoverflow.com/questions/51296562/how-to-tell-whether-keyboardevent-key-is-a-printable-character-or-control-charac 
        // Nice confirmation of what I had already suspected 
        let isInserting     = e.key.length == 1 || (e.key.length > 1 && /[^a-zA-Z0-9]/.test(e.key));
        if(isInserting) newkey = e.key; 
        else if(e.key == "Spacebar") { 
            isInserting = true; 
            newkey = ' '; 
        }

        // -- 

        // For control events, we fall back to default behavior - for anything else, we override it 
        if(!(isInserting || isDeleting)) return; 
        if(e.ctrlKey || e.metaKey || e.altKey) return;
        e.preventDefault(); 
        e.stopPropagation(); 

        // Maybe we reject this input 

        // -- 

        let update = (val, valid, cursor) => { 
            props.callback(val, valid, () => { 
                ref.current.selectionStart  = cursor; 
                ref.current.selectionEnd    = cursor; 
            }); 
        };

        let state       = fmt.value; 

        if(hasSelection || isInserting) { 
            // Inserting within, inserting over a selection, or deleting a selection 
            //  All the same algorithmically, except how we handle display-only characters below 
            let [ini, fin] = [start, end].map(i => fmt.getStateIndex(i))

            // If one of these indices is undefined, the cursor is on a place that doesn't correspond to a part of the underlying value
            // Like spaces or non-value formatting characters
            if(isInserting && start === end) { 
                // Here, there's simply an insertion in a display-only area, so we just round the cursor up to the next valid spot 
                if(ini === undefined) { 
                    ini = fmt.getStateIndex(start, true);
                    fin = ini; 
                }
            }
            else { 
                // Here it's over a selection - 
                // We use the "rounding" parameter to shrink the selection to cover strictly the value characters that are selected,
                //  in essence ignoring the other characters 
                if(ini === undefined) ini = fmt.getStateIndex(start, true); 
                if(fin === undefined) fin = fmt.getStateIndex(end, true); 
            }


            let val = state.slice(0, ini) + newkey + state.slice(fin); 
            if(isInserting && fmt.shouldReject(val)) return; 
            
            let newfmt      = fmt.constructor.FromValue(val); 
            let cursor      = newfmt.getDisplayIndex( isDeleting ? ini : ini + 1 );  

            // console.log(state, val)
            // console.log(`${newkey} @ ${ini}${"\t"}${fin}${"\t"} | ini+1 -> ${cursor}`); 
            update(val, newfmt.inputValid(), cursor); 
        }
        else { 
            // Deleting without selection

            let index = fmt.getStateIndex(start);
            if(index === undefined) { 
                // In case the cursor is between characters
                if(deldir == -1)    index = fmt.getStateIndex(start, false);    // Backspace    - round down
                else                index = fmt.getStateIndex(start, true);     // Del          - round up 
            }
            else index += deldir; 
            
            // Deleting at either end; no-op 
            if(index < 0 || index >= state.length) return; 

            let val = state.slice(0, index) + state.slice(index + 1); 

            let newfmt = fmt.constructor.FromValue(val); 
            let cursor = newfmt.getDisplayIndex(index)

            update(val, newfmt.inputValid(), cursor); 
        }
    }

    let onChange = (e) => { 
        // Backup handler to do OS-specific actions, like alt-backspace or paste or whatever 
        
        let newfmt = fmt.constructor.FromDisplay(e.target.value);
        if( fmt.shouldReject(newfmt.value) ) return; 
        props.callback( newfmt.value,  newfmt.inputValid(), () => {} );
    }

    let pass = Object.assign({}, props); 
    delete pass.callback; 
    delete pass.onEnter;
    delete pass.fmt; 

    return <input ref={ref} value={ fmt.display } onKeyDown={ onKeyDown } onChange={ onChange }
        {... pass} />
}



// ===
// Formatidator
// ---
// Formatter + validator; standard interface composed with these fancy components
// --- 


class Formatidator { 

    constructor(value, display) { 
        this.value      = value; 
        this.display    = display; 
    }

    static FromValue(state) { return new this(state, this.toDisplay(state));  } 
    static FromDisplay(disp) { return this.FromValue( this.toState(disp) ); }

    inputValid()                { return false; }
    shouldReject(value)         { return !(value == '' || /^\d+$/.test(value)); }

    static toState(string)      { return string; }
    static toDisplay(string)    { return string; }


    // We allow indices one past the end of the array bc that's where the cursor goes on entry 
    // This could be a foot gun 

    getStateIndex(index)        {     
        if(index == 0 && this.display.length == 0)  return 0; 
        if(index < 0)                               throw `Out of range (${index} < 0)`; 
        if(index > this.display.length + 1)         throw `Out of range (${index} > ${this.display.length + 1})`; 
    } 
    getDisplayIndex(index)      { 
        if(index == 0 && this.value.length == 0)    return 0; 
        if(index < 0)                               throw `Out of range (${index} < 0)`; 
        if(index > this.value.length + 1)           throw `Out of range (${index} > ${this.value.length + 1})`; 
    }
}


// -----
// Formatidator implementations
// -----


// Credit card



const GSIZE = 4; // Credit card group size - not sure how to generalize this logic
export class CREDIT_CARD extends Formatidator { 

    static toState(string)      { return string.replace(/\s*/g, ''); } 
    static toDisplay(string)    { return string.replace(/\d{4}\s*/g, "$& ").trimEnd(); } 

    inputValid()                { return /^\d{13,20}$/.test(this.value) && Luhn(this.value) }
    shouldReject(value) { 
        if(super.shouldReject(value)) return true; 
        return value.length > 20; 
    }


    getStateIndex = (index, rounding) => { 
        if(super.getStateIndex(index) == 0) return 0; 

        // | 0123 4567 89 /|\
        // | 1111 2222 33  |
        // | 0123-5678-01  |
        const SPACES = 1; 
        const SGS = (GSIZE + SPACES); 

        let rem = index % SGS; 
        let numgroups = (index - rem) / SGS; 

        if((index + 1) % SGS == 0) { 
            // Special case on space character; breaks the above arithmetic 
            if(rounding === undefined) return undefined; 
            return index - (numgroups + 1) + (rounding ? 1 : 0); 
        }

        return index - numgroups; 
    }

    getDisplayIndex(index) { 
        if(super.getDisplayIndex(index) == 0) return 0; 

        let len = this.value.length; 
        let rem = index % GSIZE; 
        let qty = (index - rem) / GSIZE; 

        // Special case - the end gets trimmed

        return (qty * (GSIZE + 1)) + rem; 
    }

}

// Phone number

export class PHONE_NUMBER extends Formatidator { 

    inputValid()            { 
        let val = this.value; 
        let len = val.length; 
        return /^\d+$/.test(val) && (len >= 10)
    }
    shouldReject(value) { 
        if(super.shouldReject(value)) return true; 
        return value.length > 11; 
    }


    /** @param {String} string */
    static toState(string)  { return (string.match(/\d+/g) ?? []).join(''); }
    static toDisplay(string) { 
        if(string == '') return ''; 
        let len = string.length; 

        if(len <= 3) return string; 
        if(len > 3 && len <= 7) { 
            let match = string.match(/(\d{3})(\d+)/); 
            return `${match[1]} - ${match[2]}`; 
        }
        if(len > 7 && len <= 10) { 
            let m = string.match(/(\d{3})(\d{3})(\d+)/); 
            return `(${m[1]}) ${m[2]} - ${m[3]}`; 
        }

        if(len == 11) { 
            let m = string.match(/(\d)(\d{3})(\d{3})(\d{4})/); 
            return `+${m[1]} (${m[2]}) ${m[3]} - ${m[4]}`; 
        }

        console.debug("Invalid state length:", len) 
        return string
    }


    getStateIndex = (index, rounding) => { 
        if(super.getStateIndex(index) == 0) return 0; 

        const display   = this.display; 
        const reg11     = /^\+(\d) \((\d{3})\) (\d{3}) - (\d{4})$/; 
        const reg10     = /^\((\d{3})\) (\d{3}) - (\d{1,4})$/; 
        const reg7      = /^(\d{3}) - (\d{1,4})$/; 
        const reg4      = /^\d{1,4}$/; 

        var round = rounding ? 1 : 0; 

        if(reg11.test(display)) { 
            // |  0  123  456   7890  /|\
            // | +1 (xxx) xxx - xxxx   | 
            // | -1--456--901---5678   | 

            if(index == 0)  return rounding === undefined ? undefined : 0; 
            if(index == 1)  return 0; 
            if(index < 4)   return rounding === undefined ? undefined : 0 + round; 
            if(index < 7)   return index - 3; 
            if(index < 9)   return rounding === undefined ? undefined : 3 + round; 
            if(index < 12)  return index - 5; 
            if(index < 15)  return rounding === undefined ? undefined : 6 + round; 
            if(index < 20)  return index - 8; 
            throw 'Index out of range'; 
        }
        if(reg10.test(display)) { 
            // |  012  345   6789  /|\ 
            // | (xxx) xxx - xxxx   | 
            // | -123--678---2345   | 


            if(index < 1)   return rounding === undefined ? undefined : 0; 
            if(index < 4)   return index - 1; 
            if(index < 6)   return rounding === undefined ? undefined : 2 + round; 
            if(index < 9)   return index - 3; 
            if(index < 12)  return rounding === undefined ? undefined : 8 + round; 
            if(index < 17)  return index - 6; 
            throw 'Index out of range'; 
        }

        if(reg7.test(display)) { 
            // | 012   3456  /|\
            // | xxx - xxxx   |
            // | 012---6789   | 
            if(index < 3)   return index; 
            if(index < 6)   return rounding === undefined ? undefined : 2 + round;
            if(index < 11)  return index - 3; 
            throw `Index out of range #${index} in ${display}`;   
        }

        if(reg4.test(display)) return index; 
        
        throw 'Could not parse display string';  
    }

    getDisplayIndex = (index) => { 
        if(super.getDisplayIndex(index) == 0) return 0; 

        const state     = this.value; 
        const len       = state.length;
        
        if(len == 11) { 
            // |  0  123  456   7890   |
            // | +1 (xxx) xxx - xxxx   | 
            // | -1--456--901---5678  \|/ 
            if(index == 0)  return 1;
            if(index < 4)   return index + 3; 
            if(index < 7)   return index + 5; 
            if(index < 12)  return index + 8; 
            throw 'Index out of range'; 
        }
        if(len > 7) { 
            // |  012  345   6789   | 
            // | (xxx) xxx - xxxx   | 
            // | -123--678---2345  \|/
            if(index < 3)   return index + 1; 
            if(index < 6)   return index + 3; 
            if(index < 11)  return index + 6; 
            throw 'Index out of range';
        }
        if(len > 3) { 
            // | 012   3456   |
            // | xxx - xxxx   |
            // | 012---6789  \|/
            if(index < 3)   return index; 
            if(index < 8)   return index + 3; 
            throw `Index out of range #${index} in ${state}`; 
        }

        return index; 
    }

}



// ===
// Exported JSX Elements
// === 


export function Luhn(num) {
    let sum = 0;
    var doubleUp = false;
    /* from the right to left, double every other digit starting with the second to last digit */
    for (var i = num.length - 1; i >= 0; i--)
    {
        var curDigit = parseInt(num.charAt(i));

        /* double every other digit starting with the second to last digit */
        if(doubleUp)
        {
            /* doubled number is greater than 9 then subtract 9 */
            if((curDigit*2) > 9)
            {
              sum +=( curDigit*2)-9;
            }
            else
            {
              sum += curDigit*2;
            }
        }
        else
        {
          sum += curDigit;
        }
        var doubleUp =!doubleUp
    }
  /* add, then and divide it by 10. 
  If the remainder equals zero, the original credit card number is valid */
  return (sum % 10) == 0  ? true : false;
};


export function CreditCard(props) { 
    let id = props.id; 
    let value = props.value;
    let pass = Object.assign({}, props); 
    delete pass.value; 
    delete pass.id; 


    //Card Number Eval - Returns string for img src
    let imgName = (function () {
        // Int tests
        if (value && value.length >= 6) {
            let cardInt = parseInt(value.substring(0, 6));
            if(cardInt >= 622126 && cardInt <= 622925) return 'discover.png';
        }
        if( value && value.length >= 4) {
            let cardInt = parseInt(value.substring(0, 4));
            if(cardInt >= 2221 && cardInt <= 2720) return 'mastercard.png';
        }
        if (value && value.length >= 2) {
            let cardInt = parseInt(value.substring(0, 2));
            if(cardInt >= 51 && cardInt <= 55) return 'mastercard.png';
        }
        // Regex Tests
        if (/^65|^649|^648|^647|^646|^645|^644|^6011/.test(value)) return 'discover.png';
        if (/^34|^37/.test(value)) return 'amex.png';
        if (/^4/.test(value)) return 'visa.png'; 
        else return 'generic.png'; // <-- placeholder
    })();

    return (
    <div id={id}>
        <FormatInput 
        inputMode="numeric" type="text" 
        fmt={ CREDIT_CARD.FromValue(props.value) }
        {... pass}
        />
        <img src={ '/assets/img/card-networks/' + imgName } alt="credit card logo" data-logo={imgName} />
    </div>
  )
}

export function PhoneNumber(props) { 
    let pass = Object.assign({}, props); 
    delete pass.value; 

    return <FormatInput
        inputMode="numeric" type="text" 
        fmt={ PHONE_NUMBER.FromValue(props.value) }
        {... pass} />; 
}