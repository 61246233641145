import React, { useState } from 'react'; 
import { PHONE_NUMBER } from 'views/widgets/FormatInput';

import Location from 'lib/lib-smb-menus/data/Location';
import Order from 'lib/lib-smb-menus/data/Order';

import { EnterPhone, VerifyPhone } from './LogIn';
import OrderMethod from './OrderMethod';


/**
 * Modal flow to set order method
 * 
 * Currently just a wrapper around the older `OrderMethod` component, 
 * wrapped by the `div`/`dialog` combo used in the `LogIn` component as well.
 * 
 * These were originally a single component that performed all of the business logic,
 * but were split after the `OrderView` began to need user info but not method info for a new order 
 * @param {object} props 
 * @param {Location} props.location
 * @param {Order} props.order
 * @param {React.Dispatch<Order>} props.setOrder
 */
export function SetOrderMethod(props) { 
    // Coupled with the `LogIn` component body; 
    // We have a `Dialog` component now but this was left over from the previous designs
    // If we redo this modal, we can maybe update the component architecture 

    return <div aria-modal={true} id="InitDialog">
        <dialog open={true}>
            <img src={ '/assets/img/ulink-logotype-black.png' } />
            <OrderMethod order={props.order} setOrder={props.setOrder} location={props.location} />
        </dialog>
    </div>
}


/**
 * Log-in modal flow
 * @param {object} props 
 * @param {*} props.user 
 * @param {function} props.setUser 
 */
export function LogIn(props) { 
    const user = props.user

    var initPhone;
    if(!initPhone && user) initPhone = user.phone; 
    if(!initPhone) initPhone = window.localStorage.getItem('user_phone');
    if(!initPhone) initPhone = '';  
    if(!initPhone && GLOBALS.ENV == 'development') initPhone = '6158813300';

    let [ phone, setPhone ] = useState({ value: initPhone, valid: PHONE_NUMBER.FromValue(initPhone).inputValid() }); 

    /** @typedef {{ uri: string, expires: Date }} Challenge */
    /** @type {[ Challenge, React.Dispatch<React.SetStateAction<Challenge>> ]} */
    let [ challenge, setChallenge ] = useState(null) 

    var content = null;
    if(challenge) content =  <VerifyPhone challenge={challenge}
        onBack={ () => setChallenge(null) }  setChallenge={ setChallenge }
        phone={phone} 
        setUser={ props.setUser } 
    />;
    else content = <EnterPhone 
        phone={phone} setPhone={setPhone}   
        wasExpired={props.sessionWasExpired}
        setUser={ props.setUser } 
        setChallenge={ setChallenge }
    />;


    // Coupled with the `SetOrderMethod` component; see those comments above
    return <div aria-modal={true} id="InitDialog">
        <dialog open={true}>
            <img src={ '/assets/img/ulink-logotype-black.png' } />
            {content}
        </dialog>
    </div>

}