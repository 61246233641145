import React, {useEffect, useState} from "react";

import Location from "lib/lib-smb-menus/data/Location";
import Transaction from "lib/lib-sionic/data/Transaction";

import { ULINK_UI } from "ulink_global";

// Confirmation Circle which shows for a second upon this component mounting
import CheckCircle from "../helpers/CheckCircle";


export default function(props: { 
    location: Location,
    transaction: Transaction,

    onDone: () => void, 
}) { 

    const [ checkShowing, setCheckShowing ] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setCheckShowing(false);
        }, 1500);
    }, []);


    function ReceiptContent() {
        return (
            <div id="Summary">
                <div className="info-block">
                    <div className="info-wrapper">
                        <span>Transaction Complete</span>
                        <h1>{props.location.name}</h1>
                        <h2 className="merchant-address">{props.location.address_1}, {props.location.city}</h2>
                    </div>
                </div>

                <div id="OrderSummary">
                    <div className="summary-header">
                        <h2><b>Receipt Summary</b></h2>
                    </div>
                    <div className="subtotal">
                        <h2>Sale Total</h2>
                        <h2>${ ULINK_UI.Price_s(props.transaction.subtotal) }</h2>
                    </div>

                    { 
                        props.transaction.incentives?.map((inc, i) => <div className="incentive" key={i}>
                            <h2>{ inc.textDescription() }</h2>
                            <h2><b>{ inc.amount_discounted ? `$${ULINK_UI.Price_s(inc.amount_discounted)}` : "----" }</b></h2>
                        </div>)
                    }

                    { 
                        props.transaction.tip && <div className="tip">
                            <h2>Tip</h2>
                            <h2>${ ULINK_UI.Price_s(props.transaction.tip) }</h2>
                        </div>
                    }

                    <div className="withdraw-amount">
                        <div><h2><b>Total Amount</b></h2></div>
                        <h2><b>${ ULINK_UI.Price_s(props.transaction.total) }</b></h2>
                    </div>

                    <div id="OKButton"><button id="CheckoutDoneBtn" onClick={props.onDone}>Done</button></div>
                </div>
            </div>
        )
    }
    
    return  checkShowing ? <CheckCircle /> : ReceiptContent();
}