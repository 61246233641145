/**
 * @description Takes in func name <string> and returns the correct cloud function URL based on the current environment variable (dev/qa/prod)
 */
export default (functionName: string) => {
    //@ts-ignore -- GLOBALS is passed in through raw HTML and unrecognized by React TS
    switch(GLOBALS.ENV) {
        case 'development':
            return `https://us-east1-ulink-242918.cloudfunctions.net/${functionName}`;
        case 'remote_dev':
            return `https://us-east1-ulink-242918.cloudfunctions.net/${functionName}`;
        case 'remote_qa':
            return `https://us-east1-ulink-qa.cloudfunctions.net/${functionName}`;
        case 'production':
            return `https://us-east1-ulink-prod.cloudfunctions.net/${functionName}`;
        default: return `https://us-east1-ulink-242918.cloudfunctions.net/${functionName}`;
    }   
}