import React, { useEffect, useState, useRef } from "react";
import MXConnect from "views/widgets/MXConnect";
import { ULINK_UI } from "ulink_global";

// Main Component Props Interface
import MainView from "../pay-in-store/interfaces/MainView";

//Will return correct dev/qa/prod function based on env var
import CloudFunctionsURL from "../pay-in-store/helpers/CloudFunctionsURL";

// Environment
import isProd from "../pay-in-store/helpers/isProd";

//Inputs for Alt form
import { TextInput } from "views/widgets/Fields";

export default function Main(props: MainView) {

    //State
    const [ showMXWidget, setShowMXWidget ]         = useState(false);
    const [ showAltForm, setShowAltForm ]           = useState(false);
    const [ showCompleteMsg, setShowCompleteMsg ]   = useState(false);
    const [ showAltFormBtn, setShowAltFormBtn ]     = useState(false);

    //Alt Form
    const [ name, setName ]                         = useState('');
    const [ email, setEmail ]                       = useState('');

    //Used to scroll to bottom when MX widget is loading
    const bottomRef = useRef(null);

    /* ------------------------------------------
        Show the MXWidget when set to true and only
        if a user exists. Should happen after user
        logs in to ULink or creates an account.
       ------------------------------------------ */
    useEffect(() => { 
        if(props.user) {
            setShowMXWidget(true); //forces a re-render to show the widget
        }
     }, [props.user]);


    // Send MX data to server-side function
    async function sendMXData(userGuid: string, memberGuid: string) {
        const data = { 
            guid: userGuid,
            mguid: memberGuid,
            ulink_user_id: props.user.id,
            mx_env_prod: isProd(),
        }

        const response = await fetch(CloudFunctionsURL('mx_link_withid'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          })
          try {
            const returnData = await response.text();
          } catch(e) {
            console.error("error in hitting mx_link_withid", e);
            ULINK_UI.GenericError();
          }
    }

    // Send Alt form (phone number and partner) to cloud function
    async function postAltForm() {
        const data = { 
            name: name,
            email: email,
            phone: props.user.phone, 
            ulink_user_id: `${props.user.id}`,
        };

        const response = await fetch(CloudFunctionsURL('pre_reg_backupform'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          });
          try {
              const returnData = await response.text();
              setShowAltForm(false);
              setShowCompleteMsg(true);
            } catch(e) {
                console.log(e);
                ULINK_UI.GenericError();
          }
    }




    /* -----*-----------*---------------*-----
        MX Widget callback action functions
        to be passed into MXConnect component
    -*------------*-------------*------------- */
    function onPrimaryAction() { 
        setShowMXWidget(false);
        setShowCompleteMsg(true);
    };
    function onStepChange() {
        setShowAltFormBtn(true);
    }
    function onMemberConnected(userGuid: string, memberGuid: string) { 
        sendMXData(userGuid, memberGuid);
    };
    function MXUrlCreated() {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }



    return (
        <div id="Main">
            <header>
                <h1>Pay-by-Bank Launch Reception</h1>
                
                <div className="flex-center">
                    <span><i><b>{`"Dine, Drink, & Disrupt"`}</b></i></span>

                    <img src="/assets/img/pbb-launch/establishment-logo.png" alt="Establishment company logo" />
                </div>

                <h2>Join Us <br /> Tuesday, 8/23/22, 5:30-9:30 PM <br /> @ ESTABLISHMENT </h2>
                <span>1197 Peachtree St NE, Suite 517, <br /> Atlanta, GA 30305</span>
            </header>

            <div id="Instructions">
                <ul>
                    <li>Enter your mobile number</li>
                    <li>Verify code from the text</li>
                    <li>Link your bank account</li>
                </ul>
            </div>

            <div id="LogosRow">
                <img src="/assets/img/pbb-launch/sionic-logo.png" alt="Sionic company logo" />
                <img src="/assets/img/pbb-launch/google-cloud-logo.png" alt="Google Cloud company logo" />
                <img src="/assets/img/pbb-launch/mx-logo.png" alt="MX company logo" />
                <img src="/assets/img/pbb-launch/rtp-logo.png" alt="Real Time Payments marketing logo" />
            </div>


            {/* Shows MX Connect Widget when state is true */}
            { showMXWidget  
                && <MXConnect 
                    onURLCallback={MXUrlCreated}
                    primaryAction={onPrimaryAction} 
                    stepChange={onStepChange}
                    user={props.user} setUser={props.setUser}
                    memberConnected={onMemberConnected} /> 
            }

            {/* Shows bank-not-listed button after MX "Continue" btn clicked */}
            { showMXWidget
                && showAltFormBtn
                && <button onClick={() => {
                    setShowMXWidget(false);
                    setShowAltForm(true);
                }}>My bank isn't listed</button>
            }

            {/* Show Alt form when state is set to true */}
            { showAltForm 
                &&
                <div id="AltForm">
                    <TextInput 
                        customClass="altform-name"
                        name="name" 
                        placeholder="Your name"
                        label="Name"
                        singleField={name}
                        setSingleField={setName}
                    />

                    <TextInput 
                        name="email" 
                        placeholder="Email Address"
                        label="Email"
                        singleField={email}
                        setSingleField={setEmail}
                    />

                    <button onClick={() => { postAltForm() }}>Submit</button>
                </div>
            }

            {/* After Alt form or MX widget has been filled */}
            {
                showCompleteMsg 
                && <div id="CompleteMsg">
                        <h1>Thank you!</h1>
                        <span>We look forward to seeing you at the reception.</span>
                    </div>
            }

            {/* Only show button when all show states are false (initial state of things) */}
            { 
                !showMXWidget 
                && !showCompleteMsg 
                && !showAltForm
                && <button 
                    onClick={() => { 
                        props.requireUserOnly(); //makes a user log in to ULink with phone # to give us user object
                    }}>Count Me In!</button> 
            }

            <div ref={bottomRef} />
        </div>
    )
}