import Big from 'big.js';
import React from 'react'; 

import Icon from 'lib/lib-sionic/Icon';

export const SupportLink = <a href="mailto:support@ulink.mobi">support@ulink.mobi</a>; 

export class ULINK_UI { 
    static MenuBarHeight = 55; 
    static CharacterLimit = 80; 


    // CSS here will be injected into the global <body> style 
    // So global CSS variables should be defined here;
    //  maybe we find a way to make some of these more "local" if we end up needing
    static CSS = { 
        // [!] These are all in PIXELS [!] 
        // Some calculations depend on it being in pixels downstream

        '--global-MenuBar-height':              this.MenuBarHeight,

        '--LocationMenu-categories-height':     40,
        '--LocationMenu-filter-height':         35,
    }



    /**
     * Formatted price string 
     * @param {Big} price 
     * @returns {string}
     */
    static Price_s(price) { return price.toFixed(2); }

    /**
     * Price component
     * @param {Big} price 
     * @returns {React.Component}
     */
    static Price_e(price) { return <span className="price">{ ULINK_UI.Price_s(price) }</span>; }


    static LoadingSpinner = ()      => <img className="spinner" src={ '/assets/img/spinner.gif' } />;
    static LoadingComponent = ()    => <div className="RootComponent spinner">{ this.LoadingSpinner() }</div>;


    static GenericError = () => alert(`An unexpected error occured - please try again, or contact us at ${SupportLink}.  We apologize for the inconvenience.`);


    static ajax_count = 0; 
    static ajax = { 

        before: () => { 
            this.ajax_count ++; 
            if(this.ajax_count == 1) document.getElementById(SPINNER_ID).classList.add(SPINNER_CLASS); 
        },

        /** @param {Response} res */
        catch: (res) => { 
            console.error("ajax.catch:", res)

            var status = res; 
            if(res instanceof Response) status = res.status;

            var $message; 
            switch(status) { 
                case 403:    
                case 404:
                case 409:   $message = <>Your request can no longer be performed.  Please refresh the page and try again.</>; break
                case 503:   $message = <>This service is currently unavailable.  Please wait for a moment and try again.  We apologize for the inconvenience.</>; break 
                default:    $message = <>
                    An unexpected error occurred.  
                    We sincerely apologize for the inconvenience.  
                    Please feel free to contact us at { SupportLink } and we will investigate the issue.
                </>; break 
            }
        
            if(SetDefaultErrorModal) SetDefaultErrorModal(
                <Dialog close={() => SetDefaultErrorModal(null)} title="Error" >
                    { $message }
                </Dialog>
            )
        
            else console.error("No default error modal dispatch set:", res) 

        },

        finally: () => { 
            this.ajax_count --; 
            if(this.ajax_count <= 0) document.getElementById(SPINNER_ID).classList.remove(SPINNER_CLASS); 
        }

    }
}

const SPINNER_ID    = 'spinner-global'; 
const SPINNER_CLASS = 'active'; 





//
// JSX utils
// 


/**
 * Generic modal dialog 
 * @param {object} props 
 * @param {string} props.id ID to pass to the `dialog` HTML element
 * @param {function} props.close Function to close the dialog 
 * @param {string} props.title Title shown at the top 
 * 
 * @param {*} props.children
 * @returns {React.ComponentType}
 */
export function Dialog(props) { 

    return <div className="dialog-wrapper">
        <dialog open id={props.id}>
            <div className="dialog-header">
                <h1>{ props.title }</h1>
                <Icon name="close" onClick={ props.close } aria-label="Close Dialog" />
            </div>

            <div className="dialog-body">
                { props.children }
            </div>
        </dialog>
    </div>

}


// Alert banners, styled in alerts.scss 
// Juuged from the menu builder 

// Time in seconds of FadeOut css animation [alerts.scss]
// Maybe I could just inject this later 
const FADE_OUT_TIME = 3.5;

export function Banner(obj) { 

    let el = document.createElement('div');
    el.classList.add('BannerAlert'); 

    var title = obj.title; 

    switch(obj.style) { 

        case 'negative':
            el.classList.add('negative'); 
            title = title ?? 'Oh no!';

        case 'positive': 
            el.classList.add('positive'); 
    }


    if(title) { 
        let h1 = document.createElement('h1'); 
        h1.innerText = title; 
        el.insertAdjacentElement('afterbegin', h1); 
    }

    
    if(obj.message) el.insertAdjacentText('beforeend', obj.message); 

    const REMOVE = () => { 
        if(! el) return; 
        body.removeChild(el) 
        el = null;
    }; 


    const body = document.getElementById('BannerContainer'); 
    body.insertAdjacentElement('afterbegin', el); 

    setTimeout(REMOVE, FADE_OUT_TIME * 1000);
}

window.Banner = x => { Banner(x) }; 


//
// Error handling
//



/**
 * State dispatch initialized at the root level in `app.jsx`, 
 * Invoked by the default error handler
 * @type {function(React.Element)}
 */
export var SetDefaultErrorModal = null 
export function setShowDefaultErrorModal(f) { SetDefaultErrorModal= f }

/**
 * Invoked from `ULink.js` when any fetch (except login) returns 401 
 * @type {function()} 
 */
export var LogoutHandler = null; 
export function setLogoutHandler(handler) { LogoutHandler = handler }


/**
 * Handles an error condition returned from a payment call,
 * namely Order Submit and Complete Checkout
 * @param {Response} response 
 * @param {function(React.ReactNode)} setErrorModal A function that displays the accepted React component as an error modal
 */
export function handlePaymentError(response, setErrorModal) { 

    if(response.headers?.get('Content-Type')?.startsWith('application/json')) { 

        response.json().then(json => { 
            if(response.status != 422 && response.status != 409) console.warn("Unexpected order submission status", response.status)

            //
            // [!!] Make sure that all of these error handlers return
            // 

            // First, we check for errors where the payment was processed successfully 
            // The best case scenario is the `ResponseRender` error, where the order went through but we failed to retrieve the result. 

            if(json.payment_error == "ResponseRender") { 
                setErrorModal(<Dialog title="Error displaying your receipt" close={ () => setErrorModal(null) }>
                    <p>Your order was completed successfully, but an unexpected error occurred while retrieving your order receipt.</p>
                    <p>
                        Please check your e-mail, as the confirmation e-mail may have still sent successfully.  
                        Additionally, you may contact the restaurant directly; they should have received your order.  
                    </p>
                    <p>
                        We sincerely apologize for the inconvenience. 
                        Our support team has been notified of this issue and we will work to fix it as soon as possible. 
                    </p>
                </Dialog>)
                return 
            }

            // If that's not the case, but the payment still completed, we must notify the user immediately.
            // Here we check to see if the payment was automatically reverted or not, and let them know

            if(json.payment_completed) { 

                if(json.payment_reverted) { 

                    setErrorModal(<Dialog title='Error submitting order' close={ () => setErrorModal(null) }>
                        <p>An unexpected error occurred while processing your order.</p>
                        <p>The charge to your payment method was cancelled; please allow a few business days for this to be reflected on your statement.</p>
                        <p>We sincerely apologize for the inconvenience, and we are working to resolve the issue.</p>
                    </Dialog>)
                    return

                } 
                else { 
                    
                    setErrorModal(<Dialog title="Error completing your order" close={ () => setErrorModal(null) }>
                        <p>An unexpected error occurred after processing your payment. We could not finish submitting your order to the merchant.</p>
                        <p>
                            Your payment was completed, and unfortunately we were not able to reverse the completed payment.  
                            Please do not attempt this payment again.
                        </p>
                        <p>
                            Our support team has been notified of this incident, and we will contact you at the e-mail or phone number you provided.
                            We will work to refund you to your method of payment as soon as possible.    
                            Regardless, please feel free to contact {SupportLink} at any time to discuss the issue. 
                        </p>
                        <p>
                            We sincerely apologize for the inconvenience.  
                        </p>
                    </Dialog>)
                    return 

                }

            }

            // Otherwise, it's either just a run-of-the-mill error that we can display accordingly,
            // or it might be a completely unexpected order, but it's highly unlikely that it ever entered the payment process,
            // so we needn't scare the user either way 

            if(json.payment_error) { 
                
                const close = () => setErrorModal(null)
                const notCompleted = { title: "Payment not completed", close }

                switch(json.payment_error) { 
                    
                    case "NotVerified": 
                        setErrorModal(<Dialog {... notCompleted }>
                            We could not verify the payment information provided.
                            Please check your payment details and try again.
                        </Dialog>)
                        return 

                    case "NotAuthorized": 
                        setErrorModal(<Dialog {... notCompleted }>
                            The payment could not be authorized with the payment method you provided. 
                            Please check your payment details, or try again with another payment method. 
                        </Dialog>)
                        return 


                    case "RailUnsupportedMerchant":
                    case "RailUnsupportedCustomer":
                        setErrorModal(<Dialog close={close} title="Payment not supported">
                            We can no longer process this payment request for this merchant.
                            Please refresh the page and try again, or contact support at { SupportLink } if this issue persists.
                            We apologize for the inconvenience.
                        </Dialog>)
                        return 


                    case "External":
                        setErrorModal(<Dialog {... notCompleted}>
                            We encountered an error while trying to submit your payment to the payment processor.
                            Please try another payment method if possible, or contact support at { SupportLink } if this issue persists.
                            We apologize for the inconvenience.
                        </Dialog>)
                        return 
                        
                    case "Unavailable":
                        setErrorModal(<Dialog title='Service Unavailable' close={ () => setErrorModal(null) }>
                            The payment processor is currently unavailable.  
                            Please try another payment method if possible, or try again later.
                            We apologize for the inconvenience.
                        </Dialog>)
                        return 

                    
                    case "UnexpectedTotal":
                        setErrorModal(<Dialog title='Error submitting order' close={close}>
                            <p>
                                We could not submit the order because a price has changed since you loaded the page.
                                Please refresh the page and try again.
                            </p>
                            <p>
                                We apologize for the inconvenience, and are working to create a smoother experience.
                            </p>
                        </Dialog>)
                        return 


                    default: 
                        setErrorModal(<Dialog {... notCompleted }>
                            An unexpected error occurred while attempting to process the payment. 
                            Please check your payment details and try again. 
                            We apologize for any inconvenience. 
                        </Dialog>)
                        return 
                }

            }

            if(json.order_error) { 
                const dialogProps = { title: "Could not place order", close: () => setErrorModal(null) }
                switch(json.order_error) { 

                    case "UnavailableLocation": 
                        setErrorModal(<Dialog {... dialogProps }>
                            This location is no longer available.  
                        </Dialog>)
                        return 

                    default:
                        setErrorModal(<Dialog {... dialogProps}>
                            There was a problem submitting your order. 
                            Please refresh the page, double-check the contents of your order, and try again.
                            We apologize for the inconvenience.
                        </Dialog>)
                        return 

                }
            }

            ULINK_UI.ajax.catch(response)
            return

        }).catch(ULINK_UI.ajax.catch)

        // 👆 This should only catch if there was an error processing a response that was supposed to be JSON
        // 👇 This should never really happen, but is still there as a UI fallback 
    }
    else { 
        console.warn("Non-JSON response from order submission endpoint")
        ULINK_UI.ajax.catch(response) 
    }

}




//
// Uhhhhhh
// I'm sleepy 
// 

export function firebaseEvent(event, params) { 
    firebase.analytics().logEvent(event, params)
}
