/**
 * @arguments override -- optional boolean
 * @returns true if env variable is production/QA and false if development/remote_dev || if override arg passed, will return the bool which was passed
 */
export default function(override?: boolean) {
    if(override) return override;

    //@ts-ignore
    switch(GLOBALS.ENV) {
        case 'development':
        case 'remote_dev':
        case 'remote_qa':
            return false;
        case 'production':
            return true;
        default: return false;
    }
}