import Big from "big.js";
import Incentive from "./Incentive";
import Location from "lib/lib-smb-menus/data/Location";

export default class { 
    id: number 
    location_id: number 

    created_at: Date 
    cashier_name?: string 

    //TODO: Upgrade this to an actual object
    // https://www.notion.so/Move-Location-into-lib-sionic-split-from-SMB-location-6776ec85587a4c568786e706f222ac1d
    location: object

    subtotal: Big
    tax?: Big
    fees?: Big
    tip?: Big 
    total: Big 

    incentives?: Incentive[]

    links: { 
        await: string | null
    }

    constructor(json: any) { 

        this.id = json.id 
        this.location_id = json.location_id

        this.created_at = new Date(json.created_at)
        this.cashier_name = json.cashier_name

        this.location = json.location

        this.subtotal = new Big(json.subtotal) 
        this.tax      = json.tax && new Big(json.tax)
        this.fees     = json.fees && new Big(json.fees)
        this.tip      = json.tip && new Big(json.tip)
        this.total    = new Big(json.total) 

        this.incentives = json.incentives && json.incentives.map(x => new Incentive(x))

        this.links = json._links
        
    }
}