import OrderReceipt from "data/OrderReceipt";
import User, { PaymentMethod, BankPaymentMethod } from "data/User";
import { LogoutHandler } from "ulink_global";

import Location from "lib/lib-smb-menus/data/Location";
import Menu from "lib/lib-smb-menus/data/Menu";
import Checkout from "data/Checkout";
import Transaction from "lib/lib-sionic/data/Transaction";
import Big from "big.js";

interface ULinkFetchInit extends RequestInit { 
    json?: object, 
    params?: object,
}

function ulinkFetch(endpoint: string, options : ULinkFetchInit = {}) : Promise<Response> { 

    var endpoint = endpoint; 

    if(options.json) { 
        var headers     = Object.assign(( options.headers || {} ), { "Content-Type": "application/json" })
        options.headers = headers 
        options.body    = JSON.stringify(options.json); 
    }

    if(options.params) { 
        let pairs   = Object.keys(options.params).map( key => `${ key }=${ encodeURIComponent(options.params[key]) }` )
        endpoint    = `${ endpoint }?${ pairs.join('&') }`
    }

    options.credentials = 'include' 
    options.mode        = 'cors'

    return fetch('/ulink-mirror' + endpoint, options)

}


function ulink(endpoint: string, options: ULinkFetchInit = {}): Promise<Response> { 
    return ulinkFetch(endpoint, options)
        .then(res => { 
            if(res.status == 401) { 
                if(LogoutHandler) LogoutHandler() 
                else console.warn("No LogoutHandler set") 
            }

            return res 
        })
}


/**
 * Performs long polling on the provided `uri`, 
 * repeating on timeout until a non-timeout response is returned
 */
async function longPoll(endpoint: string, options: ULinkFetchInit = {}) : Promise<Response> { 
    // Given the intended nature of the `async` keyword, it seems like this should return a `Response` rather than a `Promise` to one.
    // It can't - TypeScript complains.  I have no idea why; it must be something involving the desugaring.
    // This kind of shit is exactly why I don't like `async`, but we kinda need it here for the looping
    // Seems to work though 🤷‍♂️

    for(;;) { 
        let res = await ulink(endpoint, options)
        if(res.status == 408) continue 
        else return res 
    }
}


// Quick hacky solution for long polling MX stuff
// This needs to be organized and documented 

export type BankPollCallback = ((result: BankPaymentMethod[]) => void); 
var bankPollCallback : BankPollCallback

export function mountBankPoll(user: User, callback: BankPollCallback) { 
    if(bankPollCallback) { 
        console.warn("Re-mounted bankPoll")
        return
    }

    bankPollCallback = callback;
    (async () => { 
        console.debug("Mounted bankPoll, beginning polling...")
        for(;;) { 
            let res = await ulink(user._links.await_banks, {method: "POST"}); 
            if(res.status == 408) continue; 
            else { 
                try { 
                    let json = await res.json();
                    let result = json.map(x => new BankPaymentMethod(x))

                    if (bankPollCallback) { 
                        console.debug("MX callback received; invoking... ", result)
                        bankPollCallback( result )
                    } else { 
                        console.debug("MX callback received with no bankPollCallback; ignoring...")
                    }

                }
                catch(err) { 
                    console.error("Error invoking bankPoll callback:", err)
                }

                bankPollCallback = null 
                break 
            }
        }
    })()
}

export function unmountBankPoll() { 
    if(bankPollCallback) { 
        bankPollCallback = null 
    } else { 
        console.debug("Unmounted bankPoll, but it was already unmounted")
    }
}



export default class ULink {
    
    static user = class { 

        /**
         * Performs a login bypassing phone number verification;
         * only available in dev environments
         * @param {string} phone 
         * @returns {Promise<User>}
         */
        static devLogIn(phone) { 
            return ulink("/v2/ordering/dev/log-in/" + phone, { method: "POST" })
                .then(response => { 
                    if(! response.ok) throw response
                    return response.json()
                })
                .then(json => new User(json))
        }


        /**
         * Throws status on failure
         * @param {string} phone 
         * @returns {Promise<{ uri: string, expires: Date }>}
         */
        static loginSendChallenge(phone) { 
            return ulink("/v2/ordering/session", { method: "POST", json: { phone } })
                .then(res => { 
                    if(! res.ok) throw res.status
                    return res.json() 
                })
                .then(json => { 
                    json.expires = new Date(json.expires) 
                    return json 
                })
        }

        /**
         * Throws status on failure
         * @param {string} uri URI from `loginSendChallenge` 
         * @param {string} code Code sent to user 
         * @returns {Promise<{ user: User, isNew: boolean }>}
         */
        static loginAnswerChallenge(uri, code) { 
            return ulink(uri, { method: "POST", json: { code }})
                .then(res => { 
                    if(! res.ok) throw res.status
                    return res.json().then(json => ({ isNew: res.status == 201, user: new User(json) }))
                })
        }


        /**
         * Attempts to restore the current session; returns a `null` promise if the session is invalid
         * @returns {Promise<?User>}
         */
        static getSession() { 
            return ulink("/v2/ordering/session")
                .then(response => { 
                    if(response.status == 401) return null 
                    else if(! response.ok) throw response 
                    else return response.json().then( json => new User(json) )
                })
        }

        /**
         * Updates the current user
         * @param {{ first_name: string, last_name: string, email: string }} body 
         * @returns {Promise<User>}
         */
        static update(body) { 
            return ulink("/v2/ordering/session/user", { method: "PATCH", json: body }) 
                .then(response => { 
                    if(! response.ok) throw response 
                    else return response.json().then( json => new User(json) )
                })
        }

        /**
         * @returns {Promise<void>}
         */
        static logOut() { 
            return ulink("/v2/log-out", { method: "POST" })
                .then(response => { if(! response.ok) throw response })
        }

        /**
         * Deletes a payment method.
         * 
         * If deleting the default payment method, the result of this promise will indicate the new default. 
         * @param {PaymentMethod} method 
         * @returns {Promise<{ new_default: ?PaymentMethod }>}
         */
        static deletePaymentMethod(method) { 
            return ulink(method.uri, { method: "DELETE" })
                .then(response => { 
                    if(! response.ok) throw response 
                    else return response.json().then( json => ({ 
                        new_default: json.new_default ? new PaymentMethod(json.new_default) : null, 
                    }) )
                })
        }

        /**
         * @param {PaymentMethod} method 
         * @returns {Promise<PaymentMethod>}
         */
        static setDefaultPaymentMethod(method) { 
            return ulink(method._links.setDefault, { method: "POST" })
                .then( response => { 
                    if(! response.ok) throw response 
                    else return response.json().then(json => new PaymentMethod(json))
                })
        }

    }


    /**
     * Fetches data (`Location` and `Menu` objects) for the given ULink location
     * @param {string} id ULink ID
     * @returns {Promise<{ location: Location, menu: Menu }>}
     */
    static location(id) { 
        return ulink("/v2/ordering/locations/" + id) 
            .then(response => { 
                if(! response.ok) throw response
                return response.json() 
            })
            .then(json => ({
                location:   new Location(json.location, json.menu.location), 
                menu:       new Menu(json.menu), 
            }))
    }


    /**
     * Submits an Order request
     * 
     * On failure, throws an object of the form 
     * `{ status: number, json: ?object }` 
     * Where `status` is the HTTP status, 
     * and `json` is the response's JSON body or `null` if the response is not json. 
     * @param {string} location_id 
     * @param {object} payload 
     * @returns {Promise<{ receipt: OrderReceipt, payment_method_created: ?PaymentMethod }>}
     */
    static placeOrder(location_id, payload) { 
        return ulink(`/v2/ordering/locations/${location_id}/order`, { method: 'POST', json: payload })
            .then(res => { 
                if(res.ok) return res.json().then(json => ({
                    receipt: new OrderReceipt(json), 
                    payment_method_created: json.payment_method_created && new PaymentMethod(json.payment_method_created),
                }))
                else { 
                    throw res 
                }
            })
    }


    /**
     * Fetches an Order, or throws HTTP status on failure
     * @param {string} order_id The Order ID (for now the External ID; from the SMB service)
     * @returns {Promise<OrderReceipt>}
     */
    static fetchOrder(order_id) { 
        return ulink(`/v2/ordering/orders/${order_id}`)
            .then(res => { 
                if(! res.ok) throw res
                return res.json().then(json => new OrderReceipt(json)) 
            })
    }



    static checkouts = class { 

        /**
         * @param {string} location_id 
         * @param {string} code
         * @returns {Promise<Checkout>}
         */
        static preview(location_id, code) { 
            return ulink(`/v2/ordering/codepay-checkout?location_id=${location_id}&code=${code}`)
                .then( res => { 
                    if(! res.ok) throw res 
                    return res.json()
                })
                .then( json => new Checkout(json) )
        }

        static async complete(uri: string, payload: { tip: Big, expected_total: Big, payment: any }) : Promise<Transaction> { 

            return new Promise(async (resolve, reject) => { 
                let initialResponse = await ulink(uri, { method: "POST", json: payload })
                if(! initialResponse.ok) { reject(initialResponse); return }
                let txn = await initialResponse.json().then(json => new Transaction(json))
    
                if(initialResponse.status == 202) { 
                    if(txn.links.await) { 
                        console.debug("Checkout complete: awaiting...")
                        let pollResponse = await longPoll(txn.links.await)
                        if(! pollResponse.ok) { reject(pollResponse); return }
                    } else { 
                        console.error("Checkout complete: ULink returned a 202 but provided no `await` link") 
                        { reject(initialResponse); return }
                    }
                } 
                
                resolve(txn)
            })



        }

    }

}