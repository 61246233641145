import React from 'react'; 

import { OrderItemModState } from '../data/Order';
import Menu, { Mod, ModItem } from '../data/Menu';

import Icon from 'lib/lib-sionic/Icon';

/**
 * A list of mods; either top-level belonging to a menu item, or nested belonging to a mod item.
 * 
 * See `OrderItemModState.getMods` docs for information about the "path" concept. 
 * 
 * Renders null if the list is empty. 
 * 
 * @param {object} props 
 * 
 * @param {?string} props.menu_item_id The menu-item to which this mod belongs, if top-level, or `null` if nested. 
 * @param {?[]} props.parentPath `null` if not nested, or the path to the mod item containing this mod list.  
 * 
 * 
 * @param {Menu} props.menu 
 * @param {OrderItemModState} props.modState Helper object that manages the state of the modifier set 
 * @param {function} props.setModState 
 * 
 * @param {boolean} props.showErrors If `true`, render to display invalid mod selections
 */
export default function(props) { 
    var list; 
    if(props.parentPath && props.parentModItemID) {
        list = props.menu.getNestedMods( props.parentModItemID )
    }
    else if(props.parentPath || props.parentModItemID) { 
        throw "Requires both parentPath and parentModItemId for nested mods"
    }
    else if(props.menu_item_id) { 
        list = props.menu.getMenuItemMods( props.menu_item_id )
    }
    else throw "Requires menu_item_id or nested mod information" 

    if(list.length == 0) return null 
    return <div className="ModifierList">{

        list.map(mod => <ModOptionList key={ mod.id } mod={ mod } 
            parentPath={ props.parentPath } parentModItemID={ props.parentModItemID } 
            menu={ props.menu } modState={ props.modState } setModState={ props.setModState }
            showErrors={ props.showErrors }
        />)

    }</div>
}


/**
 * An individual Mod, with its set of options.  
 * 
 * See `OrderItemModState.getMods` docs for information about the "path" concept. 
 * 
 * @param {object} props 
 * @param {Mod} props.mod 
 * 
 * @param {?[]} props.parentPath `null` if not nested, or the path to the mod item containing the list that this mod is in. 
 * @param {?string} props.parentModItemID `null` if not nested, or the ID of the mod item containing this nested mod (within `parentPath`)
 * 
 * @param {Menu} props.menu 
 * @param {OrderItemModState} props.modState State of **all** mod selections for the menu item 
 * @param {function} props.setModState 
 * 
 * @param {boolean} props.showErrors If `true`, render to display invalid mod selections
 */
function ModOptionList(props) { 
    const mod = props.mod
    const options = props.menu.getModOptions(mod.id) 

    let sel_min       = mod.sel_min 
    let sel_max       = mod.sel_max
    let isMandatory   = mod.sel_min > 0 
    let isMultiple    = mod.sel_max > 1 
    let isBoolean     = mod.sel_min == 0 && mod.sel_max == 1 && options.length == 1 

    var path; 
    if(props.parentPath && props.parentModItemID) path = [ ...props.parentPath, [ props.parentModItemID, mod.id ] ]
    else if(props.parentPath || props.parentModItemID) throw "Requires both parentPath and parentModItemId for nested mods"
    else path = [ mod.id ]

    const thisModState = props.modState.getMod(path) 
    const currentCount = thisModState.currentQuantity()
    const isValid      = thisModState.isQuantityValid(props.mod, currentCount) 
    
    const description = (() => { 
        if(isBoolean) return null;

        let limited = sel_max && sel_max < options.length; 

        if(sel_min == 0) { 
            // Optional 

            // min 0 max 0 : impossible 
            // min 0 max 1 :
            if(sel_max == 1)        return "Choose one - optional"
            // min 0 max ~ : 
            else if(limited)        return `Choose up to ${sel_max}`;
            // min 0       : no message   (any amount is ok - the user doesn't need to know anything)
        }
        else if(sel_min == 1) { 
            // Mandatory
            //  Looks like this is identical to the below; merge them once we make a digits-to-words func
            //  https://app.clubhouse.io/sionic-mobile/story/420/render-numbers-in-words-instead-of-as-digits

            // min 1 max 0 : impossible 
            // min 1 max 1 : 
            if(sel_max == 1)        return "Choose one - required"; 
            // min 1 max ~ : 
            else if(limited)        return `Choose at least one, up to ${sel_max}`;
            // min 1       : 
            else                    return "Choose at least one";

        }
        else if(sel_min > 1) { 
            // Mandatory multiple 

            // min + max 0 : impossible
            // min + max 1 : impossible  
            // min = max   : 
            if(sel_min == sel_max)  return `Choose ${sel_min} items`;
            // min + max ~ :
            else if(limited)        return `Choose at least ${sel_min}, up to ${sel_max}`;
            // min +       :
            else                    return `Choose at least ${sel_min}`;
        }

        return null; 
    })()


    const handleSelect = (mod_item_id) => { 
        //TAG: [ModItemQuantity] 
        const alreadySelected = props.modState.getSelectionQuantity(path, mod_item_id) > 0 

        // Toggling off
        if(alreadySelected) { 

            if(isMandatory && !isMultiple) { 
                // Not allowed to toggle off
                return
            } else {
                props.setModState(props.modState.copy()
                    .withItemQuantity(props.menu, path, mod_item_id, 0))
            }
            
        }
        else {

            if(isMultiple) { 

                // Not allowed to select more
                if(currentCount.length >= sel_max) return 

                // OK, select this one 
                props.setModState(props.modState.copy()
                    .withItemQuantity(props.menu, path, mod_item_id, 1))

            } else { 
                // Changing selection of a singular option
                props.setModState(props.modState.copy()
                    .withReplacingItem(props.menu, path, mod_item_id))
            }

        }

    }


    /*
        There was initially a plan to add a CSS wobble-like animation to indicate an invalid selection,
        which could have been done by adding an `invalid_child` state and using that to trigger a CSS class.
        It was commented out long ago so I've removed it 
     */


    return <div className="Modifier">
        <h3>{ mod.name }</h3>

        { description && (<p className="description" id={ mod.id } data-error={ props.showErrors && !isValid } >{ description }</p>) }

        <div role="group" aria-describedby={ description == null ? null : mod.id } >
        { 
            //TAG: [ISSUE #5] We only render options here 
            options.map(item => { 
                //TAG: [ModItemQuantity] 
                let sel = props.modState.getSelectionQuantity(path, item.id) > 0 

                return <ModOption key={item.id} modItem={item} 
                    isCheckbox={ isMultiple || isBoolean }
                    handleSelect={ handleSelect }

                    isSelected={sel} 
                    isDisabled={ isMultiple && !sel && ( currentCount >= sel_max ) }
                    />

            }) 
        }
        </div>
    </div>

}



/**
 * An individual mod option 
 * @param {object} props 
 * @param {ModItem} props.modItem 
 * @param {boolean} props.isCheckbox 
 * @param {function(string)} props.handleSelect
 * 
 * @param {boolean} props.isSelected
 * @param {boolean} props.isDisabled If this option is currently disabled, e.g. at maximum allowed
 * 
 */
function ModOption(props) { 
    const isSelected = props.isSelected
    const isCheck = props.isCheckbox

    var icon = null; 
    if( !isCheck && !isSelected ) icon = 'radio_button_unchecked'; 
    if( !isCheck &&  isSelected ) icon = 'radio_button_checked'; 
    if(  isCheck && !isSelected ) icon = 'check_box_outline_blank';
    if(  isCheck &&  isSelected ) icon = 'check_box'; 
    icon = <Icon name={icon} />

    // Thinking about turning this into something more galaxy-brain, like
    // icon( [ 'radio_button_unchecked', 'radio_button_checked', 'check_box_outline_blank', 'check_box' ][ isCheck * 0b10 | isSelected * 0b1  ] )

    const item = props.modItem
    return <div className="ModifierItem" 
        role={ isCheck ? 'checkbox' : 'radio' } 
        aria-checked={isSelected} 

        onClick={ () => props.handleSelect(item.id) }
        aria-disabled={ props.isDisabled || !item.available }

        data-available={ item.available }
    >
        { icon }

        <span className="ItemName">{ item.name }</span>

        { item.available ? (item.price.gt(0) ? <span className="price">{ item.price.toFixed(2) }</span> : null) : <span>Unavailable</span> }
        {/* May need some l8n considerations for other currencies here      👆 */}
        {/* This was originally handled within an Order Ahead utility method before it was factored out into the lib */}
    </div>;

}
