import React, { useState, useEffect } from 'react'; 
import { Link, useNavigate } from 'react-router-dom';

import { Dialog, handlePaymentError, SupportLink, ULINK_UI } from 'ulink_global'; 
import { PhoneNumber, PHONE_NUMBER } from 'views/widgets/FormatInput';

import Summary from './Summary'; 
import LocationHeader from '../LocationMenuView/Header';
import PaymentControl, { getPaymentAPIValue, setDefaultPayState } from './PaymentControl';
import { newCreditCardState, newBankAccountState } from './PaymentControl';

import Big from 'big.js';

import ULink from 'services/ULink';

import OrderReceipt from 'data/OrderReceipt';
import User from '/data/User';

import Location from 'lib/lib-smb-menus/data/Location';
import Order from 'lib/lib-smb-menus/data/Order';
import Menu from 'lib/lib-smb-menus/data/Menu';

import { currency } from 'lib/lib-sionic/utils';

import Icon from 'lib/lib-sionic/Icon';

/**
 * @param {object} props 
 * @param {function} props.scrollToTop
 * 
 * @param {User} props.user
 * @param {React.Dispatch<User>} props.setUser
 * 
 * @param {function(Order)} props.setOrder
 * 
 * @param {function()} props.hamburgerToggle
 * 
 * @param {{ bypassTime: boolean }} props.dev Bypass location hours check; needs to be forwarded to API in dev 
 * 
 * location_props
 * @param {Location} props.location 
 * @param {Menu} props.menu
 * @param {Order} props.order
 * 
 * @param {function} props.setNotFound 
 * @param {function} props.requireInitFlow
 * @param {function} props.fetchLocation 
 * 
 * @param {function(OrderReceipt)} props.setOrderReceipt 
 * @param {function()} props.clearOrder
 */
export default function(props) { 
    let navigate = useNavigate()

    //TODO: Order recovery, incl. fetching location from stored ID (it's not in url params so we can't in useeffect)
    //TODO: Analytics, but also persist it so that we only send the event once
    // firebase.analytics().logEvent('begin_checkout', { items: (this.props.transient.state.order_items ?? []).map(x => x.name) })

    useEffect(() => { 
        props.requireInitFlow() 
        props.scrollToTop()
    }, [])

    let [ doValidate, setDoValidate ] = useState(false)

    /** @type {[ string, React.Dispatch<string> ]} */
    let [ guestName, setGuestName ] = useState(null) 

    let [ phone, setPhone ] = useState('')
    let [ email, setEmail ] = useState('')
    let [ instructions, setInstructions ] = useState('')


    /** @type {[ ?number, function(number) ]} */
    let [ tipPercent, setTipPercent ] = useState( 20 )
    /** @type {[ ?string, function(string) ]} */
    let [ tipString, setTipString ] = useState(null) 
    const tipProps = { tipPercent, setTipPercent, tipString, setTipString } 
    
    
    /** @typedef {"NEW_CARD"|"NEW_BANK"|"SAVED_CARD"|"SAVED_BANK"} PaymentType */
    /** @type {[ PaymentType, React.Dispatch<PaymentType> ]} */
    let [ paymentType, setPaymentType ]         = useState("NEW_CARD")
    let [ paymentSavedID, setPaymentSavedID ]   = useState(null)
    let [ cardState, setCardState ]             = useState( newCreditCardState() ) // <-- payWithCard  
    let [ bankState, setBankState ]             = useState( newBankAccountState() ) // <- pay by Bank

    let [ submitting, setSubmitting ] = useState(false)



    // Fetch location if not loaded 
    // Other views fetch this from a URL param; here we have to pull order state 
    useEffect(() => { 
        if(props.location) return 
        if(! props.order) return 

        props.fetchLocation(props.order.location_id)
    }, [ props.order ])    

    // Default user info 
    useEffect(() => { 
        if(! props.user) return 

        setEmail(props.user.email ?? '')
        setPhone(props.user.phone) // Guaranteed by log-in process

        setDefaultPayState(props.location, props.user, setPaymentType, setPaymentSavedID, props.location); 
        
    }, [props.user])



    let [ errorModal, setErrorModal ] = useState(null) 



    if(!(props.menu && props.order && props.user)) return null
    //TODO: What to do if there is no valid order state
    // i.e. the user came directly here before ordering
    // Also consider an expiration of order in local storage? 


    //
    // Derived
    //

    const mayTip = props.location.allow_tips

    const linkMenu = `/locations/${ props.location.id }/menu`

    const canCheckout     = props.location.isAvailableNow()

    const orderMethod = props.order.method
    const clearOrderMethod = () => { 
        let x = props.order.copy().withMethod(null)
        props.setOrder(x) // forces the init modal to pop up again
    }


    let validGuestPhone = phone && PHONE_NUMBER.FromValue(phone).inputValid(); 
    let validGuestEmail = email && /^.+@.+\.\w+$/.test(email) 

    const orderCalc = props.menu.calcOrderWithTax(props.order)

    /** 
     * Nonnull value of the tip; 
     * separate from `order.tip` since that may be null but the underlying value is still zero for calculation purposes
     * @type {Big} 
     */
    var tipAmount   = null 
    try { 
        if(! mayTip) { 
            tipAmount = new Big(0) 
        }
        else if(tipString == ".") { 
            // Edge case: The user may be typing a decimal point without a leading zero
            // The value is still effectively zero, but we want to allow them to finish typing
            tipAmount = new Big(0) 
        }
        else if(tipString !== null && tipString !== undefined) { 
            if(tipString) tipAmount = new Big(tipString) 
            else tipAmount = new Big(0) 
        } 
        else if(tipPercent !== null && tipPercent !== undefined) { 
            tipAmount = new Big(tipPercent).div(100).mul(orderCalc.sub)
        } 
        else { 
            console.error("No source of tip information; continuing as if invalid")
        }
    }
    catch(e) { 
        alert("The tip amount you entered has been flagged as invalid. Please try again.");
        console.debug("Could not calculate tip; presumed invalid", e);
        console.debug("tip amount:", tipAmount);
    }
    tipAmount = currency(tipAmount)

    const subtotal  = orderCalc.sub
    const tax       = orderCalc.tax
    const fee       = props.location.calcFeeForSubtotal(subtotal)
    const total     = tipAmount ? subtotal.add(tax).add(tipAmount).add(fee) : null 

    const calcValid = !! total 


    const paymentAPIValue = getPaymentAPIValue(paymentType, bankState, cardState, paymentSavedID)
    const paymentValid  = !! paymentAPIValue
    const paymentSaved  = paymentSavedID ? props.user.getPaymentMethod(paymentSavedID) : null 

    //TAG: [NAME_FIELDS] If we unify 👇
    let guestNameDefault = 
        (props.user.first_name && props.user.last_name ? props.user.first_name + ' ' + props.user.last_name : null) ??
        (paymentSaved && paymentSaved.name_on_card) ?? 
        cardState.value.name_on_card

    let guestNameValue = guestName ?? guestNameDefault ?? ''

    let validGuestName = guestNameValue.length > 0 
    const guestValid = validGuestName && validGuestPhone && validGuestEmail
    const isValid = guestValid && calcValid && paymentValid


    //
    // Actions
    //

    const onClear = () => { 
        let doit = window.confirm("Are you sure? This action cannot be undone."); 
        if(! doit) return; 

        props.setOrder(props.order.copy().withClearItems())
        navigate(linkMenu)    
    }


    const onCheckout = () => { 
        if(submitting) return 
        setSubmitting(true) 

        if(! isValid) { 
            setDoValidate(true) 
            return 
        }

        if(! props.location.isAvailableNow()) { 
            alert("We're sorry! This location is currently closed. Please try again during normal business hours.")
            return 
        }

        var payload = { 

            guest: { name: guestNameValue, phone, email },

            order: Object.assign(
                { 
                    custom_instructions: instructions, 
                    dev_options: { bypass_time: props.dev.bypassTime },
                    // 👆 Must be inside `order` to be forwarded to SMB
                    // ULink itself doesn't care about these yet; if we ever need it to, 
                    // specific forwarding will have to be set up to facilitate that
                }, 
                props.order
            ),

            payment: paymentAPIValue,

            tip: mayTip ? tipAmount.toString() : null, 

            expect: { 
                prep_mins:  props.location.prep_mins,
                fee:        fee.toString(),
                tax:        tax.toString(), 
                subtotal:   subtotal.toString(), 
                total:      total.toString()
            }
        }

        // [ISSUE #9]
        // firebase.analytics().logEvent('place_order', { items: (menu_item_list ?? []).map(x => x.name) })
        // if(! Object.keys(payment).includes('payment_method_id')) firebase.analytics().logEvent('add_payment_info')

        console.debug(payload) 

        ULINK_UI.ajax.before()
        ULink.placeOrder(props.location.id, payload)
            .then(result => { 
                let receipt = result.receipt 
                props.setOrderReceipt(receipt)
                props.clearOrder() 

                // User updates
                // Should mirror the "extra updates" performed in the back end 
                let user = props.user.copy()
                user.email = payload.guest.email
                if(result.payment_method_created) user.withAddPaymentMethod(result.payment_method_created)
                props.setUser(user)

                ULINK_UI.ajax.finally()
                navigate('/receipt/' + receipt.order_id)                
            })
            .catch(err => { 
                ULINK_UI.ajax.finally()
                setSubmitting(false)
                handlePaymentError(err, setErrorModal)
            })

    }


    //
    // Final render
    //


    return <div id="CheckoutView" className="RootComponent">
        { errorModal }

        <LocationHeader location={ props.location } />
        <main>
            <div id="MainMenuActions">
                <Link to={ linkMenu } id="Back">
                    <Icon name="arrow_back" />
                    <span>Back to menu</span>
                </Link>

                <button className="negative plain link" id="ClearCart" onClick={ onClear }>
                    Clear Cart
                </button>
            </div>
            

            { <Summary location={ props.location } menu={ props.menu } order={ props.order } setOrder={ props.setOrder } 
                {... tipProps} mayTip={ mayTip } tipAmount={ tipAmount } 
                orderCalc={ orderCalc } fee={ fee } total={ total }
                backToMenu={ () => navigate(linkMenu) }
            /> }


            <div id="SpecialInstructions">
                <textarea rows={3} placeholder="Special Instructions" value={ instructions } 
                    onChange={ e => { 
                        let str = e.target.value 
                        if(str.length > ULINK_UI.CharacterLimit) return 
                        setInstructions(str) 
                    } } 
                    />
                <p className="CharacterCount">{ instructions.length } / { ULINK_UI.CharacterLimit } </p>
            </div>
            

            <h3 id="Payment-header">Payment Details</h3>
            <PaymentControl
                user={ props.user } 
                location={props.location}

                doValidate={ doValidate }
                paymentType={ paymentType } setPaymentType={ setPaymentType }
                cardState={ cardState } setCardState={ setCardState } 
                bankState={ bankState } setBankState={ setBankState }
                paymentMethodID={ paymentSavedID } setPaymentMethodID={ setPaymentSavedID }
            />


            <h3 id="User-header">Your information</h3>
            <form id="GuestInfo">

                <div>
                    <label htmlFor="guest_fn">Order for:</label>

                    <div id="OrderFor">
                        <input id="guest_fn" type="text"
                            aria-invalid={ doValidate && !(validGuestName) }
                            placeholder={  "e.g. John Smith" }
                            value={ guestName ?? guestNameValue ?? '' } onChange={ e => setGuestName(e.target.value) } />
                    </div>
                </div>


                <div>
                    <label htmlFor="phone">Mobile number:</label>
                    {/* TODO: fill User phone # */}
                    <PhoneNumber id="phone"
                        aria-invalid={ ( doValidate && !(validGuestPhone) ) } value={ phone }
                        callback={ (value, valid, finish) => { 
                            setPhone(value)
                            finish()
                        }} />
                </div>

                { 
                    !(props.user.email) && 
                    <div>
                        <label htmlFor="email">E-mail:</label>
                        <input id="email" type="text"
                            aria-invalid={ ( doValidate && !(validGuestEmail) ) } value={ email }
                            onChange={ (e) => setEmail(e.target.value) } />
                    </div>
                }
                

            </form>

            { 
                orderMethod && props.location.isAvailableNow() && props.location.allowsOrderMethodOtherThan(orderMethod.$) &&
                <div id="FulfillmentControl">
                    Order for { props.location.orderMethodDisplay(orderMethod) }
                    <button className="plain" onClick={ clearOrderMethod } ><Icon name="edit" aria-label='Change order method' /></button>
                </div> 
            }

            <p id="CheckoutReadyLabel">
                {/* <span><Icon name="local_mall" /> Pickup &bull; </span>  */}
                Ready by { props.location.getOrderAvailableTime().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) }
            </p>

            <p id="ReceiptEmailLabel">A copy of this receipt will be sent to { props.user.email ? <span className="email">{ props.user.email }</span> : "your email" }</p>


            { doValidate && !isValid && <p className="error">Please correct the highlighted fields above.</p> } 

            <button className="" onClick={ () => canCheckout && onCheckout() } aria-disabled={ !canCheckout } >
                Place order for <span className="price">{  ULINK_UI.Price_s(total)  }</span>
            </button>
        </main>
    </div>
}