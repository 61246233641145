import Big from "big.js"

import { currency } from "lib/lib-sionic/utils"

import Hours from "./Hours"
import Incentive from "lib/lib-sionic/data/Incentive"


export type OrderMethod = "DineIn" | "TakeOut" 


export default class { 
    id                       : number 

    name                     : string 
    phone                    : string 

    address_1                : string 
    address_2               ?: string 
    city                    ?: string 
    region                  ?: string 
    postal_code             ?: string 

    banner_url              ?: string 

    latitude                ?: number 
    longitude               ?: number 
    google_place_id         ?: string 

    payment_types_available : {
        new_card        : boolean
        new_bank        : boolean
        saved_bank      : boolean
        saved_pmc_cybs  : boolean
        saved_pmc_s4    : boolean
    }

    /**
     * If `true`, the effective status of the Location is set to **closed** from the back end 
     */
    force_closed : boolean 

    /**
     * **External**: the business hours of the location 
     */
    hours : Hours

    allow_tips      : boolean 
    incentives      : Incentive[]


    /**
     * **External:** The number of minutes in which a given order is expected to be prepared
     * 
     * This data comes from an external source (currently only SMB service); 
     * it will be `undefined` in contexts where that data is not provided
     * (currently only when fetching a completed order via `OrderReceipt`)
     * 
     * This is terrible and should be revisited whenever the data gets more complex 
     */
    prep_mins : number

    /**
     * **External:** Temporary flag to render menu item images as `contain` instead of `cover`
     */
    images_contain : number


    /**
     * Constructs an instance from an Ordering API response, with both ULink and SMB Service parts.
     * @param {object} json The `location` field of the ULink json response; i.e. ULink data
     * @param {object} external  The `menu.location` field of the ULink json response; i.e. SMB Service data (currently)
     */
    constructor(json: any, external: any ) { 
        this.id = json.id

        this.name               = json.name
        this.phone              = json.phone
        
        this.address_1          = json.address_1
        this.address_2          = json.address_2
        this.city               = json.city
        this.region             = json.region
        this.postal_code        = json.postal_code

        this.latitude           = json.latitude
        this.longitude          = json.longitude

        this.google_place_id    = json.google_place_id

        this.payment_types_available = json.payment_types_available

        this.banner_url         = json.banner_url
    
        this.force_closed       = json.force_closed
        
        this.hours              = new Hours(external.hours) 
        
        if(!external.hours) console.warn("Missing hours in Location constructor")

        this.allow_tips         = json.allow_tips
        this.incentives         = json.incentives.map( (x: any) => new Incentive(x) )

        this.prep_mins          = external.prep_mins
        this.images_contain     = external.images_contain
    }


    /**
     * `true` if the location has been manually set to unavailable
     * 
     * This is now represented by the `force_closed` variable, 
     * but the function is left for compatibility with older code
     */
    isClosedManually(): boolean  { 
        return this.force_closed
    }
    /**
     * `true` if the location is closed at the current time due to its normal schedule
     */
    isClosedNaturally(): boolean { 
        return !(this.hours.isAvailableNow())
    }

    /**
     * `true` if the location is open currently; not closed for any reason
     */
    isAvailableNow(): boolean { 
        return !(this.isClosedManually() || this.isClosedNaturally()); 
    }


    /**
     * Returns the `Date` representing `prep_mins` after now - 
     * i.e. the time when an Order should be ready by 
     */
    getOrderAvailableTime(): Date { 
        return new Date(new Date().getTime() + this.prep_mins * 60 * 1000); 
    }


    //TAG: [ORDER_METHODS]
    // This will have to be inferred

    /**
     * `true` if the provided order method is allowed
     */
    allowsOrderMethod(method: OrderMethod): boolean { 
        return true 
    }

    /**
     * Returns `true` if the user can change their order method from `method` to something else in the GUI 
     */
    allowsOrderMethodOtherThan(method: OrderMethod): boolean { 
        return true
    }


    /**
     * Human-readable string describing the Order Method value 
     * 
     * This may eventually have additional logic for non-restaurant businesses, like "pick up" instead of "take out"
     * @param method Method object - see `Order` docs
     */
    orderMethodDisplay(method: any): string { 
        switch(method.$) { 
            case "DineIn":  return `Dine In (Table #${method.table_number})`;
            case "TakeOut": return "Take out"; 
        }
    }


    /**
     * Applies this Location's fee structure to the given **subtotal**. 
     * @param {Big} subtotal 
     * @returns {Big}
     */
    calcFeeForSubtotal(subtotal: Big): Big { 
        //TODO: https://www.notion.so/Implement-fee-structure-client-side-832089827044405f800b10c097673969
        // this will need to include payment rail type as well
        // currently there's a typedef for that that lives in `Incentive.ts`
        return new Big(0)
    }
}