import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

//Interface
import MainView from '../interfaces/MainView';

export default function(props: MainView) { 

    //Top level state
    const [ hasSavedPayments, setHasSavedPayments ] = useState(null);

    let navigate = useNavigate();

    useEffect(() => { 
        props.requireUserOnly()     // This is what makes the user log in (but not have to choose an order method, since it's not relevant)
        props.scrollToTop()         // This should be on every page, it's a hacky solution to reset the scroll view whenever the page changes
    }, [])

    useEffect(() => {
        if(props.user) { setHasSavedPayments(!!props.user.payment_methods.length) };
        if(props.user) console.log(props.user)
    }, [props.user]);


    let { location_id } = useParams()
    useEffect(() => { 
        // This forces the API call to fetch the location if it isn't already loaded
        props.fetchLocation(location_id)
    }, [])

    // Here we wait for the location to load before rendering, 
    // but that's probably not necessary at this point
    // and really needs to be replaced with a spinner or something 
    // (we can probably use a short-circuit || on the template to do that for every page)
    if(! props.location)    return null;
    if(! props.user)        return null;

    return (
        <div id="FlowSwitch">
            <header>
                <h1>{props.location.name}</h1>
                <span>{props.location.address_1}, {props.location.city}</span>
            </header>

            <div id="Switch">
                <button className="plain" onClick={() => { 
                    if(!hasSavedPayments) {
                        navigate(`/locations/${location_id}/add-payment`);
                    } else {
                        navigate(`/locations/${location_id}/code-checkout`);
                    }
                 }}>
                    <img src="/assets/img/pay-in-store/storefront.svg" alt="A storefront" />
                    <span>
                        PAY IN-STORE
                    </span> 
                </button>
                <button className="plain" onClick={() => { navigate(`/locations/${location_id}/menu`) }}>
                    <img src="/assets/img/pay-in-store/mobile-phone.svg" alt="A mobile phone" />
                    <span>
                        ORDER AHEAD
                    </span>
                </button>
            </div>

            <div id="Copyright">
                <span>Copyright © 2010-2022 Sionic Mobile Corporation</span>
            </div>
            <div className="absolute-bottom-wave"></div>
            
        </div>
    )
}