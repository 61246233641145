import React, { useRef } from 'react'; 
import { Link } from 'react-router-dom'; 
import Big from 'big.js';

import { ULINK_UI } from 'ulink_global'; 

import Order, { OrderItem } from 'lib/lib-smb-menus/data/Order';
import Menu from 'lib/lib-smb-menus/data/Menu';
import Location from 'lib/lib-smb-menus/data/Location';

import Icon from 'lib/lib-sionic/Icon';

//TODO: This probably should just be a different thing for previous orders;
//  the `Order` class contains a lot of logic for calculations that we won't have to make 

/**
 * 
 * @param {object} props 
 * @param {Location} props.location
 * @param {Menu} props.menu
 * @param {Order} props.order 
 * @param {?function} props.setOrder A set-Order function, or `null` if not editable
 * 
 * If editable:
 * @param {boolean} props.mayTip If `false`, the tipping control is hidden
 * @param {number} props.tipPercent 
 * @param {string} props.tipString
 * @param {function} props.setTipPercent 
 * @param {function} props.setTipString
 * 
 * @param {Big} props.tipAmount The calculated amount of the applied tip, if valid 
 * @param {{ sub: Big, tax: Big }} props.orderCalc 
 * @param {Big} props.fee 
 * @param {Big} props.total 
 * 
 * @param {function()} props.backToMenu Navigates back to the menu, used when all items are cleared w/ remove button 
 */
export default function(props) { 
    const customTipRef = useRef(null)

    const isEditable = !! props.setOrder 

    var $tipping = null 
    if(isEditable) { 
        const setTipPct = (int) => { 
            props.setTipPercent( int )
            props.setTipString( null ) 
        }

        const setTipStr = props.setTipString

        const isCustom = props.tipString !== null
        const isPercent = !isCustom 
        const isValid = !! props.tipAmount

        $tipping = <tr className="TippingRow" data-custom={ isCustom }>
                <td >
                    <div id="TippingSelector" role="radiogroup">

                        {/* This button is rendered on narrow screens to return to the previous state, since it will collapse for a custom tip */}
                        <div role="radio" onClick={ () => setTipStr(null) } aria-checked={ false }
                          aria-hidden={ true } tabIndex={ -1 }  /* Should not be visible to screen readers since it doesn't really do anything */ 
                          id="tipMobileBack" > <Icon name="arrow_back" /> </div>

                        <div role="radio" onClick={ () => setTipPct(0 ) } aria-checked={ isPercent && props.tipPercent == 0  } >None</div>
                        <div role="radio" onClick={ () => setTipPct(10) } aria-checked={ isPercent && props.tipPercent == 10 } >{ "10%" }</div>
                        <div role="radio" onClick={ () => setTipPct(15) } aria-checked={ isPercent && props.tipPercent == 15 } >{ "15%" }</div>
                        <div role="radio" onClick={ () => setTipPct(20) } aria-checked={ isPercent && props.tipPercent == 20 } >{ "20%" }</div>
                        <div role="radio" onClick={ () => setTipPct(25) } aria-checked={ isPercent && props.tipPercent == 25 } >{ "25%" }</div>

                        <div role="radio"  aria-checked={ isCustom } aria-owns="TipCustom" id="tipCustomButton" 
                          onClick={ () => { setTipStr('');  customTipRef.current.focus() } }>
                            Custom
                        </div>
                    </div>

                </td>

                <td id="tipValueCell">
                  <div> { /* Grid container */ }
                    <input id="TipCustom" ref={ customTipRef }
                        inputMode="decimal" aria-hidden={ ! isCustom } 
                        value={ props.tipString ?? '' } onChange={ e => { 
                            if(! /^\d*(\.\d*)?$/.test(e.target.value)) return
                            setTipStr(e.target.value) 
                        }} 
                    />
                    <div id="TipValue" className="price">{ props.tipAmount && ULINK_UI.Price_s(props.tipAmount) }</div>
                  </div>
                </td>
            </tr>
    }
    else { 
        //TODO: With representation of past orders? See comments up top 
        throw "Not implemented"

    //     Tipping = <tr>
    //     <td>Tip</td>
    //     <td className="price">{ order.strTip() }</td>
    //   </tr>
    }

    const subtotal  = props.orderCalc.sub
    const tax       = props.orderCalc.tax
    const fee       = props.fee 
    const total     = props.total 

    // tbody validation forbids whitespace between these nodes, which makes it hideous
    // Seriously I hate this so much 
    return <table className="Receipt">
            <tbody>{ 
                props.order.items.map(item => <ReceiptItem key={item.key} orderItem={item} 
                    menu={ props.menu } order={ props.order } setOrder={ props.setOrder }
                    backToMenu={ props.backToMenu }
                />)
            }</tbody>
            <tfoot>
                <tr><td>Subtotal</td><td className="price">{ ULINK_UI.Price_s(subtotal) }</td></tr>
                <tr><td>Tax</td><td className="price">{ ULINK_UI.Price_s(tax) }</td></tr> 
                <tr><td className="td-align-left">Tip</td></tr> 
                { props.mayTip && $tipping } 
                { fee.gt(0) && <tr><td>Service Fee</td><td className="price">{ ULINK_UI.Price_s( fee ) }</td></tr> }
                <tr><td>Total</td><td className="price total">{ total && ULINK_UI.Price_s( total ) }</td></tr>
            </tfoot>
    </table>;
}

/**
 * 
 * @param {object} props 
 * 
 * @param {Menu} props.menu 
 * @param {?Order} props.order 
 * @param {function} props.setOrder
 * 
 * @param {OrderItem} props.orderItem 
 * 
 * @param {function()} props.backToMenu Navigates back to the menu, used when all items are cleared w/ remove button 
 */
function ReceiptItem(props) { 
    let menuItem = props.menu.getMenuItem(props.orderItem.menu_item_id) 

    const onRemove = () => { 
        let order = props.order.copy().withRemoveItem(props.orderItem)
        props.setOrder(order) 
        if(order.items.length == 0) props.backToMenu()
    }


    //TAG: [ISSUE #5] This does not implement nested mods 
    let $modItems = props.orderItem.mods
        .flatMap(mod => mod.selections) 
        .map(sel => { 
            let modItem = props.menu.getModItem(sel.mod_item_id) 

            return <tr className='Modifier' key={sel.mod_item_id}>
                <td>{ `(${modItem.name})` }</td>
                <td>{ modItem.price.gt(0) && ULINK_UI.Price_e(modItem.price) }</td>
            </tr>
        })

    let $customInstructions = 
        props.orderItem.custom_instructions ?
        <tr className='Modifier'>
            <td colSpan={2} className='CustomInstructions'>{ props.orderItem.custom_instructions }</td>
        </tr>
        : null 

    let $quantity = 
        <tr className="Actions"><td colSpan={2}>
            <div className="flex">
                <div>
                    <Link to={ 'item/' + props.orderItem.key } role='button' className='plain link'>
                        {/* <Icon name="edit" />  */}
                        Edit Item
                    </Link>
                    <button className="plain link negative"  onClick={ onRemove }>  
                        {/* <Icon name="remove_circle_outline" />  //  className="negative inline small"  */}
                        Remove Item
                    </button>
                </div>
                <span className="x">x</span>
                <input type="number" inputMode="numeric" className="ItemQuantity" 
                    min={ 1 } value={ props.orderItem.quantity } 
                    onChange={ e =>  { 
                        let int = parseInt(e.target.value) 
                        if(! int) return 
                        if(int >= 1000) return 

                        props.setOrder(
                            order => order.copy().withReplaceItem(
                                props.orderItem.copy().withQuantity(int)
                            )
                        ) 
                    } } 
                    // onKeyDown={ e => { if(/^[a-zA-Z]$/.test(e.key) && !(e.altKey || e.ctrlKey || e.metaKey)) e.preventDefault(); } }
                />
            </div>
        </td></tr>


    // Same whitespace validation rules since this frag is a child of tbody, god it sucks ass 
    return <>{ 
            <tr className={`Item ${ $modItems.length > 0 ? 'has-modifiers' : '' }`}>
                <td className='name'>{ menuItem.name }</td>
                <td>
                    {/* //TAG: [ISSUE #7] Gotta finish up Price-by-Mod */}
                    { ULINK_UI.Price_e( menuItem.price ) }
                    {/* For some reason it was originally Price_e for mods and span.price{Price_s} for the item here */}
                </td>
            </tr>

                    /* { !isEditable && item.quantity > 1 && <tr className="Modifier">
                <td></td><td className="Quantity">x { item.quantity }</td>
                </tr>} */
        }{ 
            $modItems
        }{
            $customInstructions
        }{ 
            $quantity
        }</> 
}

