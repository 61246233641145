import React, { useState } from 'react';
import ULink from 'services/ULink';

import { firebaseEvent, ULINK_UI } from 'ulink_global'; 

import PaymentMethod from 'views/pages/CheckoutView/PaymentMethod';
import { PHONE_NUMBER } from 'views/widgets/FormatInput';

import Icon from 'lib/lib-sionic/Icon';
import User from '/data/User';


/**
 * 
 * @param {object} props 
 * @param {function} props.hamburgerToggle 
 * @param {User} props.user 
 * @param {React.Dispatch<User>} props.setUser 
 * @param {function} props.logOut 
 * 
 * @param {object} props.dev 
 * @param {boolean} props.dev.bypassTime
 * @param {function(boolean)} props.dev.setBypassTime
 * @returns 
 */
export default function(props) { 

    let user = props.user 

    const allowBypass = !(['production', 'remote_qa'].includes(GLOBALS.ENV))

    return <div id="HamburgerMenuWrapper">
        <div id="HamburgerMenu">

        {/*<h1>My Account</h1>
        <nav>
            <Link to={{ }}>Order History</Link>
        </nav> */}

        { user && <div id="User">

            { /* This CSS was very dumb - even dumber now that we had to pull <PaymentMethod /> out of it */ }
            <div id="UserTable">
                <UserInfo user={user} setUser={ props.setUser } />

                <div id="Payments">
                    <div><img src={ "/assets/img/menu/card.png" } /></div>
                    <div><h1>Payments</h1></div>
                </div>
            </div>

            <ul>
            {
                user.pm_cards[0] ? 'Cards' : null
            }
            { user.pm_cards.map(x => <li key={x.id}>
                <PaymentMethod method={ x } accessories={true} 
                    user={ props.user } setUser={ props.setUser } />
            </li>) } 
            Bank Accounts
            { user.pm_banks.map(x => <li key={x.id}>
                <PaymentMethod method={ x } accessories={true} 
                    user={ props.user } setUser={ props.setUser } />
            </li>) } 
            </ul>

            <p>To add a payment, proceed to checkout.</p>

        </div> }

        <h1>Support</h1>

        <nav id="legal">
            <a target="_blank" href="/support">Support</a>
            <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTeDJN93Uf9hN6lrE0kj8SuSCuWtYv46v-CUXN-e9WwvZOR7wpnYb-WfqVAICPY8WdeP2TqB9nilv2L/pub">Privacy Policy</a>
            <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vT-wJFBpYSXrcTr_wz7xS33LP1HmS14X6qiJosRSd8S89TB-idZj57y6bGlOGNA8dT-DsXRnnMkejlC/pub">Terms of Service</a>
        </nav>

        { user && <button onClick={ props.logOut }>Log out</button> }


        { allowBypass && props.dev && <nav id="dev">
            <h1>Development</h1>

            <input id="bypass-time" type="checkbox" 
                checked={ props.dev.bypassTime } onChange={ e => props.dev.setBypassTime(e.target.checked) } /> 
            <label htmlFor="bypass-time">Bypass time </label>
            <br />

        </nav> } 
        </div>
    </div>


}




/**
 * 
 * @param {object} props 
 * @param {User} props.user 
 * @param {React.Dispatch<React.SetStateAction<User>>} props.setUser
 * @returns 
 */
function UserInfo(props) { 

    let [ isEditing, setEditing   ]     = useState(false); 
    let [ name, setName ]               = useState(props.user.name ?? ''); 
    let [ isSubmitting, setSubmitting ] = useState(false); 
    let [ doValidate, setValidate ]     = useState(false); 

    const doSubmit = () => { 
        // Nothing's changed - we'll have to change this to a state var maybe 
        if(name == props.user.name) { 
            setEditing(false) 
            return 
        }

        if(name == '') { 
            setValidate(true)
            return
        }
        else setValidate(false) 

        if(isSubmitting) return; 
        setSubmitting(true) 

        ULINK_UI.ajax.before(); 
        ULink.user.update({ name: name })
            .then((user) => { 
                setEditing(false)
                props.setUser(user)
            })
            .catch(ULINK_UI.ajax.catch)
            .finally(() => { 
                setSubmitting(false) 
                ULINK_UI.ajax.finally() 
            })
    }

    const EditButton = <Icon role="button" name="edit" onClick={ () => setEditing(true) } aria-label='Edit profile information' />
    const DoneButton = <button className="small inline" onClick={ doSubmit }>Done</button>

    const UserDisplay = () => <dl>
        <dt>My Account</dt>
        <dd>{ props.user.name }</dd>

        <dt>Email</dt>
        <dd>{ props.user.email }</dd>

        <dt>Mobile</dt>
        <dd>{ PHONE_NUMBER.toDisplay( props.user.phone ) }</dd>
    </dl>

    const UserEdit = function(){ 

        return <dl>
            <dt>Full Name</dt>
            <dd><input type="text" value={name} aria-invalid={ doValidate && name == '' } onChange={ e => setName(e.target.value) } /></dd>
            <dt>Email</dt>
            <dd>{ props.user.email }</dd>

            <dt>Mobile</dt>
            <dd>{ PHONE_NUMBER.toDisplay( props.user.phone ) }</dd>
        </dl>
    }


    return <div id="UserInfo" data-editing={isEditing}>
        <div>
            <img src={ "/assets/img/menu/profile.svg" } />
            { isEditing ? DoneButton : EditButton }
        </div>

        { isEditing ? UserEdit() : UserDisplay() }
    </div>
}