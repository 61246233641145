import React from "react";

export default function Loading() {
    return (
        <div id="FadeWrapper">
            <div className='example'>
                <div className='block'>
                    <div className='item'></div>
                    <div className='item'></div>
                    <div className='item'></div>
                    <div className='item'></div>
                    <div className='item'></div>
                    <div className='item'></div>
                    <div className='item'></div>
                    <div className='item'></div>
                </div>
            </div>
        </div>
    )
}