import React, { useEffect, useState, useRef } from "react";
import MXConnect from "views/widgets/MXConnect";
import { ULINK_UI } from "ulink_global";

// Main Component Props Interface
import MainView from "../pay-in-store/interfaces/MainView";

//Will return correct dev/qa/prod function based on env var
import CloudFunctionsURL from "../pay-in-store/helpers/CloudFunctionsURL";

// Environment
import isProd from "../pay-in-store/helpers/isProd";

//Inputs for Alt form
import { TextInput } from "views/widgets/Fields";

export default function AccountPreview(props: MainView) {

    //State
    const [ showMainContent, setShowMainContent]    = useState(true); // this is used for testing (show/hide main stuff-- true for normal flow)
    const [ showMXWidget, setShowMXWidget ]         = useState(false);
    const [ showAltForm, setShowAltForm ]           = useState(false);
    const [ showCompleteMsg, setShowCompleteMsg ]   = useState(false); //this needs to be true for designing second screen
    const [ showAltFormBtn, setShowAltFormBtn ]     = useState(false);

    useEffect(() => {
        console.debug("showMXWidget", showMXWidget)
        console.debug("showAltForm", showAltForm)
        console.debug("showCompleteMsg", showCompleteMsg)
    }, [showMXWidget, showAltForm, showCompleteMsg])

    //Alt Form
    const [ name, setName ]                         = useState('');
    const [ email, setEmail ]                       = useState('');

    //Used to scroll to bottom when MX widget is loading
    const bottomRef = useRef(null);

    /* ------------------------------------------
        Show the MXWidget when set to true and only
        if a user exists. Should happen after user
        logs in to ULink or creates an account.
       ------------------------------------------ */
    useEffect(() => { 
        if(props.user) {
            setShowMXWidget(true); //forces a re-render to show the widget
        }
     }, [props.user]);


    // Send MX data to server-side function
    async function sendMXData(userGuid: string, memberGuid: string) {
        const data = { 
            guid: userGuid,
            mguid: memberGuid,
            ulink_user_id: props.user.id,
            mx_env_prod: isProd(),
        }

        const response = await fetch(CloudFunctionsURL('mx_link_withid'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          })
          try {
            const returnData = await response.text();
          } catch(e) {
            console.error("error in hitting mx_link_withid", e);
            ULINK_UI.GenericError();
          }
    }

    // Send Alt form (phone number and partner) to cloud function
    async function postAltForm() {
        const data = { 
            name: name,
            email: email,
            phone: props.user.phone, 
            ulink_user_id: `${props.user.id}`,
        };

        const response = await fetch(CloudFunctionsURL('pre_reg_backupform'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          });
          try {
              const returnData = await response.text();
              setShowAltForm(false);
              setShowCompleteMsg(true);
              setShowMainContent(false);
            } catch(e) {
                console.log(e);
                ULINK_UI.GenericError();
          }
    }




    /* -----*-----------*---------------*-----
        MX Widget callback action functions
        to be passed into MXConnect component
    -*------------*-------------*------------- */
    function onPrimaryAction() { 
        setShowMXWidget(false);
        setShowCompleteMsg(true);
        setShowMainContent(false);
    };
    function onStepChange() {
        setShowAltFormBtn(true);
    }
    function onMemberConnected(userGuid: string, memberGuid: string) { 
        sendMXData(userGuid, memberGuid);
    };
    function MXUrlCreated() {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }



    return (
        <div id="AccountPreview">
            {
                showMainContent &&
                <>
                    <header>
                        <img src="/assets/img/AccountPreview/pbb-logo.png" alt="Pay-by-Bank logo which is a picture of a cellphone" />
                        <h1>Account Linking Preview</h1>
                    </header>

                    <div className="paragraph-text">
                        <p>
                            As part of our Reseller Training Program, we’re introducing you 
                            to the Pay-by-Bank experience that your merchant’s customers
                            will see to help you better answer questions from your merchant 
                            retail clients.
                        </p>
                    </div>


                    <div className="white-line"></div>


                    <div className="paragraph-text">
                        <p>
                            To use Pay-by-Bank, customers must link their 
                            personal bank accounts inside our secure ULink® microsite.
                        </p>
                    </div>


                    <div className="white-line"></div>


                    <h2>
                        This experience will walk you through the process 
                        of linking your personal bank account.
                    </h2>
                </>
            }   
           
            {/* --- MX WIDGET CODE --- */}

            {/* Shows MX Connect Widget when state is true */}
            { showMXWidget  
                && <MXConnect 
                    onURLCallback={MXUrlCreated}
                    primaryAction={onPrimaryAction} 
                    stepChange={onStepChange}
                    user={props.user} setUser={props.setUser}
                    memberConnected={onMemberConnected} /> 
            }

            {/* Shows bank-not-listed button after MX "Continue" btn clicked */}
            { showMXWidget
                && showAltFormBtn
                && <button onClick={() => {
                    setShowMXWidget(false);
                    setShowAltForm(true);
                }}>My bank isn't listed</button>
            }

            {/* Show Alt form when state is set to true */}
            { showAltForm 
                &&
                <div id="AltForm">
                    <TextInput 
                        customClass="altform-name"
                        name="name" 
                        placeholder="Your name"
                        label="Name"
                        singleField={name}
                        setSingleField={setName}
                    />

                    <TextInput 
                        name="email" 
                        placeholder="Email Address"
                        label="Email"
                        singleField={email}
                        setSingleField={setEmail}
                    />

                    <button onClick={() => { postAltForm() }}>Submit</button>
                </div>
            }

            {/* After Alt form or MX widget has been filled */}
            {
                showCompleteMsg 
                && <div id="CompleteMsg">
                        <img style={{width: "200px"}} src="/assets/img/AccountPreview/confirm-check.png" alt="Green checkmark inside a white box" />
                        <h1>SUCCESS!</h1>
                        <span>Your ULink® profile has been securely created in our microsite.</span>
                        <div className="white-line"></div>
                        <h2>Thank you!</h2>
                    </div>
            }

            {/* Only show button when all show states are false (initial state of things) */}
            { 
                !showMXWidget 
                && !showCompleteMsg 
                && !showAltForm
                && showMainContent
                && <button 
                    onClick={() => { 
                        props.requireUserOnly(); //makes a user log in to ULink with phone # to give us user object
                    }}>Let's Go!</button> 
            }

            <div ref={bottomRef} />
        </div>
    )
}