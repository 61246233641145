import React from "react";

import Location from "../data/Location";
import { MenuSection, MenuItem } from "../data/Menu"

import MenuItemDisplay from "./MenuItemDisplay"

/**
 * Component render a menu section as a group of items with a section header
 * @param {object} props 
 * @param {string} props.id HTML id, supplied for react-scroll
 * @param {Menu} props.menu
 * @param {Location} props.location
 * 
 * @param {MenuSection} props.section
 * @param {[MenuItem]} props.items 
 * 
 * @param { function(MenuItem):void } props.navigateItem Invoked when an item is clicked; should navigate the user to that item 
 */
 export default function(props) { 
    var ifUnavailable = null
    if(! props.section.available) ifUnavailable = <p className="Status">This section is currently unavailable.</p> 
    else if(! props.section.isAvailableNow()) ifUnavailable = <p className="Status">This section is unavailable until {props.section.hours.displayNextAvailableTime()}.</p> 
    

    return <section id={props.id} className="LocationMenuSection">
        <h1>{ props.section.name }</h1>
        <p>{ props.section.description }</p>
        { ifUnavailable } 

        <div className="content">
            { props.items.map(item => <MenuItemDisplay key={item.id} 
                item={item} section={props.section} menu={ props.menu } location={ props.location }
                navigate={ () => props.navigateItem(item) } 
            /> ) }
        </div>
    </section>
}