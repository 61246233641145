import React from 'react'; 

import { PHONE_NUMBER } from 'views/widgets/FormatInput'; 

import Location from 'lib/lib-smb-menus/data/Location';

/**
 * 
 * @param {object} props 
 * @param {Location} props.location
 * @returns 
 */
export default function(props) { 
    let location = props.location

    var statusmsg;
    if(location.isAvailableNow()) { 
        let closeTime = props.location.hours.displayAvailableUntilTime()
            closeTime = closeTime && ` until ${closeTime}`
        statusmsg = <><span className="open">Open</span>{ closeTime }</>;
    } else { 
        // quicc short circuit to avoid calling `displayNextAvailableTime()` 👇  if the location is disabled (since we wouldn't want to show it) 
        let openTime = !(props.location.isClosedManually()) && props.location.hours.displayNextAvailableTime()
            openTime = openTime && ` - opens ${openTime}`
        statusmsg = <><span className="closed">Closed</span>{ openTime }</>
    }

    
    return <header className="LocationBanner" 
        style={{ backgroundImage:   props.location.banner_url && 'url("' + props.location.banner_url + '")'   } } 
    >
        <div>
            <h1>{ location.name }</h1>
            <p>
                { location.address_1 }<br />
                { location.address_2 &&   <>{ location.address_2}<br /></>   }
                { `${location.city}, ${location.region} ${location.postal_code}` }
            </p>
            { location.phone && <p>{ PHONE_NUMBER.toDisplay(location.phone) }</p> }
            <div id="LocationStatus">{ statusmsg }</div>
        </div>
    </header>
}