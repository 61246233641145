import React from 'react'

import ItemModsDisplay from './ItemModsDisplay';

import Menu, { MenuItem } from "../data/Menu";
import { OrderItemModState } from "../data/Order";
import { CharacterLimit } from '../utils';

/**
 * 
 * @param {object} props
 * 
 * @param {MenuItem} props.item
 * @param {Menu} props.menu 
 * 
 * @param {OrderItemModState} props.modState 
 * @param {React.Dispatch<OrderItemModState>} props.setModState 
 * @param {string} props.instructions 
 * @param {React.Dispatch<OrderItemModState>} props.setInstructions
 * 
 * @param {boolean} props.showErrors
 * 
 * @returns 
 */
export default function(props) { 
    const item = props.item 

    let modList = <ItemModsDisplay 
            menu_item_id={ item.id } menu={ props.menu }
            modState={ props.modState } setModState={ props.setModState }
            showErrors={ props.showErrors }
        />

    return <div className='ItemDisplay'>
        <h1 id="name">{ item.name }</h1>
        <p id="description">{ item.description }</p>

        { 
            //checks if mods apply (ik this is hacky js brain)
            props.modState.state[0] && 

            <div className="ModifierListFrame">
                <h2 className="heading" id="ModifierHeader">Customize</h2>
                { modList }
            </div> 
        }

        { 
            item.custom_instructions && 
            <>
                <h2 className="heading">Special Instructions</h2>
                <textarea id="SpecialInstructions" value={ props.instructions } 
                onChange={ e => props.setInstructions(e.target.value.length <= CharacterLimit ? e.target.value : setInstructions) }/>
                <p className="CharacterCount">{ (props.instructions ?? '').length } / { CharacterLimit } </p>
            </>
        }
    </div>
}