import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';

import ULink from "./services/ULink"; 
import { ULINK_UI, SupportLink, firebaseEvent, setLogoutHandler, setShowDefaultErrorModal } from 'ulink_global';

import Big from "big.js";

import HamburgerMenu from "views/global/HamburgerMenu";
import MenuBar from "views/global/MenuBar";
import { LogIn, SetOrderMethod } from "./views/init-modals/InitFlow";

import LocationMenuView from "views/pages/LocationMenuView/LocationMenuView";
import MenuItemView from "views/pages/MenuItemView/MenuItemView";
import CheckoutView from "views/pages/CheckoutView/CheckoutView";
import OrderView from "views/pages/OrderView/OrderView";

import OrderReceipt from "data/OrderReceipt";
import User from "data/User";

import Order from "lib/lib-smb-menus/data/Order";
import Menu from "lib/lib-smb-menus/data/Menu";
import Location from "lib/lib-smb-menus/data/Location";
import Hours from "lib/lib-smb-menus/data/Hours";

import FlowSwitch from "views/pages/pay-in-store/FlowSwitch/FlowSwitch";
import CodeCheckout from "views/pages/pay-in-store/CodeCheckout/CodeCheckout";
import AddPayment from "views/pages/pay-in-store/AddPayment/AddPayment";
import Main from "views/pages/Campaign/Main";
import AccountPreview from "views/pages/Campaign/AccountPreview";

import 'core-js/stable' // polyfills

/**
 * 
 * @param {object} props 
 * @param {boolean} props.hamburgerOpen 
 * @param {function} props.hamburgerToggle
 * @param {object} props.dev Dev tools
 * 
 * @param {React.Component} props.modals
 * 
 * @param {React.Ref} props.refMainScroll Ref to the main scrolling element, so we can scroll to top on route change
 *  
 * @returns 
 */
function Frame(props) { 
    const { hamburgerOpen, hamburgerToggle } = props 

    useEffect(() => { 

        /*
        This type of direct DOM access is typically ill-advised when using React
        But:
            - I'm only doing it here, this once
            - It's not actually manipulating the structure of the DOM, which is 
            what's actually dangerous 
            - It's to a global element, guaranteed to already be here since it's in the
            layout template
            - Since it's a global element from the layout template, 
            it's outside of React and therefore I can't use refs 
            - It's necessary to do it this way to set these CSS Variables globally;
            that way styles applied to the `body` tag can also access them 
        */
        let body_style = Object.entries(ULINK_UI.CSS).map(x => `${x[0]}: ${x[1]}px`).join('; ');
        document.body.setAttribute('style', body_style);

    }, [])

    return <div id="FRAME">

        {/* Dynamic containers */}
        <div id="BannerContainer"></div>

        { props.modals || null }

        <CSSTransition in={ hamburgerOpen }  timeout={200} classNames="t-hamburger">
            <div id="DisplayRoot">
                <MenuBar 
                    hamburgerOpen={ hamburgerOpen } hamburgerToggle={ hamburgerToggle } 
                    /> 

                <HamburgerMenu 
                    hamburgerToggle={ hamburgerToggle }
                    user={ props.user } setUser={ props.setUser } logOut={ props.logOut }
                    dev={ props.dev }
                    /> 
                <div id="ContentHMenuOverlay" onClick={ hamburgerToggle }></div>

                <div id="ContentRoot" ref={ props.refMainScroll }>
                    <div id="NavbarShadowSpacer"></div>
                    { props.children }
                </div>
            </div>
        </CSSTransition>
    </div>
}



class ErrorBoundary extends React.Component { 
    constructor(props) { 
        super(props) 
        this.state = { hasError: false }
    }

    // Error boundary
    static getDerivedStateFromError(error) { return { hasError: true }; }

    render() { 
        if(this.state.hasError) return <Frame>
            <main id="ErrorBoundary" className="basic"> 
                <h1 className="PageTitle small">Error</h1>
                <p>Please try again, or feel free to contact us at {SupportLink} and we will investigate the issue.</p>
                <p>We sincerely apologize for any inconvenience.</p>
            </main>
        </Frame>


        return this.props.children; 
    }
}


function App() { 
    /*
        TODO: On mount
         - Set `window` observers
         - Set logout handler
         - Validate session

         - Browser history listeners 

        onPageChange = (location) => { 
            this.setState({ hamburgerOpen: false });

            // This seems like the wrong way to do this; should we use refs?
            document.getElementById('ContentRoot').scrollTop = 0; 
        }
     */

    /*
        TODO: on update
         - Check if needs user 
     */

    const [ hamburgerOpen, setHamburgerOpen ] = useState(false)
    const hamburgerToggle = () => setHamburgerOpen( x => !x)


    let [ errored, setErrored ] = useState(false) 
    let [ notFound, setNotFound ] = useState(false) 

    // Session state 
    /** @type {[User, function]} */
    let [ user, setUser ]           = useState(null)
    let [ sessionWasExpired, setSessionWasExpired ] = useState(false) 

    setLogoutHandler( () => setUser(null) )


    // Session restore attempt
    useEffect(() => { 
        ULink.user.getSession().then( setUser ) 
    }, [])


    //
    // Location state 
    //

    /*
        I'm keeping it down here at the root level for now since it's convenient,
        but obvious not all rendered routes have location params.
        Perhaps all routes with location params should be nested within a parent route or something 
     */
    /** @type {[ Location, React.Dispatch<Location> ]} */
    let [ location, setLocation ] = useState(null) 
    /** @type {[ Menu, React.Dispatch<Menu> ]} */
    let [ menu, setMenu ] = useState(null) 


    //
    // Order state
    // 

    /**
     * Completed order state - only used to hand off the state from the checkout view to the receipt view 
     * @type {[ OrderReceipt, function ]}
     */
    let [ orderReceipt, setOrderReceipt ] = useState(null) 


    /** @type {[ ?Order, React.Dispatch<Order> ]} */
    let [ order, setOrder ] = useState( null )

    const ORDER_LOCAL_KEY="v2_order"

    /**
     * Used specifically by the checkout view to clear state/storage after successful order
     * 
     * Bypasses the no-op on the useEffect that writes everything else to localStorage 
     */
    const clearOrder = () => { 
        setOrder(null)
        window.localStorage.removeItem(ORDER_LOCAL_KEY)
    }


    // Attempt to restore persisted order state
    useEffect(() => { 
        try { 
            let text = window.localStorage.getItem(ORDER_LOCAL_KEY)
            if(! text) return 

            let json = JSON.parse(text) 
            let order = new Order(json)
            setOrder(order)
            console.info("Restored order state") 
        }
        catch(err) { 
            console.error("Error restoring order state:", err) 
        }
    }, [])

    // Persist order state on change
    useEffect(() => { 
        if(! order) return 

        // Throttling
        // We include this here just because of quantity changes in CheckoutView; they can happen pretty quickly 
        const now =  Date.now()
        if(window.storageLock && now - window.storageLock < 250) { 
            console.debug("Order Persist threshold not reached; ignoring") 
            return 
        }
        window.storageLock = now 

        try { 
            window.localStorage.setItem(ORDER_LOCAL_KEY, JSON.stringify(order))
        } 
        catch(err) { 
            console.error("Error persisting order state:", err) 
        }
    }, [ order ])



    // Clear the Order when the location has changed
    useEffect(() => { 

        // This effect also used to initialize the order once `location` and `orderMethod` were there;
        //  but it was re-initializing the order after clearing on a successful checkout
        //  while keeping the previous `orderMethod` for some React reason,
        //  even though the effect was directly downstream of the `clearOrder` function that changed the state 
        //
        // Now `orderMethod` is no longer a separate state, 
        //  so we initialize the `Order` directly from the `SetOrderMethod` modal, 
        //  since it requires location data before being displayed (in order to display the appropriate methods) 

         if( order && location && order.location_id != location.id ) { 
            console.info("Detected location change; re-initializing order")
            clearOrder()
        }

    }, [ order, location ])




    /**
     * Fetches the location, and updates the `location` and `menu` state. 
     * No-op if there's already a location and menu matching the requested ID.
     * 
     * The `location_id` parameter comes directly from the URL parameters. 
     * It's loosely typed on purpose; `location_id` comes from URL params so it's a string. 
     * 
     * @param {string} location_id  **string**; see above comments regarding typing
     */
    const fetchLocation = (location_id) => { 
        if( location && menu ) { 
            if(location.id == location_id) return // 👈  See above comments on typing 
            else { 
                console.info("Detected location change; overwriting current", location.id, location_id) 
            }
        }
        // I tried to pull `location_id` from params directly here,
        //  but params are only available within a react-router route,
        //  which is unfortunate but makes sense I guess 

        ULINK_UI.ajax.before() 
        ULink.location(location_id)
            .then(result => { 
                setLocation(result.location)
                setMenu(result.menu) 

                window.AppLocation = () => result.location
                window.AppMenu = () => result.menu
            })
            .catch(res => { 
              if(res.status == 404 || res.status == 410) setNotFound(true) 
              else ULINK_UI.ajax.catch(res)   
            })
            .finally(ULINK_UI.ajax.finally)
    }

    const logOut = () => { 
        firebaseEvent('logout')
        ULink.user.logOut().then(() => { 
            setUser(null)
            setHamburgerOpen(false) // if doing from hamburger menu lol
        })
        .catch(e => { 
            console.error("Could not log out", e) 
            alert("There was an error logging out of your account.  If you need to log out for security reasons, you can delete the cookies for orderahead.ulink.mobi in your browser's settings.  We apologize for the inconvenience.")
        })
    }

    //
    // Init flow 
    //

    // Previously this logic was performed in `InitFlow.jsx`
    // Where necessary, we show `SetOrderMethod` first, then `LogIn` 

    // If `true`, require a logged-in user, showing the modal if necessary
    const [ requiresUser, setRequiresUser ] = useState(false) 
    // If `true`, require an order method, showing the modal if necessary
    const [ requiresMethod, setRequiresMethod ] = useState(false) 

    // Patch function against the older version; 
    // This useful since most things except `OrderView` require both user and order method 
    const requireInitFlow = () => { setRequiresMethod(true); setRequiresUser(true) }

    // The catch here is that we must update all state values whenever the view requirements change,
    // so that requirements still loaded from previous views don't interfere
    const requireUserOnly = () => { setRequiresMethod(false); setRequiresUser(true) }

    // We must wait for the location to be fetched before entering the init flow, 
    // since the available order methods depend on the location being viewed
    // We must also verify that the order method modal displays first if needed,
    // so as to not break our flow requirements

    var initModal = null 
    if(requiresMethod && !(order?.method)) { 
        if(location) initModal = <SetOrderMethod location={location} order={order} setOrder={ setOrder } />
        else if(!user && requiresUser) console.debug("Needs user, but also method; waiting for location to load before displaying LogIn")
    }
    else if(!user && requiresUser)  initModal = <LogIn user={user} setUser={setUser} sessionWasExpired={sessionWasExpired} />


    //
    // Other modals
    //

    // Error modal invoked from API responses, by the "default" catch handler
    const [ defaultErrorModal, setDefaultErrorModal ] = useState(null) 
    setShowDefaultErrorModal( setDefaultErrorModal )


    // Display

    const modals = <>
        { initModal }
        { defaultErrorModal }
    </>


    // 
    // Dev state
    // 

    let [ devBypassTime, setDevBypassTime ] = useState(Hours.BYPASS) 
    const dev = { 
        bypassTime: devBypassTime, 
        setBypassTime: bool => { Hours.BYPASS = bool; setDevBypassTime(bool); }
    }


    //
    // Final router setup
    //


    const pageSupport = <main id="Support" className="basic">
        <h1 className="PageTitle">Support</h1>
        <p>For cancellations, refunds or issues with your order, please contact the merchant directly. If you're having any technical difficulties please contact customer support at {SupportLink}.</p>
    </main>

    const pageIndex = <main id="IndexPlaceholder" className="basic">
        <h1 className="PageTitle">Whoops!</h1>
        <p>Looks like you may be searching for something else. </p>
        <p>For order ahead features or to place an order, please visit the merchant’s website.</p>
    </main>

    const pageNotFound = <main id="NotFound" className="basic">
        <h1 className="PageTitle">Page not found</h1>
        <p>We're sorry - the address you are looking for could not be found.</p>
        <p>If you believe this to be an error, please feel free to contact us at {SupportLink}.</p>
    </main>



    const location_props = { location, menu, order, fetchLocation, requireInitFlow, setNotFound }

    const refMainScroll = useRef(null)
    const scrollToTop = () => { 
        if(refMainScroll.current) refMainScroll.current.scrollTo(0,0)
    }

    var content = null 
    if(notFound) content = pageNotFound 
    else content = <BrowserRouter>
        <Routes>

            {/* New flows, supporting pay-in-store */}

            <Route 
                path="/locations/:location_id/flow-switch"
                element={ <FlowSwitch scrollToTop={scrollToTop}
                    {... location_props} 
                    user={ user } requireUserOnly={ requireUserOnly }
                /> }
            />

            <Route 
                path="/locations/:location_id/code-checkout" //place marker
                element={ <CodeCheckout scrollToTop={scrollToTop}
                    {... location_props} 
                    user={ user } setUser={ setUser }
                    requireUserOnly={ requireUserOnly }
                    hamburgerToggle={ hamburgerToggle }
                /> }
            />

            <Route 
                path="/locations/:location_id/add-payment/"
                element={ <AddPayment scrollToTop={scrollToTop}
                    {... location_props} 
                    user={ user } setUser={ setUser }
                    requireUserOnly={ requireUserOnly }
                    hamburgerToggle={ hamburgerToggle }
                /> }
            />

            <Route 
                path="/campaign/launch-reception/:partner"
                element={ <Main scrollToTop={scrollToTop}
                    {... location_props} 
                    user={ user } requireUserOnly={ requireUserOnly }
                    hamburgerToggle={ hamburgerToggle }
                /> }
            />
            
            
            <Route 
                path="/campaign/account-preview"
                element={ <AccountPreview scrollToTop={scrollToTop}
                    {... location_props} 
                    user={ user } requireUserOnly={ requireUserOnly }
                    hamburgerToggle={ hamburgerToggle }
                /> }
            />

            {/* Existing ordering flows */}

            <Route  
                path="/locations/:location_id/menu"
                element={ <LocationMenuView scrollToTop={scrollToTop} 
                    {... location_props} user={ user }  
                /> }
            />
            <Route 
                path="/locations/:location_id/menu/items/:menu_item_id"
                element={ <MenuItemView scrollToTop={scrollToTop} 
                    {... location_props } setOrder={ setOrder } 
                /> }
             /> 

            <Route 
                path="/checkout/item/:order_item_key"
                element={ <MenuItemView scrollToTop={scrollToTop} 
                    {... location_props } setOrder={ setOrder } 
                /> } 
            />

            <Route 
                path="/checkout"
                element={  <CheckoutView scrollToTop={scrollToTop} 
                    {... location_props} 
                    user={ user }  setUser={ setUser }

                    setOrder={ setOrder } 
                    clearOrder={ clearOrder } setOrderReceipt={ setOrderReceipt }

                    hamburgerToggle={ hamburgerToggle }
                    dev={ dev }
                /> }
            />

            <Route 
                path="/receipt/:smb_order_id"
                element={ <OrderView scrollToTop={scrollToTop} 
                    orderReceipt={ orderReceipt } 
                    user={ user } requireUserOnly={ requireUserOnly } logOut={ logOut }
                    setNotFound={ setNotFound } setErrored={ setErrored }
                /> }
             />

            <Route element={ pageSupport }      path="/support" />
            <Route element={ pageIndex }        path="/" /> 
            <Route element={ pageNotFound }     path="*" /> 

        </Routes>
    </BrowserRouter>


    return <Frame refMainScroll={refMainScroll}
        hamburgerOpen={hamburgerOpen} hamburgerToggle={hamburgerToggle}  dev={ dev } 
        user={ user } setUser={ setUser } logOut={ logOut }
        modals={modals}
    >
        <div id="RootComponent">
            { content }
        </div>
    </Frame>
}





function Root() { 
    return <ErrorBoundary>
        <App /> 
    </ErrorBoundary>
}


document.addEventListener("DOMContentLoaded", function () {
    Big.RM = Big.roundUp

    ReactDOM.render(React.createElement(Root), document.getElementById("APPROOT"));
});