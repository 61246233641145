import React, { useState } from 'react'; 

import Order from 'lib/lib-smb-menus/data/Order';
import Location from 'lib/lib-smb-menus/data/Location';

/**
 * Prompt the user to select an order method 
 * @param {object} props 
 * @param {Location} props.location
 * @param {Order} props.order
 * @param {React.Dispatch<Order>} props.setOrder
 * @returns {React.Component}
 */
export default function(props) { 

    let [ isEnteringTable, setEnteringTable ] = useState(false); 
    let [ table, setTable ] = useState(''); 

    let hasTable = table != ''; 
    let validTable = /^\d+$/.test(table); 
    let invalidTable = hasTable && !validTable; 

    const setOrderMethod = (method) => { 
        props.setOrder( 
            props.order ? props.order.copy().withMethod(method) : Order.new(props.location.id, method) 
        )
    }

    return <div id="FulfillmentPrompt" data-dinein={ isEnteringTable }>

        <div id="img-DineIn">
            <img src={ '/assets/img/dine-in.png' } />
        </div>

        <div id="img-TakeOut">
            <img src={ '/assets/img/take-out.png' } />
        </div>

        <div className="option" id="opt-DineIn"> 
            <button className="positive" onClick={ () => setEnteringTable(true) }>
                Dine-in
            </button>

            <div id="smol-border"></div>
        </div>

        <div className="option" id="opt-TakeOut">
            <button className="positive" onClick={ () => setOrderMethod( Order.Methods.TakeOut() )  } >
                Take out
            </button>
        </div>

        <div id="FTableView">
            { invalidTable 
                ? <p className="error">Please enter a value containing only numbers.</p> 
                : <p>
                    Please Enter Table # <br />
                    (below QR code)
                </p> 
            }

            <input type="text" inputMode="numeric" value={ table } onChange={ e => setTable(e.target.value) } 
                aria-invalid={ invalidTable } />


            <div id="FfmtDone"><button className="inline" aria-disabled={ !validTable } onClick={ () => validTable && setOrderMethod( Order.Methods.DineIn(table) ) } >Done</button></div>

            <div id="FfmtBack"><button className="plain link" onClick={ () => { setTable(''); setEnteringTable(false) } }>&larr; Go Back</button></div>
        </div>

    </div>;
}