import Big from "big.js"

/**
 * `Checkout` represents an in-progress code checkout
 */
export default class { 

    uri: string 
    id: number 
    location_id: number 

    code: string 
    amount: Big
    expires: Date

    completed: boolean
    has_push: boolean 

    constructor(json: any) { 
        this.uri         = json.uri 
        this.id          = json.id 
        this.location_id = json.location_id 
    
        this.code        = json.code 
        this.amount      = new Big(json.amount)
        this.expires     = new Date(json.expires)
    
        this.completed   = json.completed
        this.has_push    = json.has_push 
    }    
    
}
