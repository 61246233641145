import React, { useState, useEffect } from 'react'; 
import { useParams } from 'react-router-dom';

import OrderReceipt, { OrderReceiptItem } from 'data/OrderReceipt';

import { ULINK_UI } from 'ulink_global';

import Header from '../LocationMenuView/Header';
import ULink from 'services/ULink';
import User from 'data/User';

import Icon from 'lib/lib-sionic/Icon';

/**
 * 
 * This component was split off from `Summary.jsx` since it now follows a different data structure
 * 
 * See comments there about the annoying <table> validation rules 
 * 
 * @param {object} props 
 * @param {function} props.scrollToTop
 * 
 * @param {?OrderReceipt} props.OrderReceipt pulled in only upon transition from checkout view. Otherwise, fetched by useEffect
 * @param {User} props.user
 * @param {function()} props.requireUserOnly
 * @param {function()} props.setErrored
 * @param {function()} props.setNotFound 
 * 
 */
export default function(props) { 
    //TODO: Necessary to update users if new?  I don't think so, but that was in the old version

    let [ receipt, setReceipt ] = useState(props.OrderReceipt)

    const { smb_order_id } = useParams()

    useEffect(() => { 
        props.scrollToTop()
        props.requireUserOnly()
    }, [])

    // Fetch receipt if necessary
    useEffect(() => { 
        if(! receipt) { 
            if(! props.user) { 
                // console.debug("Needs to fetch receipt, waiting for user log in") 
                return 
            }

            ULink.fetchOrder(smb_order_id)
                .then(setReceipt)
                .catch(err => console.error("Fetching receipt;", err) )

        }

    }, [props.user])

    if(! receipt) return null 

    const location = receipt.location 


    //
    // Building navigation link
    //

    // Navigation link 
    //TODO: Google place ID?  Definitely would be more reliable here [Clubhouse #809]
    let { latitude, longitude, address_1, address_2, city, region, postal_code } = location; 
    let hasAddr = address_1 && city && region && postal_code; 

    let destination, navurl; 
    if(hasAddr)                     destination = [ address_1, address_2, city, region, postal_code ].filter(x => x).join(',');
    else if(latitude && longitude)  destination = `${latitude},${longitude}`; 

    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    let iOS = /iPad|iPhone|iPod/.test( navigator.platform ) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1); 

    if(destination) { 
        destination = encodeURI(destination); 

        if(iOS) { 
            // https://developer.apple.com/library/archive/featuredarticles/iPhoneURLScheme_Reference/MapLinks/MapLinks.html
            if(hasAddr)                 navurl = `http://maps.apple.com?daddr=${destination}`;
            else                        navurl = `http://maps.apple.com?ll=${destination}`;
            // https://developers.google.com/maps/documentation/urls/get-started 
        } else                          navurl = `https://maps.google.com/maps/dir/?api=1&destination=${destination}`
    }        


    return <div id="OrderView" className="RootComponent">  

        <Header location={ location } />
        <main>

            {/* { this.state.userWasNew && <p>You can manage payments in your profile.</p> } */}
            {/* //TODO: New user message 👆 */}

            <section id="Info">
                <p id="OrderNumber">Order #{ receipt.order_number }</p>
                <p id="OrderDate">{ receipt.created_at.toLocaleDateString() } { receipt.created_at.toLocaleTimeString('en-US', { hour: 'numeric' , minute: '2-digit' }) }</p>
                <p id="OrderFulfillment">Order for { location.orderMethodDisplay(receipt.method) }</p>
            </section>

            
            <section id="Receipt">
                <table className='Receipt'>
                    <tbody>{
                        //NOTE: Keying by index since this should be immutable
                        receipt.items.map( (item, i) => <ReceiptItem key={i} item={item} />)
                    }</tbody>
                    <tfoot>
                        <tr>
                            <td>Subtotal</td>
                            <td className='price'>{ ULINK_UI.Price_s(receipt.subtotal) }</td>
                        </tr>
                        <tr>
                            <td>Tax</td>
                            <td className='price'>{ ULINK_UI.Price_s(receipt.tax) }</td>
                        </tr>{ 
                        
                        receipt.tip && 
                        <tr>
                            <td>Tip</td>
                            <td className='price'>{ ULINK_UI.Price_s(receipt.tip) }</td>
                        </tr>

                        }{
                            receipt.fees.gt(0) && 
                            <tr>
                                <td>Service Fee</td>
                                <td className='price'>{ ULINK_UI.Price_s(receipt.fees) }</td>
                            </tr>
                        }<tr>
                            <td>Total</td>
                            <td className='price total'>{ ULINK_UI.Price_s(receipt.total) }</td>
                        </tr>
                    </tfoot>
                </table>
            </section>



            <section id="Footer">
                <p id="OrderPickup">Your order will be ready at { receipt.expected_by.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) }</p>

                <p>A copy of this receipt has been sent to your email. If you are using a public computer, please <button className="plain" onClick={ props.logOut }>sign out</button>.</p>

                
                { navurl && 
                    <a href={ navurl } target="_blank"><Icon name="directions" /> Navigate to { receipt.location.name }</a> 
                }
            </section>
        </main>
    </div>

}


/**
 * 
 * @param {object} props 
 * @param {OrderReceiptItem} props.item 
 */
function ReceiptItem(props) { 

    //TAG: [ISSUE #5] This does not implement nested mods 
    let $modItems = props.item.mods
        .flatMap(mod => mod.selections)
        .map((sel, i) => { 
            //NOTE: Keying by index since this should be immutable

            return <tr className='Modifier' key={i}>
                <td>{ sel.name }</td>
                <td>{ sel.price.gt(0) && ULINK_UI.Price_e(sel.price) }</td>
            </tr>
        })

    let $customInstructions = 
        props.item.custom_instructions ? 
        <tr className="Modifier">
            <td colSpan={2} className='CustomInstructions'>{ props.item.custom_instructions }</td>
        </tr>
        : null 

    let $quantity = 
        props.item.quantity > 1 ? 
        <tr className='Modifier'>
            <td></td>
            <td className='Quantity'>x { props.item.quantity }</td>
        </tr>
        : null

    return <>
        <tr className={`Item ${ props.item.mods.length > 0 ? 'has-modifiers' : '' }`}>
            <td className='name'>{ props.item.name }</td>
            <td>
                {/* //TAG: [ISSUE #7] Gotta finish up Price-by-Mod */}
                { ULINK_UI.Price_e( props.item.price ) }
                {/* For some reason it was originally Price_e for mods and span.price{Price_s} for the item here */}
            </td>
        </tr>{ 
            $modItems
        }{
            $customInstructions
        }{ 
            $quantity
        }
    </>
}