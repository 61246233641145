import React, { useEffect, useState } from "react";

// Functions
import { newCreditCardState, newBankAccountState, getPaymentRailType, setDefaultPayState } from '../../CheckoutView/PaymentControl';

//Components
import PaymentControl, { getPaymentAPIValue, PaymentType } from "views/pages/CheckoutView/PaymentControl";
import AddTip from "../helpers/AddTip";

// For calculating numbers
import Big from 'big.js';

// Helpers
import Checkout from "data/Checkout";
import Incentive from "lib/lib-sionic/data/Incentive";
import { currency } from "lib/lib-sionic/utils";
import Transaction from "lib/lib-sionic/data/Transaction";

import { handlePaymentError, ULINK_UI } from "ulink_global";
import ULink from "services/ULink";

// Interfaces
import MainView from "../interfaces/MainView";


// Custom interface for this component
interface CheckoutPay extends MainView {
    checkout: Checkout,

    // UI state from parent
    showPopup: any, 
    setShowPopup: any, 

    // Callbacks
    onComplete: (transaction: Transaction) => void, 
    onCancel: () => void, 
 }


export default function(props: CheckoutPay) { 

    // Component state

    const [ tipData, setTipData ]                       = useState({custom: false, amount: null});
    const [ tipSelectVisible, setTipSelectVisible ]     = useState(false);

    let [ paymentType, setPaymentType ] : [PaymentType, React.Dispatch<React.SetStateAction<PaymentType>> ] = useState("SAVED_BANK");
    let [ paymentSavedID, setPaymentSavedID ]           = useState(null);
    let [ cardState, setCardState ]                     = useState( newCreditCardState() ); // <-- payWithCard  
    let [ bankState, setBankState ]                     = useState( newBankAccountState() ); // <- pay by Bank
    let [ doValidate, setDoValidate ]                   = useState(false)
    let [ checkoutProcessing, setCheckoutProcessing ]   = useState(false); //true when waiting for the checkout + polling to complete



    useEffect(() => { 
        if(! props.user) return 
        setDefaultPayState(props.location, props.user, setPaymentType, setPaymentSavedID); 
    }, [props.user])


    // Derived data
    
    const paymentAPIValue = getPaymentAPIValue(paymentType, bankState, cardState, paymentSavedID);
    const paymentValid  = !! paymentAPIValue;

    const tipScalar = new Big(tipData.amount || 0) 
    const tipValue  = currency(tipData.custom ? tipScalar : tipScalar.times(props.checkout.amount))

    const appliedPerks = Incentive.ApplyAll(props.location.incentives, props.checkout.amount, getPaymentRailType(paymentType))

    const total = props.checkout.amount.minus(appliedPerks.totalDiscount).plus(tipValue)


    // Action

    const [ locked, setLocked ] = useState(false) 
    const onCheckout = () => { 
        if(!paymentValid) { 
            setDoValidate(true) 
            return 
        }

        if(locked) return 
        setLocked(true) 

        ULINK_UI.ajax.before()
        ULink.checkouts.complete(props.checkout.uri, { 
            payment: paymentAPIValue,
            tip: tipValue, 
            expected_total: total, 
        })
        .then(txn => { 
            ULINK_UI.ajax.finally() 
            props.onComplete(txn)
        })
        .catch(res => { 
            ULINK_UI.ajax.finally() 
            setLocked(false) 
            handlePaymentError(res, props.setShowPopup) 
        })
    }


    return <div id="Summary">
        <div className="info-block">
            <div className="info-wrapper">
                <h1>{props.location.name}</h1>
                <h2 className="merchant-address">{props.location.address_1}, {props.location.city}</h2>
            </div>
        </div>

        <div id="OrderSummary">
            <div className="summary-header">
                <h2><b>Order Summary</b></h2>
            </div>

            <div className="subtotal">
                <h2>Sale Total{ /* I don't like this name*/ }</h2> 
                <h2>${ ULINK_UI.Price_s(props.checkout.amount) }</h2>
            </div>

            { 
                appliedPerks.lineItems.map((item, i) => <div className="incentive" key={ i }>
                    <h2>{ item.incentive.textDescription() }</h2>
                    <h2><b>{ item.discount ? `$${ULINK_UI.Price_s(item.discount)}` : "----" }</b></h2>
                </div>)
            }

            <div className="tip">
                <AddTip 
                    showPopup={props.showPopup} setShowPopup={props.setShowPopup}

                    tipSelectVisible={tipSelectVisible} 
                    setTipSelectVisible={setTipSelectVisible} 
                    tipData={tipData} setTipData={setTipData}
                />
            </div>
            <div className="tip">
                <h2>Tip</h2>
                <h2>${ ULINK_UI.Price_s(tipValue) }</h2>
            </div>

            <div className="PaymentMethodControl">
                <PaymentControl
                    user={ props.user }
                    setUser={ props.setUser }
                    location={props.location}
                    {... { 
                        doValidate, 
                        paymentType, setPaymentType, 
                        cardState, setCardState, bankState, setBankState,
                    }}
                    paymentMethodID={ paymentSavedID } setPaymentMethodID={ setPaymentSavedID }

                    hamburgerToggle={ props.hamburgerToggle }
                />
            </div>

            <div className="withdraw-amount">
                <div><h2><b>Total Amount</b></h2></div>
                <h2><b>${ ULINK_UI.Price_s(total) }</b></h2>
            </div>

            <div id="OKButton">
                { !checkoutProcessing &&
                    <button id="CheckoutCancelBtn" onClick={ props.onCancel }>CANCEL</button>
                }

                <button id="CheckoutDoneBtn" 
                        className={checkoutProcessing ? 'button--loading' : ''} 
                        onClick={() => {
                            onCheckout();
                            setCheckoutProcessing(true);
                        } }>
                        { !checkoutProcessing && <span className="button__text">CHECKOUT</span> }
                </button>
            </div>
        </div>
    </div>

}