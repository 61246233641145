import React, { useState, useEffect } from "react"

import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Scroll from 'react-scroll';

import { ULINK_UI } from 'ulink_global';

import LocationHeader from './Header'; 

import User from "data/User";

import Location from "lib/lib-smb-menus/data/Location";
import Menu, { MenuSection } from "lib/lib-smb-menus/data/Menu";
import Order from "lib/lib-smb-menus/data/Order";

import MenuItemDisplay from "lib/lib-smb-menus/display/MenuItemDisplay";
import MenuSectionGroupDisplay from "lib/lib-smb-menus/display/MenuSectionGroupDisplay";
import MenuSectionButtonDisplay from "lib/lib-smb-menus/display/MenuSectionButtonDisplay";

import Icon from 'lib/lib-sionic/Icon';


/**
 * Root view component for a location's menu 
 * @param {object} props 
 * @param {function} props.scrollToTop
 * 
 * @param {User} props.user
 * 
 * location_props
 * @param {Location} props.location 
 * @param {Menu} props.menu 
 * @param {Order} props.order
 * @param {function} props.requireInitFlow
 * @param {function} props.fetchLocation 
 */
export default function(props) { 
    const navigate = useNavigate()

    useEffect( () => { 
        props.requireInitFlow()
        props.scrollToTop()
    }, [] )

    let { location_id } = useParams()

    let [ filter, setFilter ] = useState('')
    const isFiltering = filter != ''

    useEffect(() => { 
        //TODO: Set needs user if location open 
        props.fetchLocation(location_id) 
    }, [])



    if(!(props.location && props.menu)) return null 


    /*
        NB: There was a commented-out `handleScroll` function in the original implementation that 
            was obosleted by CSS `position: sticky`. 
        A comment was left there that said "We will need to bring it back to life for the horizontal scroll tracking"
        It can still be found in the legacy archived repo 
     */ 

    
    let CSS = ULINK_UI.CSS; 
    let scrollOffset = 0
    + CSS['--LocationMenu-categories-height']
    + CSS['--LocationMenu-filter-height'];




    //TODO: Firestore configs 
    /**
     * If `false`, only the menu sections will be displayed, and the user clicks into each one 
     */
    let showItems = true; 




    /** @type {[MenuSection]} */
    let sections = props.menu.allCategories()

    const SectionScrollButton = Scroll.ScrollLink((props) => { 
        let reactScrollProps = {  
            onClick:    props.onClick,
            className:  props.className,
        }
        return <div role="button" {... reactScrollProps} >{ props.sectionName }</div>
    })

    const SectionScrollGroup = Scroll.ScrollElement(MenuSectionGroupDisplay)

    let contents = [] 
    if(filter == '') { 
        if(showItems) { 
            contents = sections.map(section => <SectionScrollGroup key={section.id}
                    location={ props.location } menu={props.menu} section={section} items={ props.menu.menuItems(section.id) } 
                    navigateItem={ item => navigate(`items/${item.id}`) }
                    id={ 'section-' + section.id } // react-scroll
                />)
        }
        else { 
            contents = sections.map(section => <MenuSectionButtonDisplay key={section.id} section={section} navigate={ () => navigate(`section/${props.section.id}`) } /> )
        }
    } else { 
        contents = props.menu.filterItems(filter).map(result => 
            <MenuItemDisplay key={result.item.id} 
                item={result.item} section={result.section} menu={props.menu} location={ props.location }
                navigate={ () => navigate(`items/${result.item.id}`) } 
            /> 
        )
    }


    return  <div id="LocationMenuView">

        <LocationHeader location={ props.location } />

        <main className="nopad">

        <div id="Sticky">

            <div id="Filter">
            <Icon name="find_in_page" />
            <input type="text" placeholder="Search" value={filter} onChange={ e => setFilter(e.target.value) } />
            </div>

            {
                // I wanted to hide this on `filter== ''` at first,
                //  but then I found this legacy issue.  Not sure but it may have merit 
                // https://github.com/SionicMobile/ulink-web/issues/11

                ( showItems) && 
                <nav className="MenuCategories" >
                { 
                    sections.map(section => <SectionScrollButton
                        key={section.id}
                        sectionName={section.name}

                        //react-scroll
                        containerId="ContentRoot"
                        to={ 'section-' + section.id} smooth={true} offset={ -scrollOffset } // NEGATIVE!
                        spy={true} activeClass="spy-active"
                    />)
                }
                </nav>
            }


        </div>

        { 
            !( props.location.isAvailableNow() ) && 
            <p className="LocationStatus closed">This location is currently closed. You may view the menu, but ordering is unavailable at this time.</p>
        }

        <div data-filtering={ filter != '' } data-show-items={ showItems }>
            {contents}
        </div>



        {
            props.order && props.order.items.length > 0 && props.user && !isFiltering && 
            
            <>
            <div className="Checkout-spacer" id="Checkout-wrapper">

                <Link to="/checkout" id="Checkout" role="button" className="positive">
                        Checkout - ${ ULINK_UI.Price_s( props.menu.calcOrderSubtotal(props.order) ) }
                </Link>
            </div>
            
            <div className="Checkout-spacer"></div>
            </>
        }

        </main>

    </div>
}

