import Big from "big.js";
import React from "react";

import { ULINK_UI } from 'ulink_global';
import Location from "../data/Location";

import Menu, { MenuItem, MenuSection } from "../data/Menu";

/**
 * Individual menu item component
 * @param {object} props 
 * @param {MenuItem} props.item 
 * @param {MenuSection} props.section 
 * @param {Menu} props.menu 
 * @param {Location} props.location
 * @param {function():void} props.navigate Invoked on click; should navigate the user to the item
 */
 export default function(props) { 
    const item = props.item 
    const available = item.available && props.section.isAvailableNow()


    let priceLine; 
    if(available) { 

        const startingPrice = item.price.eq(new Big(0)) && props.menu.getItemStartingPrice(item.id) 
        if(startingPrice) { 
            priceLine = <p className="item-price">Starting at { ULINK_UI.Price_e( startingPrice ) }</p>
        } else { 
            if(item.price.eq(new Big(0))) console.warn("Could not find starting price for zero-priced menu item") 

            priceLine = <p className="item-price">{ ULINK_UI.Price_e( item.price ) }</p>
        }

    }
    else { 
        priceLine = <p className="item-price">Sorry, this item is currently unavailable</p>
    }


    var style = null 
    if(item.image_url) style = { 
        backgroundImage: `url(${item.image_url})`, 
        backgroundSize: props.location.images_contain ? 'contain' : 'cover',
    }
    let img = <div className="image" style={style}></div>

    return <div className='LocationMenuItem' onClick={props.navigate}
        //NOTE: Here we use aria-disabled to mean "unavailable" - but it has different semantics in MenuItemView
        //  a ModifierItem can be aria-disabled if the maximum amount of selections has been reached - 
        //      not necessarily "unavailable" in that case 
        aria-disabled={ !available }
    >
        <div>
            <div>
                <h2>{ item.name }</h2>

                <p className="desc">{ item.description }</p>
                { priceLine }
            </div>

            { img }
        </div>

        { !available && <div className="fade"></div> }
    </div>
}