export default class User { 

    static testPaymentMethod = (def) => ({
        "id": Math.random(),
        "default": def ?? true,
        "first_name": "John",
        "last_name": "Doe",
        "status": 0,
        "status_formatted": "active",
        "masked_card": "1119",
        "created_at": "2021-03-03T12:22:54.785Z",
        "expiration_month": "12",
        "expiration_year": "26",
        "chargeable": true
      })

    static test = () => ({
        isDEV: true,

        first_name:     "Test",
        last_name:      "User",
        phone:          "4041234567",
        email:          "conley@sionicmobile.com",

        payment_methods:            [ this.testPaymentMethod(), this.testPaymentMethod(false) ]
    })


    constructor(json) { 

        /** @type {number} */
        this.id             = json.id
        /** @type {string} */
        this.name           = json.name 
        /** @type {string} */
        this.phone          = json.phone 
        /** @type {string} */
        this.email          = json.email 
        /** @type {string} */
        this.uri            = json.uri 
        /** @type {string} */
        this.created_at     = json.created_at
        /** @type {string} */
        this.updated_at     = json.updated_at 
        /** @type {{ await_banks: string }} */
        this._links         = json._links
        /** @type {number} */
        this.status         = json.status


        /** 
         * TAG: [DEPRECATED]
         * Legacy list of card payment methods - will be replaced with `pm_cards` and `pm_banks`
         * @type {[PaymentMethod]} 
         */
        this.payment_methods            = json.pm_cards.map(x => new PaymentMethod(x)).concat(json.pm_banks.map(x => new BankPaymentMethod(x)));

        /** @type {[PaymentMethod]}  */
        this.pm_cards = json.pm_cards.map(x => new PaymentMethod(x))
        /** @type {[*]} */
        this.pm_banks = json.pm_banks.map(x => new BankPaymentMethod(x))

        //DEV
        
    }


    hasPaymentMethods() { return this.payment_methods.length > 0 }

    /** @type {PaymentMethod} */
    getPaymentMethod(uri) { 
        return this.payment_methods.find(x => x.uri === uri) 
    }
    /** @type {PaymentMethod} */
    getDefaultPaymentCard() { 
        return this.pm_cards.find(x => x.default)|| (this.pm_cards.length ? this.pm_cards[0] : null)
    }
    /** @type {PaymentMethod} */
    getDefaultPaymentBank() { 
        return this.pm_banks.find(x => x.default) || (this.pm_banks.length ? this.pm_banks[0] : null)
    }

    /**
     * @returns {User}
     */
    copy() { return Object.assign(Object.create(Object.getPrototypeOf(this)), this) }


    /**
     * Appends the provided list of Bank `methods` to the current list
     * @param {Array<BankPaymentMethod>} methods
     */
    withAddBankPaymentMethods(methods) { 
        this.pm_banks = this.payment_methods.concat(methods) 
        return this 
    }

    /**
     * 
     * @param {PaymentMethod} method 
     * @returns {User}
     */
    withAddPaymentMethod(method) { 
        this.payment_methods.push(method) 
        return this 
    }

    /**
     * @param {PaymentMethod} method 
     * @returns {User}
     */
    withRemovePaymentMethod(method) { 
        this.payment_methods = this.payment_methods.filter(x => x.uri !== method.uri) 
        return this
    }

    /**
     * Updates the default payment method to be the `method` given, 
     * inserting it into the list if necessary. 
     * @param {PaymentMethod} method 
     * @returns {User}
     */
    withDefaultPaymentMethod(method) { 
        /** @type {[ PaymentMethod ]} */
        var methods = [] 
        var replaced = false

        this.payment_methods.forEach(val => { 
            if(val.uri === method.uri) { 
                replaced = true 
                methods.push(method)
            } else { 
                val.default = false 
                methods.push(val)
            }
        })
        if(! replaced) methods.push(method) 

        this.payment_methods = methods 
        return this 
    }

}

// Payment methods 
// https://paste.ubuntu.com/p/qNYTCx7xnr/


export class PaymentMethod { 
    constructor(json) { 

        /** @type {number} */
        this.id                 = json.id 
        /** @type {string} */
        this.uri                = json.uri

        /** @type {string} */
        this.name               = json.name

        /** @type {boolean} */
        this.default            = json.default

        /** @type {string} */
        this.card_type          = json.card_type
        /** @type {string} */
        this.masked_card        = json.masked_card
        /** @type {string} */
        this.expiration_month   = json.expiration_month
        /** @type {string} */
        this.expiration_year    = json.expiration_year
        
        /** @type {boolean} */
        this.available = json.status == 0 && json.chargeable


        /** @type {string} */
        this.name_on_card       = null
        if(json.name_on_card ) { 
            this.name_on_card = json.name_on_card
        }
        else if (json.first_name && json.last_name) { 
            this.name_on_card = `${json.first_name} ${json.last_name}`
        }


        /** @type {{ setDefault: string }} */
        this._links = json._links
    }
}

export class BankPaymentMethod {
    constructor(json) {
        this.id = json.id

        this.last_four = json.last_four

        /** @type {string} */
        this.uri = json.uri

        /** @type {boolean} */
        this.default = json.default

        /** @type {{ setDefault: string }} */
        this._links = json._links
    }
}