import React from "react";

import { MenuSection } from "../data/Menu"

/**
 * Button to take the user to a separate section page
 * @param {object} props 
 * @param {MenuSection} props.section
 * @param {function():void} props.navigate Invoked on click; should navigate the user to the menu section 
 */
export default function(props) { 
    return <div className="menuSectionButton" onClick={ props.navigate }>
        <div className="card-flex-wrapper">
        <div className="text-wrapper">
            <h1> {props.section.name} </h1>
            <p> {props.section.description} </p>
        </div>
        <div className="arrow-icon">
            <Icon name="arrow_forward_ios" />
        </div>
        </div>
    </div>
}