import Big from "big.js"

import Location from "lib/lib-smb-menus/data/Location"


export default class { 

    /**
     * Builds an `OrderReceipt` from an API result 
     * @param {object} json 
     */
    constructor(json) { 
        /**
         * Global order identifier (from SMB service for SMB orders)
         * @type {string}
         */
        this.order_id = json.id 

        /**
         * Human-friendly Order Number; specific to the location to which the order was submitted
         * @type {number}
         */
        this.order_number = json.loc_order_num 


        /**
         * The Location data provided by the API along with the Order object
         * 
         * @type {Location}
         */
        this.location = new Location(json.location, json.menu)

        /**
         * Time the order was submitted
         * @type {Date}
         */
        this.created_at     = new Date(json.created_at  * 1000)

        /**
         * Estimated time by which the order should be completed; based on location config
         * @type {Date} 
         */
        this.expected_by    = new Date(json.expected_by * 1000)

        /**
         * Time of last update; which is the time completed if `status` reflects that
         */
        this.updated_at     = new Date(json.updated_at  * 1000)

        /**
         * Status of the order (from the SMB service)
         * @type {"PendingAcceptance"|"Accepted"|"Claimed"|"Completed"|"Rejected"}
         */
        this.status = json.status

        /** 
         * The total of all item prices before any promotions, taxes, tipping, or fees
         * @type {Big}
         */
        this.subtotal   = new Big(json.subtotal)
        /**
         * The tip applied by the user, or `null` if tipping was not available at the time the order was placed
         * @type {?Big} 
         */
        this.tip        = json.tip ? new Big(json.tip) : null 
        /**
         * The tax withheld (For SMBs, based on the menu configuration)
         * @type {Big}
         */
        this.tax        = new Big(json.tax)
        /**
         * The sum of all fees charged by our platform (represented differently in the back end)
         * @type {Big}
         */
        this.fees       = new Big(json.fees)
        /**
         * The grand total of the order; equal to the amount tendered by the user to place the order.
         * @type {Big} 
         */
        this.total      = new Big(json.total)


        /** @type {string} */
        this.pickup_name = json.pickup_name
        /** @type {string} */
        this.pickup_phone = json.pickup_phone


        /**
         * The fulfillment method chosen by the user, as a polymorphic object.
         * See the `Order.Method.*` factory methods for a complete reference of the types of orders that may be submitted.
         * @type {{ $: string }}
         */
        this.method = json.method

        /** @type {[OrderReceiptItem]} */
        this.items = json.items.map(x => new OrderReceiptItem(x))
    }

}

export class OrderReceiptItem { 
    constructor(json) { 
        /** @type {string} */
        this.name                   = json.name 
        /** @type {?Big} */
        this.price                  = new Big(json.price) 
        /** @type {number} */
        this.quantity               = json.quantity 
        /** @type {string} */
        this.custom_instructions    = json.custom_instructions 
        /** @type {[Mod]} */
        this.mods                   = json.mods.map(x => new Mod(x))
    }
}

export class Mod { 
    constructor(json) { 
        /** @type {string} */
        this.name       = json.name
        /** @type {[ModItem]} */
        this.selections = json.selections.map(x => new ModItem(x))
    }
}

export class ModItem { 
    constructor(json) { 
        /** @type {string} */
        this.name       = json.name
        /** @type {Big} */
        this.price      = new Big(json.price)
        /** @type {number} */
        this.quantity   = json.quantity
        /** @type {[Mod]} */
        this.mods       = json.mods.map(x => new Mod(x))
    }
}
