import React from 'react'; 
import { firebaseEvent } from 'ulink_global';

export default class MenuBar extends React.Component { 

    hamburgerToggle = () => {
        if(! this.props.hamburgerOpen) firebaseEvent('hamburger_open')
        this.props.hamburgerToggle();
    }

    render () { 
        return (
            <nav id="ULinkNav" ref={this.props.navRef}>

            <div className="front-wrapper">
                <div style={{float: 'right'}}>
                    <i id="HamburgerMenuToggle" role="button" className="material-icons" onClick={this.props.hamburgerToggle} >
                        { this.props.hamburgerOpen ? 'person_outline' : 'person' }
                    </i>
                </div>
            </div>

            <div className="img-wrapper">
                    <img src={ '/assets/img/ulink-logotype-black.png' } />
                </div>
            </nav>
        );
    }

}