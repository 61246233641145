import React, { useState, useEffect, useRef, createRef } from 'react'; 
import { PhoneNumber, PHONE_NUMBER } from 'views/widgets/FormatInput'; 
// import ULINK_API from '../../ULINK_API';
import { ULINK_UI, Banner, firebaseEvent } from 'ulink_global';
import ULink from 'services/ULink';
import User from 'data/User';

export function EnterPhone(props) { 

    useEffect(() => firebaseEvent('phone_modal') , []);

    // Dev bypass, for skipping SMS auth
    // The endpoint itself is diabled in the back end for non production environments regardless
    const allowBypass = !(['production', 'remote_qa'].includes(GLOBALS.ENV))
    let [ devBypass, setDevBypass ] = useState(true) 

    let onContinue = () => {
        if(! props.phone.valid) return;

        window.localStorage.setItem('user_phone', props.phone.value)

        ULINK_UI.ajax.before(); 
        if( devBypass && allowBypass ) { 

            ULink.user.devLogIn( props.phone.value )
                .then(user => props.setUser(user))
                .catch(ULINK_UI.ajax.catch) 
                .finally(ULINK_UI.ajax.finally)

        } else { 

            ULink.user.loginSendChallenge(props.phone.value)
            .then(challenge => { 
                ULINK_UI.ajax.finally()
                props.setChallenge(challenge)
            })
            .catch(status => { 
                if(status == 422) alert("Invalid Number! Please verify your phone number and enter it again.");
                ULINK_UI.ajax.finally()
            })
            .finally(ULINK_UI.ajax.finally); 

        }

    }; 


    let msgInit = <p>
        We require a phone number to contact you about your order.
        Please provide your mobile number to verify your account.
    </p>

    let msgExpired = <p>
        For your security, the session has expired.
        Please verify your phone number again to continue. 
    </p>


    return <div id="PhoneEnter" className="plain">

        { props.wasExpired ? msgExpired : msgInit }

        <PhoneNumber value={ props.phone.value } callback={ (value, valid) => { props.setPhone({ value, valid }) }} 
            onEnter={ onContinue } />

        <p>We will send a 5 digit code to this number.</p>

        <button className="positive" aria-disabled={ !(props.phone.valid) }  onClick={ onContinue } >Continue</button>

        { allowBypass && <>
            <label>
                <input type="checkbox" checked={ devBypass } onChange={ e => setDevBypass(e.target.checked) } />
                [DEV] Bypass SMS auth
            </label>
        </> }
        {/* <button className="plain" onClick={ () => props.kernel.setUser(User.test()) } >[DEV] Bypass</button> */}
    </div>
}

const RESEND_TIME = 30 * 1000; 

const CODE_DIGITS = 5 
const newCodeVal = () => Array(CODE_DIGITS).fill('')

/** @typedef {{ uri: string, expires: Date }} Challenge */

/**
 * @param {object} props 
 * @param {Challenge} props.challenge 
 * @param {React.Dispatch<React.SetStateAction<Challenge>>} props.setChallenge
 * @param {string} props.phone
 * @param {function} props.onBack 
 * @param {React.Dispatch<React.SetStateAction<User>>} props.setUser 
 * @returns 
 */
export function VerifyPhone(props) { 

    useEffect(() => firebaseEvent('code_verify_modal'), []) 

    let [ code, setCode ]                   = useState( newCodeVal() ); // input value
    let [ locked, setLocked ]               = useState(false); 

    let resendRef = useRef() 

    const [ , setAsdf ] = useState(false) 
    let forceUpdate = () => setAsdf(x => !x)

    /** @param {Challenge} challenge */
    const challengeTTL = challenge => Math.floor(challenge.expires - new Date() / 1000)

    const [ ttlSeconds, setTtlSeconds ] = useState(challengeTTL(props.challenge)) 
    useEffect(() => { 
        let x = setInterval( () => { if (ttlSeconds > 0) setTtlSeconds(s => s - 1) } , 1000 )
        return () => clearInterval(x) 
    })

    if(ttlSeconds <= 0 && code.findIndex((x) => x != '') != -1) { 
        setCode(newCodeVal())       // Clear the code
        resendRef.current.focus()   // Focus the resend button 
    }


    let onResend = () => { 
        //TODO: Reimplement re-send cooldown

        if(locked) return; 
        setLocked(true) 

        ULINK_UI.ajax.before(); 
        ULink.user.loginSendChallenge( props.phone.value ).then((challenge) => { 
            props.setChallenge(challenge) 
            setTtlSeconds( challengeTTL(challenge) )
            alert("A new code has been sent! Please check your text messages.")
        })
        .catch(ULINK_UI.ajax.catch) 
        .finally(() => { 
            ULINK_UI.ajax.finally()
            setLocked(false) 
        }); 
    }


    let onVerify = (code) => { 
        code = code.join('')
        if(code == '') return; 
        if(new Date() >= props.challenge.expires) { 
            console.warn("EXPIRED")
            return 
        }

        if(locked) return; 
        setLocked(true) 

        ULINK_UI.ajax.before(); 
        ULink.user.loginAnswerChallenge(props.challenge.uri, code)
        .then( ({ user, isNew }) => { 

            setLocked(false) // can't do in .finally bc component unmount
            ULINK_UI.ajax.finally() 

            props.setUser(user) 
            firebaseEvent('login')

            var banner = { style: 'positive' }
            if(isNew) { 
                banner.title = "Welcome!"
                banner.message = "You've successfully created an account."
            } else { 
                banner.title = "Welcome back!"
                banner.message = "You've successfully logged in."
            }
            Banner(banner) 

        })
        .catch(code => { 
            setLocked(false) // can't do in .finally bc component unmount
            ULINK_UI.ajax.finally()

            if(code == 410) { 
                alert("We're sorry, the code has expired - please try again") 
                props.onBack()
            }
            else if(code == 401) { 
                firebaseEvent('incorrect_code_verify')
                alert("Incorrect code!  Please verify and try again.")
            } else { 
                ULINK_UI.ajax.catch(code)
            }
        })

    }

    let onWrongNumber = () => { 
        firebaseEvent('verify_wrong_number')
        props.onBack();
    }


    const isExpired = ttlSeconds <= 0

    return <div id="PhoneSent" className="plain">
        <p>Please enter the 5 digit code we sent to verify your account.  Do not share this code.</p>
        <p>
            Code was sent to <span id="sentToNumber">{ PHONE_NUMBER.toDisplay(props.phone.value) }</span> 
            <button id="pvWrongNumber" className="plain" onClick={ onWrongNumber }>(Wrong Number?)</button>
        </p>

        { isExpired && <p className="error">This code has expired. Please tap “Resend Code” below to receive a new code.</p> }
        {/* { timing.resendCountdown > 0 && <p className="warning">Please allow { timing.resendCountdown } more seconds before attempting to re-send the code.</p> } */}

        {/* <input type="text" inputMode="numeric"
            value={ code } onChange={ (e) => setCode(e.target.value) } 
            aria-disabled={ isExpired } /> */}

        <CodeInput value={ code } disabled={ isExpired }
            onChange={ (val) => { setCode(val); } } onVerify={ onVerify } forceUpdate={ forceUpdate } /> 

        <button id="pvResend" className="plain negative align-p" onClick={ onResend } ref={ resendRef } >Resend code</button>
    </div>
}




function CodeInput(props) {

    var value = props.value
    const updateVal = () => { 
        props.onChange(value) 
        props.forceUpdate() // gotta force updates or else it will wait for the next timer tick for some reason 
    }
    const isEmpty = (value.findIndex((x) => x != '') === -1)  

    const doneRef = useRef()

    // Smart solution here for using refs in arrays 
    // https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks
    const [ digitRefs, setDigitRefs ] = useState([]) 
    useEffect(() => { 
        var arr = []
        for(var i = 0; i < CODE_DIGITS; i++) arr.push( createRef() )
        setDigitRefs(arr) 
    }, [])

    useEffect(() => { 
        // Focus on the first digit upon load 
        // That second useEffect parameter is brilliant here 
        let ref = digitRefs[0]
        if(ref && ref.current) ref.current.focus() 
    }, [ digitRefs ]) 

    let focusOn = (i) => { 
        if(i < 0) throw 'Negative index'
        if(i == CODE_DIGITS)        doneRef.current.focus() 
        else if(i < CODE_DIGITS) { 
            let el = digitRefs[i].current
            el.focus()
            el.selectionStart   = 0 
            el.selectionEnd     = 0 
        }
        else throw `Index #${i} out of bounds` 
        // Overflow shouldn't be an issue - this event only happens with real indexes, so i+1 will never throw
        // The biggest one is i+2, but never on the last index, so it should be good too 
    }

    let moveFocusIfEmpty = () => { 
        if(isEmpty) digitRefs[0].current.focus() 
    }



    // Move focus back to the first digit, when it makes sense
    useEffect(() => { 
         // One of the digits except the first is focused
        if (digitRefs.findIndex((x) => x.current === document.activeElement) > 0) moveFocusIfEmpty() 
    }, [value])



    // This has to be after all hooks or else React will detect a "change in order" (last one didn't get invoked)
    if(digitRefs.length < CODE_DIGITS) return null; 


    let onKeyDown = (e) => { 
        if(props.disabled) return; 

        let i = parseInt(e.target.getAttribute('data-index'))
        let isFirst = i == 0 
        let isLast  = i == (CODE_DIGITS - 1)
        
        if(e.ctrlKey || e.metaKey || e.altKey) return;
        e.preventDefault(); 
        e.stopPropagation(); 
        //TODO: Last digit should focus done button - backspace should also unfocus it and move back to the last digit 


        const input = e.target; 
        const selstart = input.selectionStart, selend = input.selectionEnd
        const isBackspace   = e.key == 'Backspace'
        const isDelete      = e.key == 'Delete'
        const isNumeric     = /[0-9]/.test(e.key)
        const isSelected    = selstart  != selend 
        const isAfter       = selstart  == 1 
        const isBefore      = selend    == 0 

        // console.log(selstart, selend)
        // console.log(`${isBackspace ? 'BSP' : ''}${isDelete ? 'DEL' : ''}${isNumeric ? 'NUM' : ''} ${isBefore ? 'B4' : ''}${isAfter ? 'AF' : ''}  ${isSelected ? 'SEL' : ''} - ${e.key}`)
        

        if(e.key == 'ArrowLeft' && !isFirst)    { focusOn(i - 1); return; }
        if(e.key == 'ArrowRight')               { focusOn(i + 1); return; }

        if(isNumeric) { 

            if (isBefore || isSelected) { 
                value[i] = e.key 
                focusOn(i + 1) 
                updateVal() 
            }
            
            else if (isAfter && !isLast) { 
                value[i + 1] = e.key 
                focusOn(i + 2) 
                updateVal() 
            }
        }

        else if (
            (isSelected && (isBackspace || isDelete))
         || (isAfter    && isBackspace)
         || (isBefore   && isDelete )
        ) { 
            value[i] = '' 
            updateVal() 
        }

        else if(isBackspace && !isFirst) { 
            value[i - 1] = '' 
            focusOn(i - 1)
            updateVal() 
        }
        else if(isDelete && !isLast)  {
            value[i] = '' 
            updateVal() 
        }

    }

    let onChange = (e) => { 
        // From FormatInput 
        // Backup handler to do OS-specific actions, like alt-backspace or paste or whatever 
        if(props.disabled) return; 

        let newval = e.target.value; 
        let i = parseInt(e.target.getAttribute('data-index')) 

        value = value.slice(0, i).concat( newval.split('') )
        var foc = value.length
        if(value.length < CODE_DIGITS) value = value.concat( Array(CODE_DIGITS - value.length).fill('') )
        if(value.length > CODE_DIGITS) value = value.slice(0, CODE_DIGITS)

        focusOn(foc <= CODE_DIGITS ? foc : CODE_DIGITS)
        updateVal() 
    }

    


    let onDoneButtonKeyDown = (e) => { 
        if(e.key == 'ArrowLeft' ) { 
            digitRefs[CODE_DIGITS - 1].current.focus() 
        }
        else if(e.key == 'Backspace') { 
            digitRefs[CODE_DIGITS - 1].current.focus() 
            value[CODE_DIGITS - 1] = '' 
            updateVal()
        }
    }




    let digitBoxes = [] 
    for(var i = 0; i < CODE_DIGITS; i++) { 
        digitBoxes.push(
            <input key={ i } 
                type="text" inputMode="numeric"  aria-disabled={ props.disabled }
                onKeyDown={ onKeyDown } onChange={ onChange } onClick={ moveFocusIfEmpty }
                data-index={i} value={ value[i] } ref={ digitRefs[i] } 
            />
        ); 
    }


    return <>
        <div id="CodeInput">{ digitBoxes }</div>
        <button id="pvVerify" className="positive" ref={ doneRef } aria-disabled={ props.disabled }
             onClick={ () => props.onVerify(value) } onKeyDown={ onDoneButtonKeyDown }
        >Verify Account</button>
    </>
}