import React, { useState, useEffect, useRef, createRef } from 'react';
import { useNavigate } from 'react-router-dom';

//Google analytics
import ReactGA from "react-ga4";

export default function CodeInput(props) { 
    const navigator = useNavigate();

    var CODE_DIGITS = props.codedigits;
    var value = props.value;
    const updateVal = () => { 
        props.onChange(value) 
        props.forceUpdate() // gotta force updates or else it will wait for the next timer tick for some reason 
    }
    const isEmpty = (value.findIndex((x) => x != '') === -1)  

    const doneRef = useRef();


    //Google analytics
    // idk it doesn't work i think
    useEffect(() => {
        ReactGA.initialize("G-KBSHY80ZK9");
    }, []);



    // Smart solution here for using refs in arrays 
    // https://stackoverflow.com/questions/54633690/how-can-i-use-multiple-refs-for-an-array-of-elements-with-hooks
    const [ digitRefs, setDigitRefs ] = useState([]) 
    useEffect(() => { 
        var arr = []
        for(var i = 0; i < CODE_DIGITS; i++) arr.push( createRef() )
        setDigitRefs(arr) 
    }, [])

    useEffect(() => { 
        // Focus on the first digit upon load 
        // That second useEffect parameter is brilliant here 
        let ref = digitRefs[0]
        if(ref && ref.current) ref.current.focus() 
    }, [ digitRefs ]) 

    let focusOn = (i) => { 
        if(i < 0) throw 'Negative index'
        if(i == CODE_DIGITS) doneRef.current.focus() 
        else if(i < CODE_DIGITS) { 
            let el = digitRefs[i].current
            el.focus()
            el.selectionStart   = 0 
            el.selectionEnd     = 0 
        }
        else throw `Index #${i} out of bounds` 
        // Overflow shouldn't be an issue - this event only happens with real indexes, so i+1 will never throw
        // The biggest one is i+2, but never on the last index, so it should be good too 
    }

    let moveFocusIfEmpty = () => { 
        if(isEmpty) digitRefs[0].current.focus() 
    }



    // Move focus back to the first digit, when it makes sense
    useEffect(() => { 
         // One of the digits except the first is focused
        if (digitRefs.findIndex((x) => x.current === document.activeElement) > 0) moveFocusIfEmpty() 
    }, [value])



    // This has to be after all hooks or else React will detect a "change in order" (last one didn't get invoked)
    if(digitRefs.length < CODE_DIGITS) return null; 


    let onKeyDown = (e) => { 
        if(props.disabled) return; 

        let i = parseInt(e.target.getAttribute('data-index'))
        let isFirst = i == 0 
        let isLast  = i == (CODE_DIGITS - 1)
        
        if(e.ctrlKey || e.metaKey || e.altKey) return;
        e.preventDefault(); 
        e.stopPropagation(); 
        //TODO: Last digit should focus done button - backspace should also unfocus it and move back to the last digit 


        const input = e.target; 
        const selstart = input.selectionStart, selend = input.selectionEnd
        const isBackspace   = e.key == 'Backspace'
        const isDelete      = e.key == 'Delete'
        const isNumeric     = /[0-9]/.test(e.key)
        const isSelected    = selstart  != selend 
        const isAfter       = selstart  == 1 
        const isBefore      = selend    == 0 

        // console.log(selstart, selend)
        // console.log(`${isBackspace ? 'BSP' : ''}${isDelete ? 'DEL' : ''}${isNumeric ? 'NUM' : ''} ${isBefore ? 'B4' : ''}${isAfter ? 'AF' : ''}  ${isSelected ? 'SEL' : ''} - ${e.key}`)
        

        if(e.key == 'ArrowLeft' && !isFirst)    { focusOn(i - 1); return; }
        if(e.key == 'ArrowRight')               { focusOn(i + 1); return; }

        if(isNumeric) { 

            if (isBefore || isSelected) { 
                value[i] = e.key 
                focusOn(i + 1) 
                updateVal() 
            }
            
            else if (isAfter && !isLast) { 
                value[i + 1] = e.key 
                focusOn(i + 2) 
                updateVal() 
            }
        }

        else if (
            (isSelected && (isBackspace || isDelete))
         || (isAfter    && isBackspace)
         || (isBefore   && isDelete )
        ) { 
            value[i] = '' 
            updateVal() 
        }

        else if(isBackspace && !isFirst) { 
            value[i - 1] = '' 
            focusOn(i - 1)
            updateVal() 
        }
        else if(isDelete && !isLast)  {
            value[i] = '' 
            updateVal() 
        }

    }

    let onChange = (e) => { 
        // From FormatInput 
        // Backup handler to do OS-specific actions, like alt-backspace or paste or whatever 
        if(props.disabled) return; 

        let newval = e.target.value; 
        let i = parseInt(e.target.getAttribute('data-index')) 

        value = value.slice(0, i).concat( newval.split('') )
        var foc = value.length
        if(value.length < CODE_DIGITS) value = value.concat( Array(CODE_DIGITS - value.length).fill('') )
        if(value.length > CODE_DIGITS) value = value.slice(0, CODE_DIGITS)

        focusOn(foc <= CODE_DIGITS ? foc : CODE_DIGITS)
        updateVal() 
    }

    


    let onDoneButtonKeyDown = (e) => { 
        if(e.key == 'ArrowLeft' ) { 
            digitRefs[CODE_DIGITS - 1].current.focus() 
        }
        else if(e.key == 'Backspace') { 
            digitRefs[CODE_DIGITS - 1].current.focus() 
            value[CODE_DIGITS - 1] = '' 
            updateVal()
        }
    }





    let digitBoxes = [] 
    for(var i = 0; i < CODE_DIGITS; i++) { 
        digitBoxes.push(
            <input key={ i } 
                type="text" inputMode="numeric"  aria-disabled={ props.disabled }
                onKeyDown={ onKeyDown } onChange={ onChange } onClick={ moveFocusIfEmpty }
                data-index={i} value={ value[i] } ref={ digitRefs[i] } 
            />
        ); 
    }


    return <>
        <div id="CodeInput">{ digitBoxes }</div>
        <div className="buttons-wrapper">
            <button id="BackBtn" ref={ doneRef }
                onClick={ () => navigator(-1)}
            >BACK</button>

            <button id="pvVerify" className="positive" ref={ doneRef } aria-disabled={ props.disabled }
                onClick={ () => {
                    props.onVerify(value)
                    ReactGA.send("ThreeDigitCode-ContinueBtn");
                }}
                onKeyDown={ onDoneButtonKeyDown }
            >CONTINUE</button>
        </div>
    </>
}