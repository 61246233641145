import React, { useState, useEffect } from 'react'; 
import { Link, useNavigate, useParams } from 'react-router-dom';

import { firebaseEvent, SupportLink, ULINK_UI } from 'ulink_global';

import { ulid } from 'ulid';

import Menu, { MenuItem } from 'lib/lib-smb-menus/data/Menu';
import Order, { OrderItem, OrderItemModState } from 'lib/lib-smb-menus/data/Order';
import ItemDisplay from 'lib/lib-smb-menus/display/ItemDisplay';

import Icon from 'lib/lib-sionic/Icon';


/**
 * 
 * @param {object} props 
 * @param {function} props.scrollToTop
 * 
 * @param {function(Order)} props.setOrder
 * 
 * location_props
 * @param {Location} props.location 
 * @param {Menu} props.menu
 * @param {Order} props.order
 * @param {function} props.setNotFound 
 * @param {function} props.requireInitFlow
 * @param {function} props.fetchLocation 
 */
export default function(props) { 
    let { location_id, menu_item_id, order_item_key } = useParams() 
    const navigate = useNavigate()

    useEffect(() => { 
        props.scrollToTop()
        props.requireInitFlow() 
    }, [])

    const isEditing = !! order_item_key 

    /** @type {[MenuItem, React.Dispatch<MenuItem>]} */
    let [ item, setItem ] = useState(null)

    // Set to true when the order & menu have loaded but the item was still not found}
    let [ orderItemNotFound, setOrderItemNotFound ] = useState(false) 


    useEffect(() => { 
        if(item) { 
            if(isEditing)   firebaseEvent('edit_item', { items: item.name })
            else            firebaseEvent('view_item', { items: item.name })
        }
    }, [item])


    //
    // Editor state
    //

    let [ instructions, setInstructions ] = useState('') 
    let [ quantity, setQuantity ] = useState(1) 

    /** @type {[ OrderItemModState, function ]}  */
    let [ modState, setModState ] = useState( null )

    let [ showErrors, setShowErrors ] = useState(false)

    // Load item data
    // If we're editing an item, we may first have to load order info in order to fetch location
    //  and we then use what we find to fetch the rest of the relevant menu item data 
    // Otherwise, we already have the menu item ID, and we just use the default editor state 
    useEffect(() => { 

        if(isEditing) { 
            if(! props.order) return // wait until order is loaded by `app.jsx`

            if(props.menu) { 
                
                let order_item = props.order.getOrderItem(order_item_key)
                if(order_item) { 
                    let menu_item = props.menu.getMenuItem(order_item.menu_item_id) 
                    if(! menu_item) { 
                        console.warn("Could not find menu item from loaded menu", order_item.menu_item_id) 
                        setOrderItemNotFound(true)
                        return 
                    }
                    setItem(menu_item) 

                    setQuantity(order_item.quantity)
                    setInstructions(order_item.custom_instructions)
                    setModState(new OrderItemModState(order_item.mods))
                } else {
                    console.error("Could not find order item", order_item_key) 
                    setOrderItemNotFound(true)
                }

            } else { 
                props.fetchLocation(props.order.location_id) 
            }


        } else { 

            if(props.item) return 
            props.fetchLocation(location_id) 

            if(! props.menu) return 
            let menu_item = props.menu.getMenuItem(menu_item_id) 
            if(menu_item) { 
                setItem(menu_item)
                setModState( OrderItemModState.ForMenuItem(props.menu, menu_item_id) )
            } else { 
                console.info("Could not find menu item", menu_item_id)
                props.setNotFound(true) 
            }

        }

    }, [props.menu, props.order])

    //
    // Waiting screens
    // 

    if(isEditing && orderItemNotFound) return <main id="NoOrderItem" className="basic">
        <h1 className="PageTitle">Uh-oh!</h1>
        <p>
            This page is for editing an item in your order, but we can't find the item we're looking for.
            Perhaps this order was already submitted, or it no longer exists?
        </p>
        <p>
            If you believe this is an error, please feel free to contact us at {SupportLink}, and we apologize for the inconvenience.
        </p>
    </main>

    if(!(props.location && props.menu && modState && item )) return null 

    //
    // Derived
    //


    const available = props.menu.isMenuItemAvailableNow(item.id) 

    const getResult = () => new OrderItem(order_item_key || ulid(), item.id, quantity, instructions, modState.unwrap())
    //                                                  👆
    // This bit implicitly generates a new key if we're not editing
    // fancy but not super readable or robust I guess 


    //
    // Utilities
    //

    const addToCart = () => { 
        if(! available) return

        if(! modState.isValid(props.menu)) { 
            setShowErrors(true) 
            return 
        }

        if(isEditing) { 
            props.setOrder( props.order.copy().withReplaceItem( getResult() ) )
            navigate('/checkout')
        } else { 
            firebaseEvent('add_to_cart', { items: item.name })
            props.setOrder( props.order.copy().withAddItem( getResult() ) )
            navigate(`/locations/${location_id}/menu`)
        }

    }


    //
    // Main render
    //


    var BackButton;

    if(isEditing) BackButton = <Link to={ `/checkout` } id="Back">
        <Icon name="arrow_back" /> Back to cart
    </Link>;

    else BackButton = <a id="Back" onClick={ () => navigate(-1)  } >
        <Icon name="arrow_back" />Go back 
    </a>;


    return <div id="MenuItemView" className="RootComponent">

        {/*  
            Disabling because of the whole "icons" deal - default icons look super ugly 
            Next we should have a way to tell what's a default icon and show or hide accordingly 

            (👆 that comment was from v1, not sure specifically what it was about)
        */}
        <header>
            { item.image_url && <img src={ item.image_url } /> }
        </header>

        <main>
            { BackButton } 

            <ItemDisplay item={ item } menu={ props.menu}
                modState={ modState } setModState={ setModState } 
                instructions={ instructions } setInstructions={ setInstructions }
                showErrors={showErrors}
            />


            <button id="Continue" className="positive" onClick={ addToCart } aria-disabled={ ! available } >
                { 
                    isEditing 
                        ? <><Icon name="check_circle_outline" />Save and Continue</>
                        : <><Icon name="add_shopping_cart" /> Add to Cart - { ULINK_UI.Price_e(  props.menu.calcOrderItemSubtotal(getResult())  ) }</>
                }
            </button>
        </main>
    </div>
}