import React, { useState, useRef, useEffect }  from 'react';

import { CreditCard, CREDIT_CARD } from 'views/widgets/FormatInput';
import PaymentMethodSelector from './PaymentMethod'; 
import User, { PaymentMethod } from 'data/User';

import Icon from 'lib/lib-sionic/Icon';
import { IncentiveRailType } from 'lib/lib-sionic/data/Incentive';

//MX Connect Widget
import MXConnect from 'views/widgets/MXConnect';
import CloudFunctionsURL from '../pay-in-store/helpers/CloudFunctionsURL';
import Location from 'lib/lib-smb-menus/data/Location';

// Environment
import isProd from '../pay-in-store/helpers/isProd';

export type NewPaymentType   = "NEW_CARD"|"NEW_BANK"
export type SavedPaymentType = "SAVED_CARD"|"SAVED_BANK"
export type PaymentType      = NewPaymentType|SavedPaymentType

export interface PaymentInterface { $: PaymentType }
export type PaymentObject = any & PaymentInterface

type CardState               = { value: any, valid: boolean }
type BankState               = any
type SavedMethodID           = number



/**
 * Gets the effective object value (that can be used in API calls) from the state values used in `PaymentControl`. 
 */
export function getPaymentAPIValue(type: PaymentType, new_bank: BankState, new_card: CardState, saved_id: SavedMethodID) : PaymentObject
{ 
    switch(type) { 
        case "SAVED_BANK":
        case "SAVED_CARD":
            return { $: type, id: saved_id }
        case "NEW_CARD":
            if(new_card.valid) return Object.assign({ $: "NEW_CARD" }, new_card.value)
            else return null 
        case "NEW_BANK":
            return Object.assign({ $: "NEW_BANK" }, new_bank)
    }
}

/**
 * Returns the payment rail type (card or bank) for the `PaymentType` provided;
 * for use in determining if a perk applies
 */
export function getPaymentRailType(type: PaymentType): IncentiveRailType { 
    switch(type) { 
        case "NEW_CARD":
        case "SAVED_CARD":
            return "card"
        case "SAVED_BANK":
        case "NEW_BANK":
            return "bank"
        
        default:
            throw "Unrecognized PaymentType in getPaymentRailType"
    }
}

export function setDefaultPayState(location: Location, user: User, setPaymentType: React.Dispatch<PaymentType>, setPaymentSavedID: React.Dispatch<any>) { 

    const canPayByBank = location.payment_types_available.new_bank
    const canPayByCard = location.payment_types_available.new_card

    const defaultCard = user.getDefaultPaymentCard();
    const defaultBank = user.getDefaultPaymentBank();

    if(defaultBank && canPayByBank) { 
        setPaymentSavedID(defaultBank.id)
        setPaymentType("SAVED_BANK")
        return 
    }
    else if(defaultCard && canPayByCard) { 
        setPaymentSavedID(defaultCard.id)
        setPaymentType("SAVED_CARD")
        return
    } 
    else if(canPayByBank) { 
        setPaymentType("NEW_BANK")
    } 
    else if(canPayByCard) { 
        setPaymentType("NEW_CARD")
    } else { 
        console.error("Could not determine a valid initial payment state")
        throw "E"
    }
}


export default function(props: { 
    user: User, 
    setUser: React.Dispatch<React.SetStateAction<User>>,
    hamburgerToggle: () => void, 

    location: Location,

    doValidate: boolean, 

    paymentType: PaymentType,
    setPaymentType: React.Dispatch<React.SetStateAction<PaymentType>>,
    cardState: CardState,
    setCardState: React.Dispatch<React.SetStateAction<CardState>>,
    bankState: BankState,
    setBankState: React.Dispatch<React.SetStateAction<BankState>>,
    
    paymentMethodID: any, 
    setPaymentMethodID: React.Dispatch<React.SetStateAction<any>>,
}) { 

    if(! props.location) return null 
    const canPayByBank = props.location.payment_types_available.new_bank
    const canPayByCard = props.location.payment_types_available.new_card

    const PAY_METHOD_LIMIT = 5; 


    /** @param {NewPaymentType} type */
    const onNewPayment = (type: NewPaymentType) => {
        if((props.user.pm_cards.length + props.user.pm_banks.length) >= PAY_METHOD_LIMIT) { 
            alert(`You've reached the limit of ${PAY_METHOD_LIMIT} payment methods. Please delete a payment method from the menu before adding another.`)
            props.hamburgerToggle()
            return 
        }

        props.setPaymentType(type) 
    }






    //
    // Render
    //

    const $SavedBank = canPayByBank && props.user.pm_banks.length > 0 && <ul>
        {
        // @ts-ignore - missing properties - PaymentMethod has not been migrated to typescript  
        props.user.pm_banks.map(x => <li key={x.id} ><PaymentMethodSelector method={x}
            checked={ props.paymentMethodID === x.id && props.paymentType === "SAVED_BANK" } onSelect={ () => {
                props.setPaymentMethodID(x.id);
                props.setPaymentType("SAVED_BANK");
            } }
        /></li>
        )}
    </ul>

    const $SavedCard = canPayByCard && props.user.pm_cards.length > 0 && <ul>
        { 
        // @ts-ignore - missing properties - PaymentMethod has not been migrated to typescript  
        props.user.pm_cards.map(x => <li key={x.id} ><PaymentMethodSelector method={x}
            checked={ props.paymentMethodID === x.id && props.paymentType === "SAVED_CARD" } onSelect={ () => {
                props.setPaymentMethodID(x.id);
                props.setPaymentType("SAVED_CARD");
            } }
        /></li>
        )}
    </ul>



    var $NewBank = null 
    if(canPayByBank) { 
        if(props.paymentType == "NEW_BANK") $NewBank = <NewPaymentWrapper><NewPaymentBank user={props.user} setPaymentType={props.setPaymentType} doValidate={props.doValidate} state={props.bankState} setState={props.setBankState} /></NewPaymentWrapper>
        // @ts-ignore - missing properties - Icon not yet migrated to typescript
        else $NewBank = <div className='NewPaymentButton' onClick={ () => onNewPayment("NEW_BANK") }><Icon name="radio_button_unchecked" />Add New Pay-By-Bank Account</div>
    }

    var $NewCard = null 
    if(canPayByCard) { 
        if(props.paymentType == "NEW_CARD") $NewCard = <NewPaymentWrapper><NewPaymentCard doValidate={props.doValidate} state={props.cardState} setState={props.setCardState} /></NewPaymentWrapper>
        // @ts-ignore - missing properties - Icon not yet migrated to typescript
        else $NewCard = <div className='NewPaymentButton' onClick={ () => onNewPayment("NEW_CARD") }><Icon name="radio_button_unchecked" />Add New Payment Card</div>
    }


    return <div 
            id="PaymentControl">
        { $SavedBank }
        { $SavedCard }
        { $NewBank }
        { $NewCard }
    </div>
}



// State initializers

//## Credit Card ##
const blankCreditCardState = () => ({ valid: false, value: {
    name_on_card:       '',
    card_number:        '',
    cvv:                '',
    expiration_month:   '',
    expiration_year:    '',
    zipcode:            ''
} }); 

const devCreditCardState = () => ({ valid: true, value: {
    name_on_card:       'Jacob C Conley',
    card_number:        '4321000000001119',
    cvv:                '333',
    expiration_month:   '12',
    expiration_year:    '23',
    zipcode:            '89000'
}});

// ## Bank Account (Pay-by-Bank) ##
const blankBankAccountState = () => ({ valid: false, value: {
    name_first: '',
    name_last:  '',
    account:    '',
    routing:    '',
    zipcode:    '',
}});

const devBankAccountState = () => ({
    name_first: 'Adam',
    name_last:  'Davis',
    account:    '1029012880128',
    routing:    '0012128128121',
    zipcode:    '30338',
});

// @ts-ignore (GLOBALS is generated directly in HTML)
export const newCreditCardState    = () => GLOBALS.ENV == 'development' ? devCreditCardState()  : blankCreditCardState(); 
// @ts-ignore
export const newBankAccountState   = () => GLOBALS.ENV == 'development' ? devBankAccountState() : blankBankAccountState(); 



/**
 * Wrapper for the "new payment method" forms 
 * @param {object} props 
 */
function NewPaymentWrapper(props: any) { 

    return (
    <>
        <div id="CreditCardInput">
            <div id="title">
                {/* <img src={'/assets/img/fa-credit-card.svg'} alt="card" /> */}
                <h1>Payment Method</h1>
            </div>
            { props.children }
        </div>    

        <p>For your convenience, ULink will securely save your payment information for future orders.</p>
    </> 
    )
}




// 
// New Payment Card Input
// 


const ccValidator = { 
    name_on_card:        x => !! x,
    expiration_month:    x => { let val = parseInt(x); return !(isNaN(val)) && val <= 12 } ,
    expiration_year:     x => { let val = parseInt(x); return !(isNaN(val)) && val >= 20 && val <= 99 } ,
    cvv:                 x => /^[0-9]{3,4}$/.test(x) ,
    zipcode:             x => /^[0-9]{5}(-[0-9]{4})?$/.test(x) ,
    card_number:         x => CREDIT_CARD.FromValue(x).inputValid() ,
}; 

const ccValidate = (payment) => { 
    var valid = {}; 
    Object.keys(payment).forEach(key => { valid[key] = ccValidator[key](payment[key]) })
    return valid; 
}

const ccIsValid = (payment) => Object.keys(payment).reduce((prev, cur) => prev && ccValidator[cur](payment[cur]), true); 


/**
 * Form for a new Credit Card
 */
export function NewPaymentCard(props: { 
    doValidate: boolean, 
    state: CardState, 
    setState: React.Dispatch<React.SetStateAction<CardState>>,
}) { 
    var payment         = props.state.value;
    const doValidate    = props.doValidate;
    const valid         = ccValidate(payment); 

    let onInputChange = (e) => { 
        let field = e.target.id; 
        let value = e.target.value; 

        // https://app.clubhouse.io/sionic-mobile/story/2325/space-at-the-end-of-inputs-fails-validation 
        // Cheap fix, really - but only numeric inputs (CVV and ZIP) had this issue
        if(e.target.getAttribute('inputmode') == 'numeric') value = value.trimEnd(); 

        payment[field] = value;
        props.setState({ value: payment, valid: ccIsValid(payment) })
    }

    let CVV         = useRef(null);
    let expMonth    = useRef(null);
    let expYear     = useRef(null);
    let zipCode     = useRef(null);

    useEffect(() => { 
        if(expYear.current && payment.expiration_month.length == 2) expYear.current.focus();
    }, [payment.expiration_month]);

    useEffect(() => { 
        if(zipCode.current && payment.expiration_year.length == 2) zipCode.current.focus();
    }, [payment.expiration_year]);

    useEffect(() => { 
        if(zipCode.current && payment.zipcode.length == 5) zipCode.current.blur();
    }, [payment.zipcode]);

    return (
        <div className="input-flex-wrapper">
            <div id="input-left">
                <div className="input-label-wrapper name-wrap">
                    <label htmlFor="name_on_card">Card Holder</label>
                    <input type="text" id="name_on_card" autoComplete="cc-given-name" placeholder="Full name"
                    aria-invalid={ ( doValidate && !(valid['name_on_card']) ) }  value={ payment['name_on_card'] } 
                    onChange={ onInputChange }/>
                </div>
                <div className="input-label-wrapper">
                    <label htmlFor="card_number">Card Number</label>
                    <CreditCard id="card_number" autoComplete="cc-number" inputMode="numeric" 
                    type="text" placeholder="" value={ payment['card_number'] } 
                    aria-invalid={ ( doValidate && !(valid['card_number']) ) } 
                    callback={ (val, valid, finish) => { 
                        props.setState(old => ({ valid, value: Object.assign(old.value, { card_number: val }) }))
                    } } />
                </div>
                <div className="flex-row-wrapper">
                    <div className="input-label-wrapper">
                        <label htmlFor="expiration_month">Expiration Date</label>
                        <div className="expiration-wrapper">
                            <input ref={expMonth} type="text" id="expiration_month" placeholder="MM" maxLength={2}
                            aria-invalid={ ( doValidate && !(valid['expiration_month']) ) }  value={ payment['expiration_month'] }
                            autoComplete="cc-exp-month" inputMode="numeric" 
                            onChange={ onInputChange }/>
                            <input ref={expYear} type="text" id="expiration_year" placeholder="YY" maxLength={2}
                            aria-invalid={ ( doValidate && !(valid['expiration_year']) ) }  value={ payment['expiration_year'] }
                            autoComplete="cc-exp-year" inputMode="numeric"
                            onChange={ onInputChange } />
                        </div>
                    </div>
                    <div className="input-label-wrapper zip-wrap">
                        <label htmlFor="zipcode">Zip Code</label>
                        <input ref={zipCode} type="text" id="zipcode" placeholder="" maxLength={5}
                        aria-invalid={ ( doValidate && !(valid['zipcode']) ) }  value={ payment['zipcode'] }
                        autoComplete="postal-code" inputMode="numeric"
                        onChange={ onInputChange } />
                    </div>
                </div>
            </div>
            <div id="input-right">
                <div className="input-label-wrapper">
                    <label htmlFor="cvv">CVV</label>
                    <input ref={CVV} type="text" id="cvv" placeholder="" maxLength={4}
                    aria-invalid={ ( doValidate && !(valid['cvv']) ) } value={ payment['cvv'] }
                    autoComplete="cc-csc" inputMode="numeric" 
                    onChange={ onInputChange } />
                </div>
            </div>
        </div>
    )
}



//
// New Payment Bank Input
//

/**
 * Form for a new bank account
 * @param {object} props 
 * @param {boolean} props.doValidate 
 * @param {BankState} props.state 
 * @param {React.Dispatch<React.SetStateAction<BankState>>} props.setState
 */
export function NewPaymentBank(props) { 
    // New Bank Payment state
    const [ showMXWidget, setShowMXWidget ] = useState(true);

    // Get MX data after account creation
    async function getMXData(userGuid: string, memberGuid: string) {
        const data = { 
            guid: userGuid,
            mguid: memberGuid,
            ulink_user_id: props.user.id,
            mx_env_prod: isProd(),
        }

        const response = await fetch(CloudFunctionsURL('mx_link_withid'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          })
          try {
            const returnData = await response.text();
          } catch(e) {
            alert("There was an issue submitting your information. Please try again.");
            console.error(e)
          }
    }

    /* -----*-----------*---------------*-----
        MX Widget callback action functions
        to be passed into MXConnect component
    -*------------*-------------*------------- */
    function memberConnected(userGuid: string, memberGuid: string) {
        getMXData(userGuid, memberGuid);
    }
    function onPrimaryAction() {
        setShowMXWidget(false);
        props.setPaymentType("SAVED_BANK");
    }

    return (
        <div id="NewPaymentBank">
            { showMXWidget &&
                <MXConnect 
                user={props.user} 
                setUser={props.setUser}
                primaryAction={onPrimaryAction} 
                memberConnected={memberConnected}
                />
            }
        </div>
    )
} 