import React from "react";

export default function PopUp(props) {
    return (
        <div id="PopUp">
            <div id="Popup-container">
                <div id="Popup-card">
                    <h1>{props.title || "An error has occured."}</h1>
                    <p>{props.message || "There was a problem fetching information. Please close and try again."}</p>
                    <div className="button-align-right">
                        <button onClick={() => {
                            props.setShowPopup(null);
                            props.button ? props.button() : null;
                        }}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}