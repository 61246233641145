import React, {useState, useEffect} from "react";
import PopUp from "./Popup";

export default function AddTip(props) {
  
  const tipOptions = [
    {name: "None",  id: 1,  amount: 0.00},
    {name: "10%",   id: 2,  amount: 0.10},
    {name: "15%",   id: 3,  amount: 0.15},
    {name: "20%",   id: 4,  amount: 0.20},
    {name: "Other", id: 5,  amount: props.tipData},
  ];

  const defaultTip = tipOptions[3]; // defaults to index-3 (20% gratuity)
  useEffect(() => { props.setTipData({custom: false, amount: defaultTip.amount})}, []); // Sets tip amount to the default on component mount

  // State
  const [tipSelected, setTipSelected]                     = useState(defaultTip); //default is 0.20
  const [showCustomTipSelector, setShowCustomTipSelector] = useState(false);
  let customTipInputValue; // value inputed by user when "other" tip is selected - updates on input


  const tipButtons = () =>
    tipOptions.map((tip) => {
      return (
        <span key={tip.id} onClick={() => {
            setTipSelected(tip);
            if(tip.id == 5 && showCustomTipSelector == true) setShowCustomTipSelector(false);
            if(tip.id != 5) {
              setShowCustomTipSelector(false);
              props.setTipData({custom: false, amount: tip.amount})
            }
            // custom dollar amount tip is selected
            else setShowCustomTipSelector(true)
          }} 
          className={`${tip.id === tipSelected.id ? "tip-selected" : ""}`}>
          {tip.name}
        </span>
      );
    });

    //Please input a number for the tip amount.
    function changeCustomTipValue(value) {
      if(isNaN(value) || value < 0) {
        props.setShowPopup(
          <PopUp 
            title={"Input was not a positive number."} 
            message={`Please input a positive number for the tip amount.`} 
            setShowPopup={props.setShowPopup}
          /> 
        )
      }
      else {
        props.setTipData({custom: true, amount: customTipInputValue})
        setShowCustomTipSelector(false);
      }
    }

  const tipSelectorView = (type) => {
    switch(type) {
      case 'standard':
        return <div className="tip-selections-grid">{tipButtons()}</div>;
      case 'custom': 
        return (
          <>
            <div className="tip-selections-grid">{tipButtons()}</div>
            <div className="other-tip">
              <span>Dollar amount:</span>
              <input type="number" onInput={(e) => customTipInputValue = parseFloat(e.target.value || '')}/>
              <button onClick={() => changeCustomTipValue(customTipInputValue)}>Set</button>
            </div>
          </>
        )
    };
  };

  return (
    <div id="AddTip">
      { props.showPopup }
      {/* If custom tip (index: 5), render custom tip JSX */}
      {tipSelectorView(showCustomTipSelector ? 'custom' : 'standard')}
    </div>
  );
}
