import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CloudFunctionsURL from "../helpers/CloudFunctionsURL";

import { ULINK_UI } from "ulink_global";

import { TextInput } from "views/widgets/Fields";

//MX ConnectWidget
import MXConnect from "views/widgets/MXConnect";

//Component Props Interface
import MainView from "../interfaces/MainView";

// Environment
import isProd from "../helpers/isProd";


export default function AddPayment(props: MainView) {
    //MX widget state
    const [ showConnectWidget, setShowConnectWidget ]   = useState(true);
    const [ showAltForm, setShowAltForm ]               = useState(false);
    const [ showCompleteMsg, setShowCompleteMsg ]       = useState(false);
    const [ showAltFormBtn, setShowAltFormBtn ]         = useState(false);
    const [ transparent, setTransparent ]               = useState(false); // makes widget have an opacity/height/width of 0 (does not unmount so longpolling can continue)

    //Alt Form
    const [ name, setName ]                             = useState('');
    const [ email, setEmail ]                           = useState('');

    const navigate = useNavigate();

    // URL Params
    //
    let { location_id } = useParams();


    useEffect(() => { 
        // This forces the API call to fetch the location if it isn't already loaded
        props.fetchLocation(location_id);
        // Requires a user to log in
        props.requireUserOnly();
    }, [])


    async function sendMXData(userGuid: string, memberGuid: string) {
        const data = { 
            guid: userGuid,
            mguid: memberGuid,
            ulink_user_id: props.user.id,
            phone: props.user.phone,
            mx_env_prod: isProd(),
        }

        const response = await fetch(CloudFunctionsURL('mx_link_withid'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          })
          try {
            const returnData = await response.text();
          } catch(e) {
            console.error("error in hitting mx_link_withid", e);
            alert(`There was an issue communicating with MX. Please try again later or contact support at ${<a href="mailto:support@ulink.mobi">support@ulink.mobi</a>}`);
          }
    }

    // Send Alt form (phone number and partner) to cloud function
    async function postAltForm() {
        const data = { 
            name: name,
            email: email,
            phone: props.user.phone, 
            ulink_user_id: `${props.user.id}`,
        };

        const response = await fetch(CloudFunctionsURL('pre_reg_backupform'), {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
          });
          try {
              const returnData = await response.text();
              setShowAltForm(false);
            } catch(e) {
                console.log(e);
                ULINK_UI.GenericError();
          }
    }


    /* -----*-----------*---------------*-----
        MX Widget callback action functions
        to be passed into MXConnect component
    -*------------*-------------*------------- */
    function primaryActionCallback() { setTransparent(true) };
    function onMemberConnected(userGuid: string, memberGuid: string) { sendMXData(userGuid, memberGuid) };
    function onStepChange() { setShowAltFormBtn(true) };

    return (
        <>
        { props.user && 
            <div id="AddPayment">
                <header>
                    <h1>Please add a bank account before continuing</h1>
                </header>

                { showConnectWidget &&
                    <MXConnect 
                        user={props.user} setUser={ props.setUser }
                        primaryAction={primaryActionCallback}
                        memberConnected={onMemberConnected}
                        stepChange={onStepChange}
                        isTransparent={transparent}
                    />
                }

                {/* Shows bank-not-listed button after MX "Continue" btn clicked */}
                { showConnectWidget
                    && showAltFormBtn
                    && !showCompleteMsg
                    && <button className="alt-form-button" onClick={() => {
                        setShowConnectWidget(false);
                        setShowAltForm(true);
                    }}>My bank isn't listed</button>
                }

                {/* Show Alt form when state is set to true */}
                { showAltForm 
                    &&
                    <div id="AltForm">
                        <TextInput 
                            customClass="altform-name"
                            name="name" 
                            placeholder="Your name"
                            label="Name"
                            singleField={name}
                            setSingleField={setName}
                        />

                        <TextInput 
                            name="email" 
                            placeholder="Email Address"
                            label="Email"
                            singleField={email}
                            setSingleField={setEmail}
                        />

                        <button onClick={() => {
                            postAltForm();
                            setShowCompleteMsg(true);
                            setShowAltForm(false);
                        }}>Submit</button>
                    </div>
                }

                {/* Only show if user has completed the MX widget flow and waiting for longpolling to finish */}
                { !showConnectWidget 
                    && !showAltForm &&
                    !showCompleteMsg &&
                    <div><span>Please wait while we add your bank account.</span></div>
                }

                {/* After Alt form has been submitted and successful */}
                {
                    showCompleteMsg 
                    && <div id="CompleteMsg">
                            <h1>Thank you!</h1>
                            <span>You may now proceed into the event.</span>
                        </div>
                }
            </div> 
        }
        </>
    )
}