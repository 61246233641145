import React from 'react';

/**
 * Utility class for Material Icons
 * 
 * @component
 * @param {object} props All props will be rendered as passed, except the defined props for this component
 * @param {string} props.name Icon Name
 * @param {"outlined"|"rounded"|"sharp"|"two-tone"} props.theme Material Icons theme
 * @param {string} props.className Additional CSS classes
 * 
 * @param {string} props.href Renders the button as a react-router `Link` element to this parameter. 
 * 
 * @param {function} props.onClick Renders the icon as a button that invokes this function.
 * This includes setting [role=button]. 
 * Please define `aria-label` and other appropriate accessibility attributes if using this functionality.
 * 
 * @returns {React.ReactElement}
 */
 export default function Icon(props) { 
    let pc = props.className; 

    let theme = props.theme
    theme = theme ? `-${theme}` : '';

    let className = `sionic-icon material-icons${theme} ${pc ?? ''}`;

    /**
     * @type {React.HTMLAttributes}
     */
    let cp = Object.assign({}, props);
    delete cp.className; 
    delete cp.name;
    delete cp.href; 
    //TODO: Use `extractProps` from utils.js if we expand this; that's what I made it for, it should be a bit less error-prone 

    if(props.onClick) { 
        cp['data-clickable'] = true 
        cp.role = 'button'; 

        if(! props['aria-label']) console.warn("Icon Button does not have an aria-label") 
    } else { 
        cp.role = 'img'
    }

    var icon = <i className={ className } {... cp} data-icon-name={ props.name }>{ props.name }</i>

    if(props.href) { 
        cp.role = 'button'; 
        return <Link to={props.href}  className="icon-button" >{icon}</Link>
    }
    else return icon; 
}