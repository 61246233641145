import User, { PaymentMethod } from 'data/User';
import React, { useState } from 'react'; 
import ULink from 'services/ULink';

import { ULINK_UI } from 'ulink_global';

import Icon from 'lib/lib-sionic/Icon';

/**
 * 
 * @param {object} props 
 * @param {PaymentMethod} props.method
 * 
 * @param {User} props.user 
 * @param {function} props.setUser 
 * 
 * @param {boolean} props.accessories If `true`, render action buttons.  If not, use checklist props below
 * @param {boolean} props.checked
 * @param {function()} props.onSelect
 * @returns 
 */
export default function(props) { 

    const refreshPage = ()=>{ window.location.reload() };

    let [ isDeleting, setDeleting ] = useState(false) // for any AJAX, not just deleting

    const onDelete = () => { 

        if(isDeleting) return; 
        if(! window.confirm("Are you sure you want to delete this card?")) return; 
        setDeleting(true) 


        ULINK_UI.ajax.before() 
        ULink.user.deletePaymentMethod(props.method)
            .then(obj => { 
                var user = props.user.copy().withRemovePaymentMethod(props.method)

                if(obj.new_default) { 
                    console.debug("Updating default payment method") 
                    user.withDefaultPaymentMethod(obj.new_default)
                }

                props.setUser(user)
            })
            .catch(e => { 
                setDeleting(false) // unmounting on success
                ULINK_UI.ajax.catch(e)
            })
            .finally(() => {
                ULINK_UI.ajax.finally();
                refreshPage(); //I don't like this but we're pressed for time and Erica wanted it 🙄
            })
    }

    const onDefault = () => { 
        if(isDeleting) return; 
        setDeleting(true); 


        ULINK_UI.ajax.before() 
        ULink.user.setDefaultPaymentMethod(props.method) 
            .then(method => { 
                props.setUser( props.user.copy().withDefaultPaymentMethod(method) )
            })
            .catch(ULINK_UI.ajax.catch) 
            .finally(() => { 
                setDeleting(false) 
                ULINK_UI.ajax.finally()
            })
    }


    var icon = null; 
    if(props.checked != undefined) { 
        if(props.checked)   icon = <Icon name="radio_button_checked" />
        else                icon = <Icon name="radio_button_unchecked" /> 
    }

    function checkPaymentMethod(value) {
        switch(value) {
            case 'AX':
                return 'amex.png';
            case 'MC':
                return 'mastercard.png';
            case 'NS':
                return 'discover.png';
            case 'VS':
                return 'visa.png';
            default: 
                return 'generic.png';
        }
    };

    return <div className="PaymentMethod" aria-checked={ props.checked } data-is-default={ props.method.default } onClick={ props.onSelect } >        
        { icon }
        { props.accessories && <div className="PaymentMethodLabel"><span>Default</span></div> }


        <div className="info">
            { props.method.card_type &&  <img className="payment-logo" src={ '/assets/img/card-networks/' + checkPaymentMethod(props.method.card_type) } alt="payment method logo" />}
            { props.method.last_four && <img className="payment-logo" src={ '/assets/img/bank-icon.svg' } alt="payment method logo" />}
            
            <span className="cardName">{ props.method.name_on_card ?? 'Pay-by-Bank Account' }</span>
            {/* props.method.last_four */}
            <span className="cardNumber">**** {props.method.masked_card ?? ''}</span> 
            <span className="expDate">{ props.method.expiration_month && props.method.expiration_year && `${props.method.expiration_month} / ${props.method.expiration_year}` }</span>

            { props.accessories && <div className="buttons">
                { !(props.method.default)  && <button onClick={ onDefault }>Set Default</button>}
                { <button className="negative" onClick={ onDelete }>Delete</button> }
            </div> } 
        </div>
    </div>

}