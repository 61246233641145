import React, {useState, useEffect} from "react";
import { useNavigate, useParams } from "react-router-dom";
//Components
import CodeInput from "./CodeInput";
import Loader from "../helpers/Loader";
import Popup from '../helpers/Popup';

import Checkout from "data/Checkout";
import Transaction from "lib/lib-sionic/data/Transaction";

import ULink from "services/ULink";
import { ULINK_UI } from "ulink_global";
import CheckoutPayment from "./CheckoutPayment";
import CheckoutReceipt from "./CheckoutReceipt";
import User from "data/User";

const CODE_DIGITS = 3;
const newCodeVal = () => Array(CODE_DIGITS).fill('');

// MAIN COMP
/**
 * 
 * @param {object} props 
 * @param {User} props.user
 * @returns 
 */
export default function ThreeDigitCode(props) {
  let navigate = useNavigate();

  useEffect(props.requireUserOnly, [])
  
  // View state 

  /** @typedef {"Code"|"Confirmation"|"Receipt"} ViewState */
  /** @type {[ ViewState, React.Dispatch.React.SetStateAction<ViewState>> ]} */
  const [ checkoutView, setCheckoutView ] = useState("Code");

  useEffect(() => props.scrollToTop(), [checkoutView]); //Makes sure this view renders with top of page being shown


  /* -------------------------------
    This checks if there are any
    current saved payments and moves
    the user to AddPayment view if no
    saved payments have been found.
    -------------------------------- */
  useEffect(() => {
    if(props.user && props.user.pm_banks.length == 0) {
      navigate(`/locations/${location_id}/add-payment`);
    }
  }, [props.user]);



  // Global view setup 
  const [ showLoader, setShowLoader]                = useState(false);
  const [ showPopup, setShowPopup ]                 = useState(false);

  const [ , setAsdf ]                               = useState(false);
  let forceUpdate = () => setAsdf(x => !x);


  // API data

  // In-progress checkout
  /** @type {[ Checkout, React.Dispatch<React.SetStateAction<Checkout>> ]} */
  const [ checkout, setCheckout ] = useState(null) 

  /** @type {[ Transaction, React.Dispatch<React.SetStateAction<Transaction>> ]} */
  const [ completedTransaction, setTransaction ] = useState(null)


  // User inputs
  // 
  let [ code, setCode ] = useState( newCodeVal() ); // CODE input


  // URL Params
  let { location_id } = useParams()

  // Initial fetch
  //
  useEffect(() => { 
      // This forces the API call to fetch the location if it isn't already loaded
      props.fetchLocation(location_id)
  }, []);


  
  async function onEnterCode(code) { 
    code = code.join('');

    setShowLoader(true);
    ULink.checkouts.preview(location_id, code)
      .then(checkout => { 
        setCheckout(checkout)
        setCheckoutView("Confirmation")
      })
      .catch(res => { 
        /** @type {Response} */
        const response = res 

        if(response.status == 404) { 
          setShowPopup(
            <Popup 
                title={"Invalid CODE."} 
                message={"Please make sure CODE was entered correctly and try again."} 
                setShowPopup={setShowPopup} 
            /> 
          )
        }
        else if(response.status == 410) { 
          setShowPopup(
            <Popup 
                title={"Checkout has expired."} 
                message={"Please request a new CODE from the merchant."} 
                setShowPopup={setShowPopup} 
            /> 
          )

        }
        else ULINK_UI.ajax.catch(response) 
      })
      .finally(() => { 
        setShowLoader(false) 
      })

  }

  const threeDigitCodeJSX = () => {
    return (
      <>
        <div id="ThreeDigitNav">
          <span><b>Security CODE</b></span>
        </div>
        <div id="ThreeDigitCode-Info">
          <h1> {props.location.name} </h1>
          <b>Enter CODE from Merchant</b>
          <span className="three-digit-code-inputs">
            <CodeInput value={ code } codedigits={ CODE_DIGITS } onVerify={ onEnterCode } forceUpdate={ forceUpdate } onChange={ (val) => { setCode(val); }} /> 
          </span>
        </div>
      </>
    )
  }


  const goToCodeCheckout = () => {
    window.location.reload();
  }

  const currentCheckoutView = () => {
    switch(checkoutView) {
      case 'Code':
        return threeDigitCodeJSX();
      case 'Confirmation':
        return <CheckoutPayment
            {... { checkout, showPopup, setShowPopup, }}
            user={ props.user } 
            setUser={ props.setUser }
            location={ props.location }

            onComplete={ txn => { setTransaction(txn); setCheckoutView("Receipt") } }
            onCancel={ goToCodeCheckout }

            hamburgerToggle={ props.hamburgerToggle }
          />

      case 'Receipt':
        return <CheckoutReceipt
          location={ props.location }
          transaction={ completedTransaction }
          onDone={ goToCodeCheckout }
        />

      default: throw "Unhandled state in CodeCheckout";
    };
  };

  //TODO: to be replaced with a spinner or something 
  if(! props.user) return null;
  if(! props.location) return null;

  return (
    <div id="ThreeDigitCode">
      { showPopup }
      {
        showLoader ? <Loader /> : currentCheckoutView()
      }

      {/* Ryan's freaking phone kept glitching all over this footer so I just dropped it and I can't be bothered rn */}
      {/* { (checkoutView === "Code") && <footer id="Pay-In-Store-Footer"> <span>Copyright © 2010-2022 Sionic Mobile Corporation</span> </footer> } */}
    </div>
  );
}